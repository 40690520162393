import React, { useState, useEffect, useCallback } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { useAuthState } from "../../../context/auth";
import Card from "../../cards/Card";
import {
  handleRequestError,
  triggerToast,
  listTimeZones,
  objToSelectOptions,
} from "../../../helpers";
import Loader from "../../elements/Loader";
import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Admin";
import LoggerForm from "../../forms/admin/LoggerForm";
import { getLogger, updateLogger, saveLogger } from "../../../api/loggers";
import { listManufacturers } from "../../../api/manufacturers";

const ManageLogger = ({ id, setIsLoading }) => {
  const classes = useStyles();
  const user = useAuthState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [logger, setLogger] = useState(null);
  const [manufacturers, setManufacturers] = useState({});

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  const loadLogger = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getLogger(user.token, id);
      setLogger(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching logger: ");
      setLoading(false);
    }
  }, [user.token, id]);

  const loadManufacturers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await listManufacturers(user.token);
      setManufacturers(objToSelectOptions(response.data, "slug", "name"));
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching manufacturers: ");
      setLoading(false);
    }
  }, [user.token]);

  useEffect(() => {
    loadManufacturers();
    id && loadLogger(id);
  }, [loadManufacturers, loadLogger, id]);

  const handleSubmit = async (formValues) => {
    setWaiting(true);
    try {
      const values = {
        ...formValues,
        client_id: parseInt(formValues.client_id),
        admin: formValues.admin ? 1 : 0,
      };
      const response = id
        ? await updateLogger(user.token, id, values)
        : await saveLogger(user.token, values);
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      history.push("/admin");
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to ${id ? "update" : "save"} logger: `);
    }
  };

  return (
    <Loader active={loading} showChildren={false} message={`Loading Logger...`}>
      <Headline headline={`Edit Logger`} headlineVariant="h1" divider={true}>
        <FormButton
          text={id ? "Update" : "Save"}
          disabled={!valid || waiting}
          handleClick={() => dispatch(submit("LoggerForm"))}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText={id ? "Updating" : "Saving"}
        />
      </Headline>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                {Object.keys(manufacturers).length && (
                  <LoggerForm
                    manufacturers={manufacturers}
                    timezones={listTimeZones()}
                    initialValues={logger}
                    isValid={(status) => setValid(status)}
                    isWaiting={(status) => setWaiting(status)}
                    onSubmit={handleSubmit}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ManageLogger;
