import React, { useMemo, useState } from "react";
import moment from "moment";
import EditDeleteTable from "./EditDeleteTable";
import {
  useParameterById,
  fetchParameters,
  useParameterDispatch,
} from "../../context/parameters";
import { DeleteParameter, UpdateParameter } from "../../api/parameters";
import { formatDateRange } from "../../helpers";
import UpdateParametersForm from "../forms/UpdateParametersForm";
import { useTheme } from "@material-ui/core";
import {
  analyticsTableToolbar,
  defaultTableOptions,
} from "../pages/Analytics/util";

const ParameterTable = ({ data, user, field, range }) => {
  const { params, types } = data;
  const parameterDispatch = useParameterDispatch();
  const [parameterId, setParameterId] = useState(null);
  const parameterRecord = useParameterById(parameterId);
  const parameters = useMemo(
    () =>
      params.length && {
        columns: [
          { title: "Timestamp", field: "timestamp_label" },
          { title: "Description", field: "description" },
          { title: "Type", field: "type_label" },
          { title: "Value", field: "value" },
        ],
        data: params.map((item) => {
          const paramType = types.filter((elm) => elm.id === item.type);
          return {
            ...item,
            type_label: paramType.length ? paramType[0].name : "Unknown",
            timestamp_label: moment(item.timestamp).format("DD/MM/YY HH:mm"),
          };
        }),
      },
    [params, types]
  );
  const handleParameterUpdate = ({
    type,
    description,
    timestamp,
    group,
    value,
  }) =>
    UpdateParameter(user.token, user.client.id, field.id, parameterId, {
      type,
      description,
      group,
      timestamp: moment
        .tz(timestamp.format("YYYY-MM-DD HH:mm:ss"), field.timezone)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      value: parseFloat(value),
    });
  const handleParameterDelete = () =>
    DeleteParameter(user.token, user.client.id, field.id, parameterId);
  const handleParameterRefresh = () =>
    fetchParameters(
      parameterDispatch,
      user.client.id,
      field.id,
      formatDateRange(range),
      user.token,
      field.timezone
    );

  const theme = useTheme();
  const tableConfig = {
    title: "Parameters",
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
  };

  return (
    <EditDeleteTable
      title="Parameters"
      type="Parameter"
      data={{ ...parameters, ...tableConfig }}
      record={parameterRecord}
      setRecord={setParameterId}
      onUpdate={handleParameterUpdate}
      onDelete={handleParameterDelete}
      onRefresh={handleParameterRefresh}
    >
      <UpdateParametersForm
        title="Amend the Parameter:"
        timezone={field.timezone}
      />
    </EditDeleteTable>
  );
};

export default ParameterTable;
