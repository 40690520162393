import React from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Hidden,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  subHeadline: {
    color: theme.palette.text.secondary,
  },
  divider: {
    marginTop: theme.spacing(1),
  },
}));

const Children = ({ children }) => {
  return React.Children.map(children, (child) => {
    if (child.props.hidden) {
      return (
        <Hidden {...child.props.hidden}>
          <Grid item>{React.cloneElement(child)}</Grid>
        </Hidden>
      );
    }
    return <Grid item>{React.cloneElement(child)}</Grid>;
  });
};

export default (props) => {
  const classes = useStyles();
  return (
    <div className={props.subHeadlineVariant && classes.root}>
      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <Grid item>
          <Typography variant={props.headlineVariant}>
            {props.headline}
          </Typography>
        </Grid>
        {props.subHeadlineVariant && (
          <Grid item>
            <Typography
              variant={props.subHeadlineVariant}
              className={classes.subHeadline}
            >
              {props.subHeadline}
            </Typography>
          </Grid>
        )}
        {props.children && <Children {...props} />}
      </Grid>
      {props.divider === true && <Divider className={classes.divider} />}
    </div>
  );
};
