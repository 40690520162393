import { useTheme } from "@material-ui/core";
import { useMemo } from "react";
import { useMetricsState } from "../../../../../context/metrics";
import { maximaMinima } from "./utils";

export const charts = {
  ambient_climate: {
    label: "Ambient Climate",
    access: [3],
  },
  agronomics_overview: {
    label: "Agronomics Overview",
    access: [2],
  },
  agronomics_ph: {
    label: "Agronomics - pH",
    access: [2],
  },
  agronomics_ec: {
    label: "Agronomics - EC",
    access: [2],
  },
  vpd: {
    label: "VPD",
    access: [3],
  },
  mildew: {
    label: "Mildew Risk",
    access: [1],
  },
  growing_hours: {
    label: "Growing Degree Hours",
    access: [3],
  },
  chilling_hours: {
    label: "Chilling Hours",
    access: [3],
  },
  soil_climate: {
    label: "Root-Zone Moisture Content",
    access: [4],
  },
  soil_moisture: {
    label: "Soil Moisture Content",
    access: [4],
  },
  soil_moisture_gains: {
    label: "Crop Water Usage & Gains",
    access: [4],
  },
  vic: {
    label: "Soil Conductivity",
    access: [4],
  },
  soil_temp: {
    label: "Soil Temperature",
    access: [4],
  },
  rainfall: {
    label: "Rainfall",
    access: [4],
  },
  run_off: {
    label: "Run-Off",
    access: [4],
  },
  ph: {
    label: "pH",
    access: [4],
  },
  ec: {
    label: "EC",
    access: [4],
  },
};

export function useChartSettings(permissions, fieldId, visibility) {
  const settings = useMemo(() => {
    return Object.keys(charts)
      .filter((chart) =>
        permissions.some((r) => charts[chart]?.access.includes(r))
      ) // filter charts to match client permissions
      .map((chart) => {
        const settings = visibility?.filter(
          ({ field_id: id, ref }) => fieldId === id && chart === ref
        );
        const { order, hidden } = settings[0] || { order: 999, hidden: 0 };
        return { ...charts[chart], id: chart, ...{ order, hidden } };
      }) // append visibility & ordering settings
      .filter((chart) => !chart.hidden) // remove hidden charts
      .sort((a, b) => (a.order > b.order ? 1 : -1)); // order to match user preferences
  }, [fieldId, visibility, permissions]);

  return settings;
}

export function useChartTransitionDuration({ transitions: { duration } }) {
  return {
    enter: duration?.enteringScreen,
    exit: duration?.leavingScreen,
  };
}

export function useAxisesMinMax(axises, datasets, data) {
  return Object.keys(axises).reduce((obj, axisKey) => {
    const axis = axises[axisKey];
    const axisDatasets = Object.keys(datasets).reduce((arr, dataset, i) => {
      if (datasets[dataset]?.axis === axisKey) {
        return [...arr, data[i]];
      }
      return arr;
    }, []);
    const minMax = maximaMinima(axisDatasets);
    return { ...obj, [axisKey]: { ...axis, ...minMax } };
  }, {});
}

export function useChartMetrics(datasets) {
  const metrics = useMetricsState();
  const theme = useTheme();

  return datasets?.reduce((obj, key) => {
    const metric = metrics.filter(({ ref }) => ref === key);
    let config = {};

    if (metric[0]) {
      const { name, short_name: shortName, color, suffix } = metric[0];
      config[key] = {
        title: name,
        shortName,
        color: theme.palette[color[0]][color[1]],
        suffix,
      };
    }
    return { ...obj, ...config };
  }, {});
}

export function useChartData(rawData, metrics) {
  const hasData = Boolean(rawData?.length);
  let notNull = false;
  let allZero = true;
  const kpi = (v) => {
    if (v !== null) {
      notNull = true;
      if (Number(v) !== 0) {
        allZero = false;
      }
      return Number(v);
    } else {
      return null;
    }
  };
  const data = metrics?.map((metric) => {
    return rawData?.reduce(
      (arr, record) => [
        ...arr,
        {
          x: record?.timestamp,
          y: kpi(record[metric]),
        },
      ],
      []
    );
  });
  return { hasData: hasData && notNull, data, allZero };
}
