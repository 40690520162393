import React, { useMemo, useState } from "react";
import moment from "moment";
import EditDeleteTable from "../tables/EditDeleteTable";
import {
  useSqueezeById,
  fetchSqueezes,
  useSqueezeDispatch,
} from "../../context/squeeze";
import { DeleteSqueeze, UpdateSqueeze } from "../../api/squeeze";
import { formatDateRange } from "../../helpers";
import SqueezeForm from "../forms/SqueezeForm";
import config from "../../constants/squeeze";
import { useTheme } from "@material-ui/core";
import {
  analyticsTableToolbar,
  defaultTableOptions,
} from "../pages/Analytics/util";

const SqueezeTable = ({ data, user, field, range }) => {
  // Squeezes
  const squeezeDispatch = useSqueezeDispatch();
  const [squeezeId, setSqueezeId] = useState(null);
  const squeezeRecord = useSqueezeById(squeezeId);
  const squeezes = useMemo(
    () =>
      data.length && {
        columns: [
          { title: "Timestamp", field: "timestamp_label" },
          { title: "Type", field: "type" },
        ],
        data: data.map((item) => ({
          ...item,
          type: config.options[item.type],
          timestamp_label: moment(item.timestamp).format("DD/MM/YY HH:mm"),
        })),
      },
    [data]
  );
  const handleUpdate = ({ type, timestamp }) => {
    return UpdateSqueeze(user.token, user.client.id, field.id, squeezeId, {
      type,
      timestamp: moment
        .tz(timestamp.format("YYYY-MM-DD HH:mm:ss"), field.timezone)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    });
  };
  const handleDelete = () =>
    DeleteSqueeze(user.token, user.client.id, field.id, squeezeId);
  const handleRefresh = () =>
    fetchSqueezes(
      squeezeDispatch,
      user.client.id,
      field.id,
      formatDateRange(range),
      user.token,
      field.timezone
    );

  const theme = useTheme();
  const tableConfig = {
    title: "Observations",
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
  };

  return (
    <EditDeleteTable
      title="Observation Records"
      type="Observation Record"
      data={{ ...squeezes, ...tableConfig }}
      record={squeezeRecord}
      setRecord={setSqueezeId}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      onRefresh={handleRefresh}
    >
      <SqueezeForm
        title="Amend the Observation details:"
        timezone={field.timezone}
      />
    </EditDeleteTable>
  );
};

export default SqueezeTable;
