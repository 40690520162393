import React from "react";
import Card from "../../";
import { useAmbientClimateState } from "../../../../../../../context/ambient-climate";
import DefaultChart from "../../../Chart/components/DefaultChart";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import { useSensorDataTable, useLoggerLoadingStatus } from "../../hooks";

const AmbientClimate = ({ title }) => {
  const { status, data: rawData } = useAmbientClimateState();
  const loading = useLoggerLoadingStatus(status);
  const values = ["ambient_temp", "humidity"];
  const defaultTable = useSensorDataTable(rawData, title, values);
  const { data, hasData } = useChartData(Object.values(rawData), values);
  const { ambient_temp, humidity } = useChartMetrics(values);
  const datasets = {
    ambient_temp: {
      ...ambient_temp,
      type: "line",
      axis: "degC",
    },
    humidity: {
      ...humidity,
      type: "line",
      axis: "rh",
    },
  };
  const axises = useAxisesMinMax(
    {
      degC: {
        id: 0,
        label: "°C",
        orientation: "left",
      },
      rh: {
        label: "% RH",
        id: 1,
        orientation: "right",
      },
    },
    datasets,
    data
  );

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default AmbientClimate;
