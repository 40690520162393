import React, { createContext, useReducer, useContext } from "react";
import {
  handleRequestError,
  filterIrrimax,
  defaultDateRange,
} from "../helpers";
import { fetchIrrimax } from "../api/irrimax";
import { getField } from "../api/fields";

const AnalyticsStateContext = createContext();
const AnalyticsDispatchContext = createContext();

function analyticsReducer(state, action) {
  switch (action.type) {
    case "setActiveField": {
      return { ...state, field: action.payload };
    }
    case "setDateRange": {
      return { ...state, range: { ...state.range, ...action.payload } };
    }
    case "resetDateRange": {
      return { ...state, range: defaultDateRange() };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AnalyticsProvider({ children }) {
  const [state, dispatch] = useReducer(analyticsReducer, {
    field: null,
    range: defaultDateRange(),
  });
  return (
    <AnalyticsStateContext.Provider value={state}>
      <AnalyticsDispatchContext.Provider value={dispatch}>
        {children}
      </AnalyticsDispatchContext.Provider>
    </AnalyticsStateContext.Provider>
  );
}

function useAnalyticsState() {
  const context = useContext(AnalyticsStateContext);
  if (context === undefined) {
    throw new Error(
      "useAnalyticsState must be used within a AnalyticsProvider"
    );
  }
  return context;
}

function useAnalyticsDispatch() {
  const context = useContext(AnalyticsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAnalyticsDispatch must be used within a AnalyticsProvider"
    );
  }
  return context;
}

async function fetchField(dispatch, clientId, fieldId, folder, token) {
  try {
    const response = await getField(token, clientId, fieldId);
    let field = { ...response.data };

    if (folder) {
      try {
        const irrimax = await fetchIrrimax(folder);
        if (irrimax) {
          field = { ...field, ...filterIrrimax(irrimax, field) };
        }
      } catch (e) {
        handleRequestError(e, "Failed fetching irrimax: ");
      }
    }

    dispatch({ type: "setActiveField", payload: field });
  } catch (e) {
    handleRequestError(e, "Failed fetching fields: ");
  }
}

export {
  AnalyticsProvider,
  useAnalyticsState,
  useAnalyticsDispatch,
  fetchField,
};
