import React, { useEffect, useState, useMemo } from "react";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import ViewListIcon from "@material-ui/icons/ViewList";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import AmbientClimate from "./components/AmbientClimate";
import AgronomicsOverview from "./components/AgronomicsOverview";
import AgronomicsPh from "./components/AgronomicsPh";
import AgronomicsEc from "./components/AgronomicsEc";
import Vpd from "./components/Vpd";
import MildewRisk from "./components/MildewRisk";
import GrowingHours from "./components/GrowingHours";
import ChillingHours from "./components/ChillingHours";
import RootZoneMoisture from "./components/RootZoneMoisture";
import SoilMoisture from "./components/SoilMoisture";
import SoilMoistureGains from "./components/SoilMoistureGains";
import SoilConductivity from "./components/SoilConductivity";
import SoilTemperature from "./components/SoilTemperature";
import Rainfall from "./components/Rainfall";
import RunOff from "./components/RunOff";
import Ph from "./components/Ph";
import Ec from "./components/Ec";
import { useMetricsState } from "../../../../../context/metrics";
import Table from "../../../../tables/Table";
import { useTheme } from "@material-ui/core";
import { analyticsTableToolbar, defaultTableOptions } from "../../util";

export function useChartsList() {
  return {
    ambient_climate: {
      label: "Ambient Climate",
      access: [3],
      chart: <AmbientClimate />,
    },
    agronomics_overview: {
      label: "Agronomics Overview",
      access: [2],
      chart: <AgronomicsOverview />,
    },
    agronomics_ph: {
      label: "Agronomics - pH",
      access: [2],
      chart: <AgronomicsPh />,
    },
    agronomics_ec: {
      label: "Agronomics - EC",
      access: [2],
      chart: <AgronomicsEc />,
    },
    vpd: {
      label: "VPD",
      access: [3],
      chart: <Vpd />,
    },
    mildew: {
      label: "Mildew Risk",
      access: [1],
      chart: <MildewRisk />,
    },
    growing_hours: {
      label: "Growing Degree Hours",
      access: [3],
      chart: <GrowingHours />,
    },
    chilling_hours: {
      label: "Chilling Hours",
      access: [3],
      chart: <ChillingHours />,
    },
    soil_climate: {
      label: "Root-Zone Moisture Content",
      access: [4],
      chart: <RootZoneMoisture />,
    },
    soil_moisture: {
      label: "Soil Moisture Content",
      access: [4],
      chart: <SoilMoisture />,
    },
    soil_moisture_gains: {
      label: "Crop Water Usage & Gains",
      access: [4],
      chart: <SoilMoistureGains />,
    },
    vic: {
      label: "Soil Conductivity",
      access: [4],
      chart: <SoilConductivity />,
    },
    soil_temp: {
      label: "Soil Temperature",
      access: [4],
      chart: <SoilTemperature />,
    },
    rainfall: {
      label: "Rainfall",
      access: [4],
      chart: <Rainfall />,
    },
    run_off: {
      label: "Run-Off",
      access: [4],
      chart: <RunOff />,
    },
    ph: {
      label: "pH",
      access: [4],
      chart: <Ph />,
    },
    ec: {
      label: "EC",
      access: [4],
      chart: <Ec />,
    },
  };
}

export function useLoggerLoadingStatus(status) {
  return status === "idle";
}

export function useCharts(permissions, fieldId, visibility) {
  const charts = useChartsList();

  const settings = useMemo(() => {
    return Object.keys(charts)
      .filter((chart) =>
        permissions.some((r) => charts[chart]?.access.includes(r))
      ) // filter charts to match client permissions
      .map((chart) => {
        const settings = visibility?.filter(
          ({ field_id: id, ref }) => fieldId === id && chart === ref
        );
        const { order, hidden } = settings[0] || { order: 999, hidden: 0 };
        return { ...charts[chart], id: chart, ...{ order, hidden } };
      }) // append visibility & ordering settings
      .filter((chart) => !chart.hidden) // remove hidden charts
      .sort((a, b) => (a.order > b.order ? 1 : -1)); // order to match user preferences
  }, [charts, permissions, visibility, fieldId]);

  return settings;
}

export function useChartWidth(options) {
  return options
    ?.find(({ headline }) => headline === "Actions")
    ?.items.find(({ meta: { width } }) => width)?.meta?.width;
}

export function useChartDropdownActionWidth() {
  const [chartWidth, setChartWidth] = useState(12);
  const handleToggleWidth = () => setChartWidth(chartWidth === 6 ? 12 : 6);

  return {
    type: "link",
    icon: <AspectRatioIcon />,
    label: "Resize card",
    onClick: handleToggleWidth,
    meta: {
      width: chartWidth,
    },
  };
}

export function useChartDropdownActions() {
  const resize = useChartDropdownActionWidth();
  return {
    headline: "Actions",
    items: [{ ...resize }],
  };
}

export function useFormatTableData(data, filters) {
  const metrics = useMetricsState();
  const tableData = useMemo(() => {
    if (!filters) return null;

    const columns = [
      { title: "Timestamp", field: "timestamp" },
      ...filters.map((type) => {
        const metric = metrics.filter((item) => item.ref === type);
        const title = metric.length ? metric[0]?.name : `Unknown (${type})`;
        return {
          title,
          field: type,
          cellStyle: { minWidth: 120 },
        };
      }),
    ];

    const formattedData = Object.values(data)
      .sort((a, b) => (a.timestamp.isBefore(b.timestamp) ? 1 : -1))
      .map((item) => ({
        ...item,
        timestamp: item.timestamp.format("DD/MM/YY HH:mm"),
      }));

    return { columns, data: formattedData };
  }, [data, filters, metrics]);

  return tableData;
}

export function useSensorDataTable(data, title, columns) {
  const theme = useTheme();
  const tableData = useFormatTableData(data, columns);
  const config = {
    title: `${title} Sensor Data`,
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
    ...tableData,
  };

  return {
    title: "Sensor Data",
    display: false,
    table: <Table {...config} />,
  };
}

export function useChartTables(tableList) {
  const [tables, updateTables] = useState([]);

  useEffect(() => {
    updateTables(tableList);
  }, [tableList]);

  const toggleTableDisplay = (index) => {
    let newTables = [...tables];
    if (newTables[index]) {
      newTables[index].display = !newTables[index].display;
      updateTables(newTables);
    }
  };

  const tableActions = {
    icon: <ViewListIcon />,
    headline: "Table Display",
    items: tables.map(({ display, title }, i) => ({
      type: "checkbox",
      label: title,
      onClick: () => toggleTableDisplay(i),
      att: { checked: display },
    })),
  };

  return {
    tables,
    tableActions,
    toggleTableDisplay,
  };
}

export function useIrrimaxCharts(charts) {
  const [irrimaxCharts, setIrrimaxCharts] = useState([]);

  useEffect(() => {
    setIrrimaxCharts(
      charts.map((item, i) => ({
        ...item,
        display: typeof item.display === "undefined" ? !i : false,
      }))
    );
  }, [charts]);

  const toggleIrrimaxChartDisplay = (index) => {
    let newCharts = [...irrimaxCharts];
    if (newCharts[index]) {
      newCharts[index].display = !newCharts[index].display;
      setIrrimaxCharts(newCharts);
    }
  };

  const irrimaxChartActions = {
    icon: <ShowChartIcon />,
    headline: "Chart Display",
    items: irrimaxCharts.map(({ display, name }, i) => ({
      type: "checkbox",
      label: name,
      onClick: () => toggleIrrimaxChartDisplay(i),
      att: { checked: display },
    })),
  };

  return {
    irrimaxCharts,
    irrimaxChartActions,
    toggleIrrimaxChartDisplay,
  };
}

export function useChartTransitionDuration({ transitions: { duration } }) {
  return {
    enter: duration?.enteringScreen,
    exit: duration?.leavingScreen,
  };
}

export function useDepthSensors(sensors, prefix) {
  return useMemo(() => {
    const values = sensors.reduce((arr, { ref }) => {
      if (ref.includes("cm")) {
        const depth = Number(ref.substring(0, ref.length - 2).slice(-2));
        return arr.includes(depth) ? arr : [...arr, depth];
      }
      return arr;
    }, []);
    return {
      values,
      refs: values.map((v) => `${prefix}_${v}`),
    };
  }, [prefix, sensors]);
}
