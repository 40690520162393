import React, { useMemo } from "react";
import SettingsToggle from "./SettingsToggle";
import SettingsHeader from "./SettingsHeader";
import SettingsForm from "./SettingsForm";
import { useSidebarState, useSidebarDispatch } from "../../context/sidebar";
import { useSettingsState } from "../../context/settings";
import { useAuthState, usePermissions } from "../../context/auth";
import { charts as config } from "../pages/Analytics/components/Chart/hooks";

const AnalyticsSettings = ({ fieldId, loadingState }) => {
  const { open, settingsOpen } = useSidebarState();
  const permissions = usePermissions();
  const user = useAuthState();
  const {
    chartsSettings: { visibility },
  } = useSettingsState();

  const settings = useMemo(
    () =>
      visibility
        .filter(
          ({ ref, field_id }) =>
            field_id === fieldId &&
            config[ref] &&
            permissions.includes(config[ref].access[0])
        )
        .map((item) => ({
          ...item,
          label: config[item.ref] ? config[item.ref].label : "Unknown",
        })),
    [visibility, fieldId, permissions]
  );

  const sidebarDispatch = useSidebarDispatch();
  const toggleDisplay = () => {
    !open && sidebarDispatch({ type: "setOpen", payload: !open });
    sidebarDispatch({ type: "setSettingsOpen", payload: !settingsOpen });
  };

  if (open && settingsOpen) {
    return (
      <SettingsForm
        user={user}
        label="chart"
        items={settings}
        subheader={<SettingsHeader close={toggleDisplay} />}
        setLoading={(state) => loadingState(state)}
      />
    );
  } else {
    return <SettingsToggle click={toggleDisplay} />;
  }
};

export default AnalyticsSettings;
