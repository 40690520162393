import React, { useState, useMemo } from "react";
import {
  makeStyles,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import {
  useAnalyticsState,
  useAnalyticsDispatch,
} from "../../context/analytics";
import DatePickerToFrom from "../elements/DatePickerToFrom";
import Timer from "@material-ui/icons/Timer";

const useStyles = makeStyles((theme) => ({
  item: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover, &:focus, &:active": {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.secondary.main,
    },
  },
}));

const AnalyticsDateTime = ({ color }) => {
  const [value, setValue] = useState({
    start_date: moment().utc().subtract(10, "days"),
    end_date: moment().utc(),
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const { field, range } = useAnalyticsState();
  const dispatch = useAnalyticsDispatch();
  const timezone = field && field.timezone;
  const classes = useStyles();
  const quickDates = [1, 3, 7, 10, 30];

  const isEdited = useMemo(
    () =>
      !(
        value.start_date.isSame(range.start_date, "date") &&
        value.end_date.isSame(range.end_date, "date")
      ),
    [value, range]
  );

  if (!timezone) return null;

  const handleMenuClick = (e) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleQuickNav = (days) => {
    const quickRange = {
      start_date: moment().utc().subtract(days, "days"),
      end_date: moment().utc(),
    };
    setValue(quickRange);
    handleClose();
  };

  const setTimestamp = (timestamp, timezone) =>
    timezone ? timestamp.clone().tz(timezone) : timestamp;

  const handleStartDateChange = (e) =>
    setValue({
      start_date: e.clone().utc(),
      end_date: value.end_date,
    });

  const handleEndDateChange = (e) =>
    setValue({
      start_date: value.start_date,
      end_date: e.clone().utc(),
    });

  const handleSaveDateChange = () =>
    dispatch({ type: "setDateRange", payload: value });

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <IconButton size="small" onClick={handleMenuClick}>
          <Timer className={classes.icon} />
        </IconButton>
        <Menu
          dense
          id="quick-date-range"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {quickDates.map((value, item) => (
            <MenuItem key={item} onClick={() => handleQuickNav(value)}>
              {`Last ${value} day${value > 1 ? "s" : ""}`}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
      <Grid item>
        <DatePickerToFrom
          timestampFrom={setTimestamp(value.start_date, timezone)}
          onChangeFrom={handleStartDateChange}
          timestampTo={setTimestamp(value.end_date, timezone)}
          onChangeTo={handleEndDateChange}
          color={color}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveDateChange}
          className={classes.button}
          disabled={!isEdited}
        >
          Go!
        </Button>
      </Grid>
    </Grid>
  );
};

export default AnalyticsDateTime;
