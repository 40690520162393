import { useMemo } from "react";

export function useChartAdvisoryBg(sections, start_date, end_date) {
  return useMemo(() => {
    const boundaryData = ([y, to]) => {
      const dataset = sections.filter(({ id }) => to === id);
      const y0 = dataset[0]?.y[0] || 0;
      return [
        { x: start_date, y: y || 0, y0 },
        { x: end_date, y: y || 0, y0 },
      ];
    };
    return sections.map(({ y, ...rest }) => ({
      ...rest,
      data: boundaryData(y),
    }));
  }, [end_date, sections, start_date]);
}
