import React, { useMemo, useState } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core/";
import CardWarning from "../cards/CardWarning";
import { useFieldsState } from "../../context/fields";
import { useSettingsState } from "../../context/settings";
import { useHistory } from "react-router-dom";
import { useFieldsFiltersState } from "../../context/field-filters";
import FieldsTable from "./FieldsTable";
import { useMetricsState } from "../../context/metrics";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ViewListIcon from "@material-ui/icons/ViewList";
import FieldsCards from "./FieldsCards";
import {
  fieldsByType,
  fieldsWithKpis,
  filteredSortedFields,
  getVisibility,
} from "../pages/Analytics/util";

const FieldsList = ({ permissions, clientName }) => {
  const history = useHistory();
  const { fields: allFields } = useFieldsState();
  const {
    fieldsSettings: { visibility },
  } = useSettingsState();
  const { farm, crop_type } = useFieldsFiltersState();
  const [display, setDisplay] = useState("table");
  const metrics = useMetricsState();

  const fieldsList = useMemo(
    () => fieldsWithKpis(allFields, permissions, metrics),
    [allFields, permissions, metrics]
  );
  const fieldsWithVisibility = useMemo(() => {
    return fieldsList.map((field) => {
      const settings = getVisibility(field, visibility);
      return { ...field, ...settings };
    });
  }, [fieldsList, visibility]);
  const filteredFields = useMemo(
    () => filteredSortedFields(fieldsWithVisibility, { crop_type, farm }),
    [fieldsWithVisibility, crop_type, farm]
  );

  const fields = useMemo(
    () => ({
      substrate: fieldsByType(filteredFields, "substrate"),
      soil: fieldsByType(filteredFields, "soil"),
    }),
    [filteredFields]
  );

  const handleClick = (id) => history.push(`/field/${id}`);

  const toggleDisplay = (type) => setDisplay(type);

  return filteredFields.length > 0 ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h5" color="textSecondary">
              Layout:{" "}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Table"
              type="button"
              title="Table"
              color={display && display === "table" ? "primary" : "default"}
              onClick={() => toggleDisplay("table")}
            >
              <ViewListIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="Cards"
              type="button"
              title="Cards"
              color={display && display === "cards" ? "primary" : "default"}
              onClick={() => toggleDisplay("cards")}
            >
              <DashboardIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {Object.keys(fields).map((fieldType) => {
        const fieldsList = fields[fieldType];
        const title = `${clientName} ${fieldType} Fields`;
        if (!fieldsList?.length) {
          return null;
        }

        if (display === "cards") {
          return (
            <FieldsCards
              title={title}
              fields={fieldsList}
              onClick={handleClick}
            />
          );
        }

        return (
          <Grid item xs={12}>
            <FieldsTable
              title={title}
              fields={fieldsList}
              clientName={clientName}
              onClick={handleClick}
              fieldType={fieldType}
            />
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <CardWarning message="No fields found!" />
  );
};

export default FieldsList;
