import React from "react";
import Card from "../..";
import { useSoilClimateState } from "../../../../../../../context/soil-climate";
import DefaultChart from "../../../Chart/components/DefaultChart";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";

const Ph = ({ title }) => {
  const { status, data: rawData } = useSoilClimateState();
  const loading = useLoggerLoadingStatus(status);
  const values = ["ph_in", "ph_out", "ph_bag"];
  const defaultTable = useSensorDataTable(rawData, title, values);
  const { data, hasData } = useChartData(Object.values(rawData), values);
  const { ph_in, ph_out } = useChartMetrics(["ph_in", "ph_out"]);
  const datasets = {
    ph_in: {
      ...ph_in,
      type: "line",
      axis: "ph",
    },
    ph_out: {
      ...ph_out,
      type: "line",
      axis: "ph",
    },
  };
  const axises = useAxisesMinMax(
    {
      ph: {
        id: 0,
        orientation: "left",
        label: "pH",
      },
    },
    datasets,
    data
  );

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default Ph;
