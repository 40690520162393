import React, { useMemo } from "react";
import { useAuthState } from "../../../../../../../context/auth";
import RecommendationTable from "../../../../../../tables/RecommendationTable";
import SqueezeTable from "../../../../../../tables/SqueezeTable";
import ParameterTable from "../../../../../../tables/ParametersTable";
import Card from "../..";
import { useSqueezeState } from "../../../../../../../context/squeeze";
import { useRecommendationState } from "../../../../../../../context/recommendation";
import { useParameterState } from "../../../../../../../context/parameters";
import { useAnalyticsState } from "../../../../../../../context/analytics";
import { useSoilClimateState } from "../../../../../../../context/soil-climate";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import DefaultChart from "../../../Chart/components/DefaultChart";
import { useParamBoundaries } from "./hooks";

const RootZoneMoisture = ({ title }) => {
  const user = useAuthState();
  const { field, range } = useAnalyticsState();
  const { status, data: rawData } = useSoilClimateState();
  const loading = useLoggerLoadingStatus(status);
  const values = ["moisture_content"];
  const defaultTable = useSensorDataTable(
    rawData,
    "Moisture Sensor Data",
    values
  );
  const { data, hasData } = useChartData(Object.values(rawData), values);
  const { moisture_content } = useChartMetrics(values);
  const datasets = {
    ambient_temp: {
      ...moisture_content,
      type: "line",
      axis: "ml",
    },
  };
  const axises = useAxisesMinMax(
    {
      ml: {
        id: 0,
        label: "ml",
        orientation: "left",
      },
    },
    datasets,
    data
  );

  const squeezes = useSqueezeState();
  const recommendations = useRecommendationState();
  const parameters = useParameterState();

  // const boundaries = useParamBoundaries(field, parameters, range);
  // console.log({ boundaries });

  // const chartData = useMemo(() => {
  //   return data
  //     ? {
  //         ...data,
  //         squeeze: squeezes?.map((item) => ({
  //           t: moment(
  //             item.timestamp
  //               .clone()
  //               .tz(field.timezone)
  //               .format("YYYY-MM-DD HH:mm:ss")
  //           ),
  //           y: item.value,
  //           label: item.type.toUpperCase(),
  //         })),
  //         params: formatParams,
  //       }
  //     : null;
  // }, [data, squeezes, formatParams, field.timezone]);

  const tables = [
    defaultTable,
    {
      title: "Recommendations",
      display: false,
      table: (
        <RecommendationTable
          data={recommendations}
          user={user}
          field={field}
          range={range}
        />
      ),
    },
    {
      title: "Observations",
      display: false,
      table: (
        <SqueezeTable data={squeezes} user={user} field={field} range={range} />
      ),
    },
    {
      title: "Parameters",
      display: false,
      table: (
        <ParameterTable
          data={parameters}
          user={user}
          field={field}
          range={range}
        />
      ),
    },
  ];

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={tables}
    />
  );
};

export default RootZoneMoisture;
