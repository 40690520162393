import React, { createContext, useReducer, useContext } from "react";
import { handleRequestError, setLocalTimestamps } from "../helpers";
import { ListSoilClimates } from "../api/soil-climate";

const SoilClimateStateContext = createContext();
const SoilClimateDispatchContext = createContext();

function soilClimateReducer(state, action) {
  switch (action.type) {
    case "setSoilClimate": {
      return { ...state, data: { ...action.payload } };
    }
    case "setStatus": {
      return { ...state, status: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SoilClimateProvider({ children }) {
  const [state, dispatch] = useReducer(soilClimateReducer, {
    status: "idle",
    data: [],
  });
  return (
    <SoilClimateStateContext.Provider value={state}>
      <SoilClimateDispatchContext.Provider value={dispatch}>
        {children}
      </SoilClimateDispatchContext.Provider>
    </SoilClimateStateContext.Provider>
  );
}

function useSoilClimateState() {
  const context = useContext(SoilClimateStateContext);
  if (context === undefined) {
    throw new Error(
      "useSoilClimateState must be used within a SoilClimateProvider"
    );
  }
  return context;
}

function useSoilClimateDispatch() {
  const context = useContext(SoilClimateDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSoilClimateDispatch must be used within a SoilClimateProvider"
    );
  }
  return context;
}

async function fetchSoilClimates(
  dispatch,
  clientId,
  fieldId,
  range,
  token,
  timezone
) {
  dispatch({ type: "setStatus", payload: "pending" });
  try {
    const response = await ListSoilClimates(token, clientId, fieldId, range);
    const data = response.data
      ? setLocalTimestamps(response.data, timezone).reverse()
      : [];
    dispatch({ type: "setSoilClimate", payload: data });
    dispatch({ type: "setStatus", payload: "resolved" });
    return data;
  } catch (e) {
    dispatch({ type: "setStatus", payload: "rejected" });
    handleRequestError(e, "Failed fetching ambient climate: ");
  }
}

export {
  SoilClimateProvider,
  useSoilClimateState,
  useSoilClimateDispatch,
  fetchSoilClimates,
};
