import React, { cloneElement, useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useChartTables, useChartDropdownActions } from "./hooks";
import CardLayout from "./components/CardLayout";
import { ReactComponent as IconLogger } from "../../../../../assets/images/icons/icon-logger.svg";
import { ReactComponent as IconFarmer } from "../../../../../assets/images/icons/icon-farmer.svg";
import ListItemWarning from "../../../../elements/ListItemWarning";

const useStyles = makeStyles((theme) => ({
  chart: {
    width: "100%",
  },
}));

const Card = ({
  loading,
  chart,
  title,
  isLogger,
  tables: tableList,
  children,
}) => {
  const classes = useStyles();
  const defaultActions = useChartDropdownActions();
  const { tables, tableActions } = useChartTables(tableList);
  const options = useMemo(() => [{ ...defaultActions }, { ...tableActions }], [
    defaultActions,
    tableActions,
  ]);

  const [dimensions, setDimentions] = useState({ width: null, height: null });
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setDimentions({
        width: node.getBoundingClientRect().width,
        height: node.getBoundingClientRect().height,
      });
    }
  }, []);

  return (
    <CardLayout title={title} loading={loading} options={options}>
      {chart ? (
        <div ref={measuredRef} className={classes.chart}>
          {cloneElement(chart, { parent: { ...dimensions } })}
        </div>
      ) : (
        <ListItemWarning
          icon={isLogger ? <IconFarmer /> : <IconLogger />}
          message="No data found for this field within the selected date range."
        />
      )}
      {tables?.map(({ display, table }) => (display && table ? table : null))}
      {children}
    </CardLayout>
  );
};

export default Card;
