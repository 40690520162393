import React, { Children, cloneElement, useMemo, isValidElement } from "react";
import {
  makeStyles,
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import Loader from "../../../../../../elements/Loader";
import DropdownMenu from "../../../../../../elements/DropdownMenu";
import { useChartWidth } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "100%",
    borderRadius: 0,
    boxShadow: "inset 0 -5px 0 0 " + theme.palette.primary.main,
    position: "relative",
  },
  sections: {
    paddingTop: theme.spacing(2),
  },
}));

const CardLayout = ({ title, children, loading = false, options }) => {
  const width = useChartWidth(options);
  const classes = useStyles();
  const content = useMemo(
    () =>
      Children?.map(children, (child) =>
        isValidElement(child) ? (
          <div className={classes.sections}>{cloneElement(child)}</div>
        ) : null
      ),
    [children, classes.sections]
  );

  return (
    <Grid item xs={12} lg={width || 6} xl={width || 3}>
      <Loader active={loading} showChildren={true} message={`Loading...`}>
        <MuiCard className={classes.card}>
          <CardContent>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h4">{title}</Typography>
              </Grid>
              {options ? (
                <Grid item>
                  <DropdownMenu sections={options} />
                </Grid>
              ) : null}
            </Grid>
            {content}
          </CardContent>
        </MuiCard>
      </Loader>
    </Grid>
  );
};

export default CardLayout;
