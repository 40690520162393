import React from "react";
import moment from "moment";
import { getLocalDate, percentage } from "../../../../helpers";
import { MTableToolbar } from "material-table";

export function getMetricsValue(data, metric, params) {
  try {
    let value = null;
    if (metric.substring(0, 3) === "ag_") {
      value = data[metric.slice(3)];
    } else {
      const irriTrigger = params && params[8] ? params[8].value : null;
      switch (metric) {
        case "mildew":
          value = Math.round(data.value);
          break;
        case "ambient_temp":
          value = data.temperature;
          break;
        case "humidity":
          value = percentage(Number(data.humidity), 1);
          break;
        case "irri_trigger":
          value = irriTrigger;
          break;
        case "predicted_irri":
          const smd = data?.smd;
          const gains = data?.gains;
          if (irriTrigger && smd && gains && gains > 0) {
            const days = Math.floor((irriTrigger - smd) / 1.25);
            value = moment().add(days, "days").format("DD/MM/YY");
          }
          break;
        default:
          value = data[metric];
          break;
      }
    }
    return value;
  } catch (error) {
    return null;
  }
}

export function getMomentTimestamp(data, timezone) {
  if (!data || !data.timestamp) {
    return null;
  }
  return moment.utc(
    getLocalDate(data.timestamp, timezone).format("YYYY-MM-DD HH:mm:ss")
  );
}

export function getFilteredMetrics(
  data,
  kpis,
  metrics,
  metricGroup,
  group,
  timezone,
  params
) {
  return metrics?.length
    ? metrics
        .filter(
          ({ ref, module_id }) =>
            module_id === metricGroup[group] && kpis[group]?.includes(ref)
        )
        .map(({ short_name, ref, suffix, thresholds }) => ({
          short_name,
          ref,
          suffix,
          thresholds,
          value: getMetricsValue(data[group], ref, params),
          parameters: ref === "moisture_content" ? data.parameters : null,
          timestamp: getMomentTimestamp(data[group], timezone),
        }))
    : [];
}

export function getKpis(
  data,
  permissions,
  metrics,
  timezone,
  field_type,
  params
) {
  if (!data) return [];
  const substrateKpis = {
    soil_climate: ["run_off", "moisture_content", "gains", "vic", "soil_temp"],
    agronomics: [
      "ag_ph_in",
      "ag_ph_out",
      "ag_ph_bag",
      "ag_ec_in",
      "ag_ec_out",
      "ag_ec_bag",
      "ag_ec_sum",
      "ag_run_off",
    ],
    ambient_climate: ["ambient_temp", "humidity", "vpd"],
    disease_risk: ["mildew"],
  };
  const soilKpis = {
    daily: {
      soil_climate: ["gains"],
    },
    soil_climate: [
      "moisture_content",
      "smd",
      "rainfall",
      "irri_trigger",
      "predicted_irri",
    ],
    agronomics: [],
    ambient_climate: ["ambient_temp", "humidity"],
    disease_risk: [],
  };
  const kpis = field_type === "soil" ? soilKpis : substrateKpis;
  const metricGroup = {
    soil_climate: 4,
    agronomics: 2,
    ambient_climate: 3,
    disease_risk: 1,
  };

  const latest = Object.keys(metricGroup).reduce((arr, group) => {
    if (!permissions.includes(metricGroup[group])) return arr;
    return [
      ...arr,
      ...getFilteredMetrics(
        data,
        kpis,
        metrics,
        metricGroup,
        group,
        timezone,
        params
      ),
    ];
  }, []);

  const daily = kpis?.daily
    ? Object.keys(metricGroup).reduce((arr, group) => {
        if (!permissions.includes(metricGroup[group])) return arr;
        return [
          ...arr,
          ...getFilteredMetrics(
            data,
            kpis.daily,
            metrics,
            metricGroup,
            group,
            timezone,
            params
          ),
        ];
      }, [])
    : [];
  return [...latest, ...daily];
}

export function getVisibility(field, visibility) {
  const settings = visibility.filter(({ field_id }) => field.id === field_id);
  if (!settings.length) {
    return { order: 999, hidden: false };
  }
  const { order, hidden } = settings[0];
  return { order, hidden };
}

export function fieldsWithKpis(fields, permissions, metrics) {
  return fields?.length
    ? fields.map(
        ({
          id,
          name,
          farm,
          field_type,
          crop_type,
          irrimax,
          latest,
          timezone,
        }) => ({
          id,
          name,
          farm,
          field_type,
          crop_type,
          irrimax,
          data: getKpis(
            latest,
            permissions,
            metrics,
            timezone,
            field_type,
            latest?.parameters
          ),
          parameters: latest?.parameters,
        })
      )
    : [];
}

export function filteredSortedFields(fields, { farm, crop_type }) {
  return fields
    .filter(
      (field) =>
        !field.hidden &&
        (farm === "" || farm === field.farm) &&
        (crop_type === "" || crop_type === field.crop_type)
    )
    .sort((a, b) => (a.order > b.order ? 1 : -1));
}

export function fieldsByType(fields, type) {
  return fields.filter(({ field_type }) => field_type === type);
}

export const defaultTableOptions = {
  search: false,
  padding: "dense",
  actionsColumnIndex: -1,
  exportButton: true,
  exportAllData: true,
};

export const analyticsTableToolbar = (theme) => ({
  Toolbar: (props) => (
    <div
      style={{
        backgroundColor: theme.palette.tertiary.light,
        color: theme.palette.tertiary.dark,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      }}
    >
      <MTableToolbar {...props} />
    </div>
  ),
});
