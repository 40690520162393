import React, { Fragment, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Typography } from "@material-ui/core";
import { DateTimeInput, TextInput } from "./FormInputs";

const validate = (values) => {
  const errors = {};
  const requiredFields = ["type"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

const RecordRecommendation = ({
  title,
  timezone,
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
}) => {
  useEffect(() => {
    isValid(!pristine && valid && !submitting);
    isWaiting(submitting);
  });

  return (
    <Fragment>
      {title && (
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
      )}
      <form noValidate onSubmit={handleSubmit}>
        <Field
          component={DateTimeInput}
          name="timestamp"
          id="recommendation-form-timestamp"
          label="Timestamp"
        />
        <Field
          component={TextInput}
          name="description"
          id="recommendation-description"
          label="Description"
          multiline
          rows={4}
        />
        <Typography variant="body1" gutterBottom color="error">
          <b>{error}</b>
        </Typography>
      </form>
    </Fragment>
  );
};

export default reduxForm({ form: "RecordRecommendation", validate })(
  RecordRecommendation
);
