import React, { useEffect } from "react";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import {
  useSettingsState,
  useSettingsDispatch,
  setFilterOptions,
} from "../../context/settings";
import {
  useFieldsFiltersDispatch,
  useFieldsFiltersState,
} from "../../context/field-filters";

export const Filter = ({ id, label, value, options, handleChange, color }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 120,
      maxWidth: 190,
      marginRight: theme.spacing(2),
    },
    input: {
      color: color ? color : theme.palette.text.secondary,
      fontWeight: 700,
      fontSize: "0.9rem",
    },
  }));
  const classes = useStyles();

  return (
    <FormControl className={classes.root}>
      <InputLabel shrink id={`field-${id}-filter-label`}>
        {label}
      </InputLabel>
      <Select
        className={classes.input}
        labelId={`field-${id}-filter-label`}
        id={`field-${id}-filter`}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
        displayEmpty
        disableUnderline
      >
        <MenuItem value="">None</MenuItem>
        {options.map((option, index) => (
          <MenuItem key={`field-${id}-filter-option-${index}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const FieldFilters = ({ color, fields }) => {
  const { fieldsSettings } = useSettingsState();
  const { filters } = fieldsSettings;
  const selection = useFieldsFiltersState();
  const settingsDispatch = useSettingsDispatch();
  const filtersDispatch = useFieldsFiltersDispatch();
  useEffect(() => {
    setFilterOptions(settingsDispatch, fields);
  }, [settingsDispatch, fields]);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      color: theme.palette.primary.dark,
      marginRight: theme.spacing(2),
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LocationSearchingIcon className={classes.icon} />
      <Filter
        id="farm"
        label="Farm"
        value={selection.farm}
        options={filters.farm}
        handleChange={(value) =>
          filtersDispatch({
            type: "fieldsFilterSelection",
            payload: { farm: value },
          })
        }
        color={color}
      />
      <Filter
        id="crop_type"
        label="Crop Type"
        value={selection.crop_type}
        options={filters.crop_type}
        handleChange={(value) =>
          filtersDispatch({
            type: "fieldsFilterSelection",
            payload: { crop_type: value },
          })
        }
        color={color}
      />
    </div>
  );
};

export default FieldFilters;
