import React, { useState } from "react";
import {
  makeStyles,
  useTheme,
  Grid,
  // IconButton,
  // Fade,
} from "@material-ui/core";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AnalyticsDateTime from "../../../../header/AnalyticsDateTime";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    // [theme.breakpoints.up("md")]: {
    //   justifyContent: "flex-end",
    // },
  },
  expandButton: {
    maxWidth: 48,
  },
  // icon: ({ open }) => ({
  //   transform: open ? "rotate(180deg)" : "",
  //   transition: "transform 150ms ease",
  // }),
}));

const AnalyticsFooter = () => {
  const [
    open,
    // setOpen
  ] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ open });

  // const handleToggle = () => setOpen(!open);

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item>
        <AnalyticsDateTime color={theme.palette.secondary.main} />
      </Grid>
      {/* <Grid item sm className={classes.expandButton}>
        <IconButton onClick={handleToggle}>
          <ExpandLessIcon className={classes.icon} />
        </IconButton>
      </Grid>

      {open ? (
        <Fade in={open} style={{ transformOrigin: "50% 50%" }}>
          <Grid item xs={12} lg>
            Expandable section goes here
          </Grid>
        </Fade>
      ) : null} */}
    </Grid>
  );
};

export default AnalyticsFooter;
