import React, { useState, Children, cloneElement } from "react";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { Typography, CardContent, makeStyles } from "@material-ui/core";
import AnalyticsTable from "./AnalyticsTable";
import { handleRequestError, triggerToast } from "../../helpers";
import Modal from "../elements/Modal";
import DeleteRecord from "../actions/DeleteRecord";

import FormButton from "../forms/FormButton";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const EditDeleteTable = ({
  title,
  type,
  data,
  record,
  setRecord,
  children,
  onUpdate,
  onDelete,
  onRefresh,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const classes = useStyles();

  const dispatch = useDispatch();

  const handleEditModal = (event, row) => {
    openModal();
    setRecord(row.id);
    setAction("update");
  };
  const handleDeleteModal = (event, row) => {
    openModal();
    setRecord(row.id);
    setAction("delete");
  };

  const handleUpdate = async (formValues) => {
    try {
      const response = await onUpdate(formValues);
      onRefresh();
      dispatch(triggerToast(response.message, { variant: "success" }));
      closeModal();
    } catch (e) {
      handleRequestError(e, `Failed to update ${type}: `);
    }
  };

  const handleDelete = async () => {
    setWaiting(true);
    try {
      const response = await onDelete();
      onRefresh();
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      closeModal();
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to delete ${type}: `);
    }
  };

  const addPropsToForm = (child) =>
    cloneElement(child, {
      isValid: (status) => setValid(status),
      isWaiting: (status) => setWaiting(status),
      onSubmit: handleUpdate,
      initialValues: record,
    });

  return (
    <>
      {data ? (
        <>
          <AnalyticsTable
            config={data}
            onEdit={{
              tooltip: `Edit ${type}`,
              handleClick: handleEditModal,
            }}
            onDelete={{
              tooltip: `Delete ${type}`,
              handleClick: handleDeleteModal,
            }}
          />
          {record && (
            <Modal open={modalOpen}>
              {action === "update" && (
                <CardContent>
                  {record !== null &&
                    children &&
                    Children.map(children, (child) => {
                      if (child && child.props.form) {
                        const updatedChild = addPropsToForm(child);
                        return [
                          updatedChild,
                          <div className={classes.buttonGroup}>
                            <FormButton text="Close" handleClick={closeModal} />
                            <FormButton
                              text="Update"
                              disabled={!valid || waiting}
                              handleClick={() =>
                                dispatch(submit(updatedChild.props.form))
                              }
                              variant="contained"
                              color="primary"
                              waiting={waiting}
                              waitingText="Saving"
                            />
                          </div>,
                        ];
                      } else {
                        return child;
                      }
                    })}
                </CardContent>
              )}
              {action === "delete" && (
                <DeleteRecord
                  title={`Delete ${type}`}
                  id={record.id}
                  waiting={waiting}
                  onDelete={handleDelete}
                  closeModal={closeModal}
                />
              )}
            </Modal>
          )}
        </>
      ) : (
        <>
          <Typography variant="h3" component="h3" gutterBottom>
            {title}
          </Typography>
          <Typography>
            No {type} recorded within the selected date range
          </Typography>
        </>
      )}
    </>
  );
};

export default EditDeleteTable;
