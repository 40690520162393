import api from "./agritech";

async function auth(credentials) {
  const response = await api.post(`login`, credentials);
  return response.data;
}

async function getUser(token) {
  const response = await api.get(`user`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function refresh(token) {
  const response = await api.post(
    `refresh`,
    {},
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export { auth, getUser, refresh };
