import React, { useLayoutEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAnalyticsDispatch, fetchField } from "../../../context/analytics";
import { setVisibility, useSettingsDispatch } from "../../../context/settings";
import { AmbientClimateProvider } from "../../../context/ambient-climate";
import { AgronomicsProvider } from "../../../context/agronomics";
import { MildewProvider } from "../../../context/mildew";
import { SoilClimateProvider } from "../../../context/soil-climate";
import { SqueezeProvider } from "../../../context/squeeze";
import { RecommendationProvider } from "../../../context/recommendation";
import { ParametersProvider } from "../../../context/parameters";
import { useAuthState, refresh, useAuthDispatch } from "../../../context/auth";
import {
  MetricsProvider,
  useMetricsDispatch,
  fetchMetrics,
} from "../../../context/metrics";
import Layout from "./components/Layout";
import { ChartProvider } from "../../../context/chart";

const Analytics = () => (
  <MetricsProvider>
    <AmbientClimateProvider>
      <SoilClimateProvider>
        <SqueezeProvider>
          <ParametersProvider>
            <RecommendationProvider>
              <AgronomicsProvider>
                <MildewProvider>
                  <ChartProvider>
                    <AnalyticsContent />
                  </ChartProvider>
                </MildewProvider>
              </AgronomicsProvider>
            </RecommendationProvider>
          </ParametersProvider>
        </SqueezeProvider>
      </SoilClimateProvider>
    </AmbientClimateProvider>
  </MetricsProvider>
);

const AnalyticsContent = () => {
  const [status, setStatus] = useState("idle");
  const user = useAuthState();
  const { client, token, tokenExpiry, settings } = user;
  const { fieldId } = useParams();
  const authDispatch = useAuthDispatch();
  const analyticsDispatch = useAnalyticsDispatch();
  const metricsDispatch = useMetricsDispatch();
  const settingsDispatch = useSettingsDispatch();

  const loadField = useCallback(async () => {
    setStatus("pending");
    try {
      await fetchField(
        analyticsDispatch,
        client.id,
        fieldId,
        client.directory,
        token
      );
      setVisibility(settingsDispatch, client.id, settings);
      setStatus("resolved");
    } catch (e) {
      setStatus("rejected");
    }
  }, [
    setStatus,
    analyticsDispatch,
    client.id,
    client.directory,
    token,
    settings,
    fieldId,
    settingsDispatch,
  ]);

  // Load field initially
  useLayoutEffect(() => {
    refresh(authDispatch, { token, tokenExpiry });
    loadField();
  }, [authDispatch, user, loadField, token, tokenExpiry]);

  // Load metrics
  useLayoutEffect(() => {
    const { token } = user;
    fetchMetrics(metricsDispatch, token);
  }, [user, metricsDispatch]);

  return <Layout fieldId={fieldId} loading={[status, setStatus]} />;
};

export default Analytics;
