import api from "./agritech";

async function listModules(token) {
  const response = await api.get(`/modules`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export { listModules };
