import React from "react";
import SettingsToggle from "./SettingsToggle";
import SettingsHeader from "./SettingsHeader";
import SettingsForm from "./SettingsForm";
import { useSidebarState, useSidebarDispatch } from "../../context/sidebar";
import { useSettingsState } from "../../context/settings";
import { useFieldsState } from "../../context/fields";

const DashboardSettings = ({ user, loadingState }) => {
  const { open, settingsOpen } = useSidebarState();
  const {
    fieldsSettings: { visibility },
  } = useSettingsState();
  const { fields } = useFieldsState();
  const settings =
    visibility &&
    fields &&
    visibility.map((item) => {
      const field = fields.filter((field) => item.field_id === field.id);
      return {
        ...item,
        label: field.length ? field[0].name : "Unknown",
      };
    });
  const sidebarDispatch = useSidebarDispatch();
  const toggleDisplay = () => {
    !open && sidebarDispatch({ type: "setOpen", payload: !open });
    sidebarDispatch({ type: "setSettingsOpen", payload: !settingsOpen });
  };

  if (open && settingsOpen) {
    return (
      <SettingsForm
        user={user}
        label="field"
        items={settings}
        subheader={<SettingsHeader close={toggleDisplay} />}
        setLoading={(state) => loadingState(state)}
      />
    );
  } else {
    return <SettingsToggle click={toggleDisplay} />;
  }
};

export default DashboardSettings;
