import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import {
  Typography,
  FormControl,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import { TextInput, CheckboxInput } from "../FormInputs";
import { useStyles } from "../../pages/admin/Admin";

const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

const ClientForm = ({
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
  initialValues,
  permissions,
}) => {
  const classes = useStyles();
  useEffect(() => {
    isValid(!pristine && valid && !submitting);
    isWaiting(submitting);
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field
        component={TextInput}
        name="name"
        id="name"
        autoFocus={!initialValues}
        label="Name"
      />
      <Field
        component={TextInput}
        name="directory"
        id="directory"
        label="Irrimax Directory"
      />

      <div className={classes.table}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Permissions</FormLabel>
          <FormGroup row>
            {permissions &&
              permissions.map((permission, index) => {
                return (
                  <Field
                    key={`access_${index}`}
                    name={`access_${permission.id}`}
                    id={`access_${permission.id}`}
                    component={CheckboxInput}
                    label={permission.name}
                  />
                );
              })}
          </FormGroup>
        </FormControl>
      </div>

      <Typography variant="body1" gutterBottom color="error">
        <b>{error}</b>
      </Typography>
    </form>
  );
};

export default reduxForm({ form: "ClientForm", validate })(ClientForm);
