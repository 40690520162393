import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/";

const getStatus = (
  value,
  type,
  { high = null, warning = null, low = null }
) => {
  if (value === null) {
    return "grey";
  }
  if (high === null && low === null) {
    return null;
  }
  const isHigh = high !== null && value >= high;
  const isWarning = warning !== null && value > low && value <= warning;
  const isLow = low !== null && value <= low;
  if (type === "mildew") {
    return isHigh ? "danger" : isLow ? "primary" : "warning";
  }
  if (type === "moisture_content") {
    return isLow
      ? "danger"
      : isWarning
      ? "warning"
      : isHigh
      ? "info"
      : "primary";
  }
  if (type === "gains") {
    return isHigh ? "info" : isLow ? "danger" : "primary";
  }
  return isHigh ? "danger" : isLow ? "pink" : "primary";
};

const getThreshold = (type, defaults, params = null) => {
  if (type !== "moisture_content") return defaults;
  if (params === null) return defaults;

  return Object.keys(params).reduce(
    (obj, item) => {
      // full_point (2) or field_capactiy (1)
      if (["2", "1"].includes(item)) {
        obj.high = params[item].value && Number(params[item].value);
      }
      // med_squeeze (4) or irri_trigger (8)
      if (["4", "8"].includes(item)) {
        obj.warning = params[item].value && Number(params[item].value);
      }
      // irri_lower (5) or refill_point (9)
      if (["5", "9"].includes(item)) {
        obj.low = params[item].value && Number(params[item].value);
      }
      return obj;
    },
    { high: null, low: null }
  );
};

const Statistic = ({ type, value, suffix, thresholds, parameters }) => {
  const useStyles = makeStyles((theme) => ({
    stat: { fontWeight: "bold" },
    primary: {
      color: theme.palette.primary.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    info: {
      color: theme.palette.info.main,
    },
    danger: {
      color: theme.palette.danger.main,
    },
    pink: {
      color: theme.palette.pink.main,
    },
    grey: {
      color: theme.palette.grey[400],
    },
  }));
  const classes = useStyles();
  const status = getStatus(
    value,
    type,
    getThreshold(type, thresholds, parameters)
  );

  return (
    <span className={clsx(classes.stat, status && classes[status])}>
      {value === null ? "N/A" : suffix ? value + suffix : value}
    </span>
  );
};

export default Statistic;
