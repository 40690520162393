import { Typography } from "@material-ui/core";
import React from "react";
import { useAnalyticsState } from "../../../../../../../context/analytics";
import { useSoilClimateState } from "../../../../../../../context/soil-climate";
import Card from "../../../Card";
import {
  useDepthSensors,
  useLoggerLoadingStatus,
  useSensorDataTable,
} from "../../../Card/hooks";
import { useAxisesMinMax, useChartData, useChartMetrics } from "../../hooks";
import DefaultChart from "../DefaultChart";

const StackedChart = ({ title, kpi, axisLabel }) => {
  const {
    field: { sensors },
  } = useAnalyticsState();
  const { status, data: rawData } = useSoilClimateState();
  const loading = useLoggerLoadingStatus(status);
  const { values, refs } = useDepthSensors(sensors, kpi);
  const defaultTable = useSensorDataTable(rawData, title, refs);
  const { data, hasData } = useChartData(Object.values(rawData), refs);
  const metrics = useChartMetrics(refs);
  const datasets = refs.reduce((obj, key, i) => {
    return {
      ...obj,
      [key]: {
        ...metrics[key],
        id: key,
        type: "line",
        axis: kpi,
        title: `${values[i]}cm`,
      },
    };
  }, {});
  const axis = {
    [kpi]: {
      id: 0,
      orientation: "left",
      label: axisLabel,
    },
  };

  const StackedChart = ({ title, data, datasets, axis, parent }) => {
    const axises = useAxisesMinMax(axis, datasets, data);
    return (
      <>
        <Typography variant="h6">{title}</Typography>
        <DefaultChart
          {...{
            data,
            datasets,
            axises,
            parent,
          }}
        />
      </>
    );
  };

  const Charts = ({ parent }) =>
    Object.values(datasets).map(({ title, ...dataset }, i) => (
      <StackedChart
        key={`stacked-chart-${dataset?.key}-${i}`}
        title={title}
        data={[data[i]]}
        datasets={{ [dataset?.id]: { ...dataset } }}
        axis={axis}
        parent={parent}
      />
    ));

  return (
    <Card
      chart={hasData ? <Charts /> : null}
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default StackedChart;
