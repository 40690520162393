import React from "react";
import moment from "moment";
import AdminTable from "./AdminTable";
import { deleteField, restoreField } from "../../../api/fields";
import { useTheme, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudOffIcon from "@material-ui/icons/CloudOff";

const useStyles = makeStyles((theme) => {
  const defaultIcon = {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
  };
  return {
    iconOn: {
      ...defaultIcon,
      color: theme.palette.primary.main,
    },
    iconOff: {
      ...defaultIcon,
      color: theme.palette.error.main,
    },
  };
});

const FieldsTable = ({
  status,
  data,
  user,
  onRefresh,
  onRowClick,
  activeField,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const config = {
    columns: [
      {
        title: "Name",
        field: "name",
        render: ({ name }) => <strong>{name}</strong>,
      },
      {
        title: "Farm",
        field: "farm",
      },
      {
        title: "Crop Type",
        field: "crop_type",
      },
      {
        title: "Field Type",
        field: "field_type",
        render: ({ field_type }) => field_type,
      },
      {
        title: "Client",
        field: "client",
        render: ({ client }) => client && client.name,
      },
      {
        title: "Import",
        field: "import",
        render: (rowData) => {
          const { import_summary, last_import } = rowData;
          return (
            <Tooltip
              title={
                <>
                  <Typography color="inherit">
                    {import_summary && import_summary.timestamp
                      ? moment
                          .utc(import_summary.timestamp)
                          .format("DD/MM/YY HH:mm")
                      : "Unknown"}
                  </Typography>
                  {import_summary &&
                  import_summary.status &&
                  import_summary.status.length
                    ? import_summary.status.map((item, index) => (
                        <p key={`status_${index}`}>{item}</p>
                      ))
                    : "No data"}
                </>
              }
            >
              <span>
                {rowData.import ? (
                  <CloudDownloadIcon className={classes.iconOn} />
                ) : (
                  <CloudOffIcon className={classes.iconOff} />
                )}
                {last_import ? moment.utc(last_import).fromNow() : "Never"}
              </span>
            </Tooltip>
          );
        },
      },
    ],
    data,
    onRowClick,
    options: {
      pageSizeOptions: [5, 10, 20, 50],
      rowStyle: (row) => {
        return row && activeField && row.id === activeField.id
          ? { backgroundColor: theme.palette.secondary.main }
          : null;
      },
    },
  };

  const history = useHistory();
  const onCreate = () => history.push("/admin/field");
  // const onView = (id) => history.push(`/field/${id}`);
  const onEdit = (id) => history.push(`/admin/field/${id}`);
  const onDelete = (record) =>
    deleteField(user.token, record.client.id, record.id);
  const onRestore = (record) =>
    restoreField(user.token, record.client.id, record.id);

  return (
    <AdminTable
      title={<Typography variant="h2">Fields</Typography>}
      type="fields"
      config={config}
      onRefresh={onRefresh}
      onCreate={status?.includes("create") ? onCreate : null}
      onEdit={status?.includes("edit") ? onEdit : null}
      // onView={onView}
      onDelete={status?.includes("delete") ? onDelete : null}
      onRestore={status?.includes("restore") ? onRestore : null}
    />
  );
};

export default FieldsTable;
