import React, { createContext, useReducer, useContext } from "react";
import { ListParameterTypes, ListParameters } from "../api/parameters";
import { setLocalTimestamps, handleRequestError } from "../helpers";

const ParameterStateContext = createContext();
const ParameterDispatchContext = createContext();

function parametersReducer(state, action) {
  switch (action.type) {
    case "setTypes": {
      return { ...state, types: [...action.payload] };
    }
    case "setParameters": {
      return { ...state, params: [...action.payload] };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ParametersProvider({ children }) {
  const [state, dispatch] = useReducer(parametersReducer, {
    types: [],
    params: [],
  });
  return (
    <ParameterStateContext.Provider value={state}>
      <ParameterDispatchContext.Provider value={dispatch}>
        {children}
      </ParameterDispatchContext.Provider>
    </ParameterStateContext.Provider>
  );
}

function useParameterState() {
  const context = useContext(ParameterStateContext);
  if (context === undefined) {
    throw new Error(
      "useParameterState must be used within a ParametersProvider"
    );
  }
  return context;
}

async function fetchParameterTypes(dispatch, token) {
  try {
    const response = await ListParameterTypes(token);
    dispatch({ type: "setTypes", payload: response.data });
    return response.data;
  } catch (e) {
    handleRequestError(e, "Failed fetching parameter types: ");
  }
}

async function fetchParameters(
  dispatch,
  clientId,
  fieldId,
  range,
  token,
  timezone
) {
  try {
    const response = await ListParameters(token, clientId, fieldId, range);
    const data =
      response.data && timezone
        ? setLocalTimestamps(response.data, timezone)
        : [];
    dispatch({ type: "setParameters", payload: data });
    return data;
  } catch (e) {
    handleRequestError(e, "Failed fetching parameters: ");
  }
}

function useParameterById(id) {
  const { params } = useParameterState();
  const record = id && params.filter((item) => item.id === id);
  return record && record.length && record[0];
}

function useParameterDispatch() {
  const context = useContext(ParameterDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useParameterDispatch must be used within a ParametersProvider"
    );
  }
  return context;
}

export {
  ParametersProvider,
  useParameterState,
  useParameterDispatch,
  fetchParameterTypes,
  fetchParameters,
  useParameterById,
};
