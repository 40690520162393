import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";

const ListItemWarning = ({ message, icon }) => {
  const useStyles = makeStyles((theme) => ({
    icon: {
      maxWidth: 150,
      "& path, & circle": {
        fill: theme.palette.text.disabled,
      },
    },
  }));
  const classes = useStyles();
  return (
    <Grid container spacing={2} alignItems="center">
      {icon && (
        <Grid item xs className={classes.icon}>
          {icon}
        </Grid>
      )}
      <Grid item xs>
        <Typography color="textSecondary">{message}</Typography>
      </Grid>
    </Grid>
  );
};

export default ListItemWarning;
