import React from "react";
import { Grid, makeStyles } from "@material-ui/core/";
import clsx from "clsx";
import { useSidebarState } from "../../context/sidebar";

const Footer = ({ children, drawerWidth }) => {
  const useStyles = makeStyles((theme) => ({
    footer: () => ({
      backgroundColor: theme.palette.primary.main,
      position: "fixed",
      zIndex: 10,
      bottom: 0,
      left: 0,
      padding: theme.spacing(2, 3),
      width: "100%",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft: 72,
        width: `calc(100% - 72px)`,
      },
    }),
    footerShift: {
      marginLeft: `${drawerWidth}px !important`,
      width: `calc(100% - ${drawerWidth}px) !important`,
    },
  }));

  const { open } = useSidebarState();
  const { footer, footerShift } = useStyles();

  return (
    <footer className={clsx(footer, open && footerShift)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
