import React from "react";
import { Box, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { sidebarStyles } from "./SidebarContent";

export default (props) => {
  const classes = sidebarStyles();

  return (
    <ListItem button onClick={props.click}>
      <ListItemIcon>
        <MoreVertIcon className={classes.listItemIcon} />
      </ListItemIcon>
      <ListItemText>
        <Box component="span" className={classes.navText}>
          Settings
        </Box>
      </ListItemText>
    </ListItem>
  );
};
