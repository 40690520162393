import React, { Fragment, useState } from "react";
import { makeStyles, useTheme, Zoom, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ActionStepper from "../actions/ActionStepper";
import Modal from "./Modal";

export default () => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const useStyles = makeStyles((theme) => ({
    fab: {
      width: 35,
      height: 35,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      transition: "background-color 150ms ease-in-out",

      [theme.breakpoints.up("md")]: {
        width: 40,
        height: 40,
      },

      "&:hover, &:active, &:focus": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.dark,
      },
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      width: 20,
      height: 20,
    },
  }));
  const classes = useStyles();

  return (
    <Fragment>
      <Zoom
        in={true}
        timeout={transitionDuration}
        style={{ transitionDelay: `${transitionDuration.exit}ms` }}
        unmountOnExit
      >
        <div className={classes.wrapper}>
          <IconButton
            color="primary"
            className={classes.fab}
            onClick={handleOpen}
          >
            <EditIcon className={classes.icon} />
          </IconButton>
        </div>
      </Zoom>
      <Modal open={modalOpen}>
        <ActionStepper close={handleClose} />
      </Modal>
    </Fragment>
  );
};
