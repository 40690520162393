import React, { useLayoutEffect, useState } from "react";
import {
  makeStyles,
  IconButton,
  Menu,
  Fade,
  ListItemIcon,
  ListSubheader,
  ListItem,
  ListItemText,
  List,
  ListItemSecondaryAction,
  Checkbox,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles(
  ({
    palette: {
      common: { white },
      grey,
    },
    spacing,
  }) => ({
    root: {
      "& ul": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    listSection: {
      borderBottom: `1px solid ${grey[200]}`,
      "&:last-child": {
        borderBottom: "none",
      },
    },
    listSubHeader: {
      display: "flex",
      alignItems: "center",
      background: white,
      color: grey[400],
      "& .MuiListItemIcon-root": {
        minWidth: spacing(4),
      },
      "& svg *": {
        fill: grey[400],
      },
    },
    icon: {
      minWidth: 36,
    },
  })
);

const DropdownMenu = ({ icon, sections }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [checked, setChecked] = useState([]);
  const classes = useStyles();

  useLayoutEffect(
    () =>
      setChecked(
        sections.reduce(
          (sectionsAcc, { items = [] }) => [
            ...sectionsAcc,
            items.reduce((itemsAcc, { type, att }, i) => {
              return type === "checkbox" && att?.checked
                ? [...itemsAcc, i]
                : itemsAcc;
            }, []),
          ],
          []
        )
      ),
    [sections]
  );

  const handleClose = () => setAnchorEl(null);

  const handleToggle = (sectionIndex, value, cb) => () => {
    if (checked[sectionIndex]) {
      const currentIndex = checked[sectionIndex].indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked[sectionIndex].push(value);
      } else {
        newChecked[sectionIndex].splice(currentIndex, 1);
      }

      setChecked(newChecked);

      if (cb) {
        cb();
      }
    }
  };

  const isChecked = (sectionIndex, itemIndex) => {
    if (checked[sectionIndex]) {
      return checked[sectionIndex].indexOf(itemIndex) !== -1;
    }
    return false;
  };

  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {icon || <MoreVertIcon />}
      </IconButton>
      <Menu
        className={classes.root}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        subheader={<li />}
      >
        {sections.map(({ headline, icon: headlineIcon, items }, i) => {
          return (
            <li key={`menu__section-${i}`} className={classes.listSection}>
              <List>
                <ListSubheader className={classes.listSubHeader}>
                  {headlineIcon ? (
                    <ListItemIcon className={classes.icon}>
                      {headlineIcon}
                    </ListItemIcon>
                  ) : null}
                  {headline}
                </ListSubheader>
                {items.map(({ type, icon, label, onClick }, j) => (
                  <ListItem
                    key={`menu__item-${j}`}
                    button={type === "link"}
                    onClick={
                      type === "link" && onClick
                        ? () => {
                            onClick();
                            handleClose();
                          }
                        : undefined
                    }
                  >
                    {icon ? (
                      <ListItemIcon className={classes.icon}>
                        {icon}
                      </ListItemIcon>
                    ) : null}
                    <ListItemText>{label}</ListItemText>
                    {type === "checkbox" ? (
                      <ListItemSecondaryAction>
                        <Checkbox
                          color="primary"
                          edge="end"
                          onChange={handleToggle(i, j, onClick)}
                          checked={isChecked(i, j)}
                          inputProps={{
                            "aria-labelledby": `menu__item-${j}__checkbox`,
                          }}
                        />
                      </ListItemSecondaryAction>
                    ) : null}
                  </ListItem>
                ))}
              </List>
            </li>
          );
        })}
      </Menu>
    </>
  );
};

export default DropdownMenu;
