import React, { useMemo, useState } from "react";
import moment from "moment";
import EditDeleteTable from "./EditDeleteTable";
import {
  useRecommendationById,
  fetchRecommendations,
  useRecommendationDispatch,
} from "../../context/recommendation";
import {
  DeleteRecommendation,
  UpdateRecommendation,
} from "../../api/recommendation";
import { formatDateRange } from "../../helpers";
import RecommendationForm from "../forms/RecommendationForm";
import { useTheme } from "@material-ui/core";
import {
  analyticsTableToolbar,
  defaultTableOptions,
} from "../pages/Analytics/util";

const RecommendationTable = ({ data, user, field, range }) => {
  const recommendationDispatch = useRecommendationDispatch();
  const [recommendationId, setRecommendationId] = useState(null);
  const recommendationRecord = useRecommendationById(recommendationId);
  const recommendations = useMemo(
    () =>
      data.length && {
        columns: [
          { title: "Timestamp", field: "timestamp_label" },
          { title: "Description", field: "description" },
        ],
        data: data.map((item) => ({
          ...item,
          timestamp_label: moment(item.timestamp).format("DD/MM/YY HH:mm"),
        })),
      },
    [data]
  );
  const handleRecommendationUpdate = ({ timestamp, description }) =>
    UpdateRecommendation(
      user.token,
      user.client.id,
      field.id,
      recommendationId,
      {
        description,
        timestamp: moment
          .tz(timestamp.format("YYYY-MM-DD HH:mm:ss"), field.timezone)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
      }
    );
  const handleRecommendationDelete = () =>
    DeleteRecommendation(
      user.token,
      user.client.id,
      field.id,
      recommendationId
    );
  const handleRecommendationRefresh = () =>
    fetchRecommendations(
      recommendationDispatch,
      user.client.id,
      field.id,
      formatDateRange(range),
      user.token,
      field.timezone
    );

  const theme = useTheme();
  const tableConfig = {
    title: "Recommendations",
    components: analyticsTableToolbar(theme),
    options: defaultTableOptions,
  };

  return (
    <EditDeleteTable
      title="Recommendations"
      type="Recommendation"
      data={{ ...recommendations, ...tableConfig }}
      record={recommendationRecord}
      setRecord={setRecommendationId}
      onUpdate={handleRecommendationUpdate}
      onDelete={handleRecommendationDelete}
      onRefresh={handleRecommendationRefresh}
    >
      <RecommendationForm
        title="Amend the Recommendation:"
        timezone={field.timezone}
      />
    </EditDeleteTable>
  );
};

export default RecommendationTable;
