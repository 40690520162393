import React, { createContext, useReducer, useContext } from "react";
import { ListRecommendations } from "../api/recommendation";
import { setLocalTimestamps, handleRequestError } from "../helpers";

const RecommendationStateContext = createContext();
const RecommendationDispatchContext = createContext();

function recommendationReducer(state, action) {
  switch (action.type) {
    case "setRecommendations": {
      return [...action.payload];
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function RecommendationProvider({ children }) {
  const [state, dispatch] = useReducer(recommendationReducer, []);
  return (
    <RecommendationStateContext.Provider value={state}>
      <RecommendationDispatchContext.Provider value={dispatch}>
        {children}
      </RecommendationDispatchContext.Provider>
    </RecommendationStateContext.Provider>
  );
}

function useRecommendationState() {
  const context = useContext(RecommendationStateContext);
  if (context === undefined) {
    throw new Error(
      "useRecommendationState must be used within a RecommendationProvider"
    );
  }
  return context;
}

async function fetchRecommendations(
  dispatch,
  clientId,
  fieldId,
  range,
  token,
  timezone
) {
  try {
    const response = await ListRecommendations(token, clientId, fieldId, range);
    const data = setLocalTimestamps(response.data, timezone);
    dispatch({ type: "setRecommendations", payload: data });
    return data;
  } catch (e) {
    handleRequestError(e, "Failed fetching recommendations: ");
  }
}

function useRecommendationById(id) {
  const data = useRecommendationState();
  const record = id && data.filter((item) => item.id === id);
  return record && record.length && record[0];
}

function useRecommendationDispatch() {
  const context = useContext(RecommendationDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useRecommendationDispatch must be used within a RecommendationProvider"
    );
  }
  return context;
}

export {
  RecommendationProvider,
  useRecommendationState,
  useRecommendationDispatch,
  fetchRecommendations,
  useRecommendationById,
};
