import React, { cloneElement } from "react";
import { useTheme, Grid, Fade } from "@material-ui/core";
// import { VictorySharedEvents } from "victory";
import { useSettingsState } from "../../../../../../../context/settings";
import { useCharts, useChartTransitionDuration } from "../../hooks";
import { usePermissions } from "../../../../../../../context/auth";
import { useAnalyticsState } from "../../../../../../../context/analytics";
import Irrimax from "../Irrimax";

function CardList({ children }) {
  const {
    field: { id, irrimax },
  } = useAnalyticsState();
  const {
    chartsSettings: { visibility },
  } = useSettingsState();
  const theme = useTheme();
  const transitionDuration = useChartTransitionDuration(theme);
  const permissions = usePermissions();
  const charts = useCharts(permissions, id, visibility);
  const hasIrrimax = irrimax?.length;
  const hasCharts = charts?.length || hasIrrimax;

  if (!visibility && !hasCharts) return null;

  // const chartIds = ["vpd", "humidity"];
  // // const chartIds = charts.map(({ id }) => id);

  // const onMouseOver = () => {
  //   return [
  //     {
  //       childName: chartIds,
  //       mutation: (props) => {
  //         console.log(props);
  //         return {
  //           style: Object.assign({}, props.style, {
  //             fill: "lime",
  //             stroke: "lime",
  //           }),
  //         };
  //       },
  //     },
  //   ];
  // };
  // const onMouseOut = () => {
  //   return [
  //     {
  //       childName: chartIds,
  //       mutation: () => {
  //         return null;
  //       },
  //     },
  //   ];
  // };

  return (
    <Grid container spacing={3}>
      {hasIrrimax ? <Irrimax charts={irrimax} /> : null}
      {/* <VictorySharedEvents
        events={[
          {
            childName: chartIds,
            target: "data",
            eventHandlers: {
              onMouseOver,
              onMouseOut,
            },
          },
        ]}
      ></VictorySharedEvents> */}
      {charts.map(({ chart, label: title, ...rest }) => {
        if (!chart) return null;

        const Chart = () => cloneElement(chart, { title, ...rest });

        return (
          <Fade
            key={`${id}-${rest?.id}`}
            in={true}
            timeout={transitionDuration}
            style={{ transitionDelay: `${transitionDuration.exit}ms` }}
            unmountOnExit
          >
            <Chart />
          </Fade>
        );
      })}
      {children}
    </Grid>
  );
}

export default CardList;
