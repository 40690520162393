import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import {
  Button,
  makeStyles,
  CircularProgress,
  Typography,
  Zoom,
} from "@material-ui/core";
import { login, useAuthDispatch } from "../../context/auth";
import { TextInput } from "../forms/FormInputs";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    marginRight: theme.spacing(1),
  },
}));

const validate = (values) => {
  const errors = {};
  const requiredFields = ["email", "password"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }

  if (values.password && values.password.length < 4) {
    errors.password = "Password my be at least 4 characters long.";
  }
  return errors;
};

const LoginForm = ({ handleSubmit, pristine, error, valid }) => {
  const classes = useStyles();
  const dispatch = useAuthDispatch();
  const [loading, setLoading] = useState();

  const onSubmit = async (formValues) => {
    setLoading(true);
    await login(dispatch, formValues);
    setLoading(false);
  };
  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
      <Field
        component={TextInput}
        name="email"
        id="email"
        type="email"
        autoFocus
        label="Email Address"
        autoComplete="email"
        variant="outlined"
      />
      <Field
        component={TextInput}
        variant="outlined"
        name="password"
        id="password"
        label="Password"
        type="password"
        autoComplete="current-password"
      />
      <Typography variant="body1" gutterBottom color="error">
        <b>{error}</b>
      </Typography>
      <Button
        type="submit"
        disabled={pristine || loading || !valid}
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        <Zoom in={loading}>
          <CircularProgress size={20} className={classes.submitIcon} />
        </Zoom>
        {loading ? "Requesting" : "Sign In"}
      </Button>
    </form>
  );
};

export default reduxForm({ form: "LoginForm", validate })(LoginForm);
