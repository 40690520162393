import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { capitalizeFirstLetter } from "../../helpers";
import FilterVintageIcon from "@material-ui/icons/FilterVintage";
import { ReactComponent as AgricultureIcon } from "../../assets/images/mdIcons/agriculture_black_24dp.svg";
import { ReactComponent as GrassIcon } from "../../assets/images/mdIcons/grass_black_24dp.svg";

const FieldMeta = ({ field: { farm, crop_type, field_type } }) => {
  const useStyles = makeStyles(({ palette, spacing }) => ({
    item: {
      color: palette.text.disabled,
      display: "flex",
      alignItems: "center",
    },
    icon: {
      fill: palette.text.disabled,
      marginRight: spacing(0.5),
    },
  }));
  const { item, icon } = useStyles();

  const content = [
    { label: field_type, Icon: GrassIcon },
    { label: farm, Icon: AgricultureIcon },
    { label: crop_type, Icon: FilterVintageIcon },
  ];

  return (
    <Grid container alignItems="center" justify="space-between" spacing={2}>
      {content.map(({ label, Icon }, i) => (
        <Grid item className={item} key={`field-meta-${i}`}>
          <Icon className={icon} />
          {capitalizeFirstLetter(label)}
        </Grid>
      ))}
    </Grid>
  );
};

export default FieldMeta;
