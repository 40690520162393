import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Typography } from "@material-ui/core";
import { TextInput, SelectInput } from "../FormInputs";

const validate = (values) => {
  const errors = {};
  const requiredInput = ["logger_id", "timezone", "manufacturer", "alignment"];

  requiredInput.forEach((input) => {
    if (!values[input]) {
      errors[input] = "Required";
    }
  });

  return errors;
};

const LoggerForm = ({
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
  initialValues,
  timezones,
  manufacturers,
}) => {
  useEffect(() => {
    isValid(!pristine && valid && !submitting);
    isWaiting(submitting);
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field
        component={TextInput}
        name="logger_id"
        id="logger_id"
        autoFocus={!initialValues}
        label="Logger ID"
      />
      <Field
        component={SelectInput}
        name="manufacturer"
        id="manufacturer"
        label="Manufacturer"
        options={manufacturers}
      />
      <Field
        component={SelectInput}
        name="timezone"
        id="timezone"
        label="Timezone"
        options={timezones}
      />
      <Field
        component={TextInput}
        name="public_key"
        id="public_key"
        autoFocus={!initialValues}
        label="Public Key"
      />
      <Field
        component={TextInput}
        name="private_key"
        id="private_key"
        autoFocus={!initialValues}
        label="Private Key"
      />

      <Field
        component={SelectInput}
        name="alignment"
        id="alignment"
        label="Installation Alignment"
        options={{ horizontal: "Horizontal", vertical: "Vertical" }}
      />
      <Typography variant="body1" gutterBottom color="error">
        <b>{error}</b>
      </Typography>
    </form>
  );
};

export default reduxForm({ form: "LoggerForm", validate })(LoggerForm);
