import api from "./agritech";

async function getField(token, clientId, fieldId) {
  const response = await api.get(`${clientId}/fields/${fieldId}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

function formatValues(values) {
  return {
    ...values,
    sensors:
      values.sensors && values.sensors.length
        ? values.sensors.map((sensor) => {
            const { multiplier, ...rest } = sensor;
            const config = multiplier ? JSON.stringify({ multiplier }) : null;
            return { ...rest, config };
          })
        : [],
  };
}

async function saveField(token, clientId, values) {
  const response = await api.post(`${clientId}/fields`, formatValues(values), {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function updateField(token, clientId, fieldId, values) {
  const response = await api.patch(
    `${clientId}/fields/${fieldId}`,
    formatValues(values),
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function deleteField(token, clientId, fieldId) {
  const response = await api.delete(`${clientId}/fields/${fieldId}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function listDeletedFields(token, clientId) {
  const response = await api.get(`${clientId}/deleted-fields`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function restoreField(token, clientId, fieldId) {
  const response = await api.post(
    `${clientId}/fields/restore/${fieldId}`,
    {},
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export {
  getField,
  saveField,
  updateField,
  deleteField,
  listDeletedFields,
  restoreField,
};
