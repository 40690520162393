import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import { useAnalyticsDispatch } from "../../context/analytics";
import Modal from "./Modal";
import FieldFilters from "../header/FieldFilters";
import { useFieldsFiltersState } from "../../context/field-filters";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  modalHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  modalTitle: {
    color: "#fff",
    marginBottom: theme.spacing(1),
  },
  modalContent: {
    padding: 0,
  },
}));

function FieldNavLink({ children, current, fields }) {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const { farm, crop_type } = useFieldsFiltersState();
  const dispatch = useAnalyticsDispatch();
  const theme = useTheme();
  const classes = useStyles();

  const filteredFields = useMemo(
    () =>
      fields?.length
        ? fields
            .filter(
              (item) =>
                item.id !== current &&
                (farm === "" || farm === item.farm) &&
                (crop_type === "" || crop_type === item.crop_type)
            )
            .sort((a, b) => {
              const farm = !a.farm
                ? -1
                : !b.farm
                ? 1
                : a.farm.localeCompare(b.farm);
              const crop_type = !a.crop_type
                ? -1
                : !b.crop_type
                ? 1
                : a.crop_type.localeCompare(b.crop_type);
              return farm || crop_type || a.name.localeCompare(b.name);
            })
        : [],
    [fields, current, farm, crop_type]
  );

  const switchField = (id) => {
    dispatch({ type: "setActiveField", payload: null });
    history.push(`/field/${id}`);
  };

  return (
    <>
      <span className={classes.root} onClick={() => setModalOpen(!modalOpen)}>
        {children}
        <KeyboardArrowDownIcon fontSize="small" color="disabled" />
      </span>
      <Modal open={modalOpen} closeable={true}>
        <Card>
          <CardContent className={classes.modalHeader}>
            <Typography variant="h3" className={classes.modalTitle}>
              Switch to another field:
            </Typography>
            <FieldFilters
              fields={fields}
              color={theme.palette.secondary.main}
            />
          </CardContent>
          <CardContent className={classes.modalContent}>
            {filteredFields.length ? (
              <List style={{ maxHeight: "60vh", overflow: "auto" }}>
                {filteredFields.map(({ id, name, crop_type, farm }) => (
                  <ListItem
                    key={`field_link-${id}`}
                    onClick={() => switchField(id)}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItemText
                      secondary={`${farm ? farm : "n/a"} | ${
                        crop_type ? crop_type : "n/a"
                      }`}
                    >
                      {name} {farm} {crop_type}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2">No fields found.</Typography>
            )}
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}

export default FieldNavLink;
