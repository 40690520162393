import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ApiTest from "./ApiTest";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  subTitle: {
    display: "block",
    color: theme.palette.text.disabled,
  },
}));

function useageNote(manufacturer) {
  let message = `Important: The "API Node Reference" should be captured from `;
  switch (manufacturer) {
    case "davis":
      message = `${message} the node label within "davis_current_observation". (The name will likely follow a format similar to: temp_extra_1.)`;
      break;
    case "metos":
      message = `${message} the "name" field the top-level data nodes.`;
      break;
    case "30mhz":
      message = `${message} the node label within "lastRecordedStats". (The name will likely follow a format similar to: PTH_pmc_MLX90614_SHT35.SHT.humidity.)`;
      break;
    case "talgil":
      message = `${message} the "name" field for the relevant sensor, located within "sensors".`;
      break;
    case "evvos":
      message = `${message} the "name" field for the relevant sensor, located within "items".`;
      break;
    case "sms":
      message = `${message} the first node of data. (This relates to the first row on the CSV file.)`;
      break;

    default:
      message = null;
      break;
  }
  return message;
}

const LoggersList = ({ loggers }) => {
  const classes = useStyles();

  return loggers.length ? (
    <div className={classes.root}>
      {loggers.map((logger) => (
        <Accordion key={`logger_${logger.id}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`logger_${logger.id}_header`}
          >
            <Typography variant="h4">
              {logger.logger_id}
              <Typography
                component="span"
                variant="body2"
                className={classes.subTitle}
              >
                {logger.manufacturer}
              </Typography>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ApiTest
              logger={logger}
              successMessage={useageNote(logger.manufacturer)}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  ) : (
    <p>No associated loggers found</p>
  );
};

export default LoggersList;
