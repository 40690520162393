import React from "react";
import { Chip, Typography, makeStyles, useTheme } from "@material-ui/core";
import AdminTable from "./AdminTable";
import { deleteClient } from "../../../api/clients";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const PermissionChips = ({ permissions, module_access }) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      {permissions.map(({ id, name }) => {
        const module_ids = module_access.map(({ module_id }) => module_id);
        const hasAccess = module_ids.includes(id) ? "primary" : "default";
        return (
          <Chip
            key={`module-${id}`}
            label={name}
            color={hasAccess}
            size="small"
          />
        );
      })}
    </div>
  );
};

const ClientsTable = ({
  data,
  user,
  onRefresh,
  permissions,
  activeClient,
  onRowClick,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const config = {
    columns: [
      {
        title: "Name",
        field: "name",
        render: ({ id, name }) => <strong>{name}</strong>,
      },
      {
        title: "Permissions",
        field: "module_access",
        render: ({ module_access }) => (
          <PermissionChips
            permissions={permissions}
            module_access={module_access}
          />
        ),
      },
      {
        title: "# Users",
        field: "users",
        render: ({ users }) => (users ? users.length : 0),
      },
      {
        title: "# Fields",
        field: "fields",
        render: ({ fields }) => (fields ? fields.length : 0),
      },
      {
        title: "Irrimax Directory",
        field: "directory",
      },
    ],
    data,
    onRowClick: onRowClick,
    options: {
      pageSizeOptions: [5, 10, 20, 50],
      rowStyle: (row) => {
        return row && activeClient && row.id === activeClient.id
          ? { backgroundColor: theme.palette.secondary.main }
          : null;
      },
    },
  };

  const onCreate = () => history.push("/admin/client");
  const onEdit = (id) => history.push(`/admin/client/${id}`);
  const onDelete = (record) => deleteClient(user.token, record.id);

  return (
    <AdminTable
      title={<Typography variant="h2">Clients</Typography>}
      type="clients"
      config={config}
      onRefresh={onRefresh}
      onEdit={onEdit}
      onCreate={onCreate}
      onDelete={onDelete}
    />
  );
};

export default ClientsTable;
