import React, { useMemo } from "react";
import Card from "../..";
import { useSoilClimateState } from "../../../../../../../context/soil-climate";
import DefaultChart from "../../../Chart/components/DefaultChart";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";

const SoilMoistureGains = ({ title }) => {
  const { status, data: rawData } = useSoilClimateState();
  const loading = useLoggerLoadingStatus(status);
  const values = ["gains"];
  const defaultTable = useSensorDataTable(rawData, title, values);
  const { data: gainsData, hasData } = useChartData(
    Object.values(rawData),
    values
  );
  const data = useMemo(
    () =>
      gainsData[0]?.reduce(
        ([pos, neg], record) => {
          const empty = { x: record.x, y: null };
          return record.y > 0
            ? [
                [...pos, record],
                [...neg, empty],
              ]
            : [
                [...pos, empty],
                [...neg, record],
              ];
        },
        [[], []]
      ),
    [gainsData]
  );

  const { gains_pos, gains_neg } = useChartMetrics(["gains_pos", "gains_neg"]);
  const datasets = {
    gains_pos: {
      ...gains_pos,
      type: "bar",
      axis: "mls",
    },
    gains_neg: {
      ...gains_neg,
      type: "bar",
      axis: "mls",
    },
  };
  const axises = useAxisesMinMax(
    {
      mls: {
        id: 0,
        orientation: "left",
        label: "ml",
      },
    },
    datasets,
    data
  );

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default SoilMoistureGains;
