import React, { createContext, useReducer, useContext } from "react";

const SidebarStateContext = createContext();
const SidebarDispatchContext = createContext();

function sidebarReducer(state, action) {
  switch (action.type) {
    case "setDisplay": {
      return { ...state, display: action.payload };
    }
    case "setOpen": {
      return { ...state, open: action.payload };
    }
    case "setSettingsOpen": {
      return { ...state, settingsOpen: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SidebarProvider({ children }) {
  const [state, dispatch] = useReducer(sidebarReducer, {
    display: true,
    open: false,
    settingsOpen: false,
  });
  return (
    <SidebarStateContext.Provider value={state}>
      <SidebarDispatchContext.Provider value={dispatch}>
        {children}
      </SidebarDispatchContext.Provider>
    </SidebarStateContext.Provider>
  );
}

function useSidebarState() {
  const context = useContext(SidebarStateContext);
  if (context === undefined) {
    throw new Error("useSidebarState must be used within a SidebarProvider");
  }
  return context;
}

function useSidebarDispatch() {
  const context = useContext(SidebarDispatchContext);
  if (context === undefined) {
    throw new Error("useSidebarDispatch must be used within a SidebarProvider");
  }
  return context;
}

export { SidebarProvider, useSidebarState, useSidebarDispatch };
