import { Tooltip, Typography, useTheme } from "@material-ui/core";
import React, { useMemo } from "react";
import Image from "../elements/Image";
import Statistic from "../elements/Statistic";
import Table from "../tables/Table";

const FieldsTable = ({ fields, title, onClick, fieldType }) => {
  const theme = useTheme();
  const columns = useMemo(() => {
    const userTableColumns =
      fields && fields.length && fields[0].data && fields[0].data.length
        ? Object.keys(fields[0].data).map((metric) => {
            return {
              title: fields[0].data[metric].short_name,
              field: fields[0].data[metric].ref,
              render: ({ data, parameters }) => {
                const { ref, value, suffix, thresholds, timestamp } = data[
                  metric
                ];

                if (timestamp) {
                  return (
                    <Tooltip
                      title={timestamp.format("DD/MM/YYYY HH:mm")}
                      placement="top"
                    >
                      <span>
                        <Statistic
                          {...{
                            type: ref,
                            value,
                            suffix,
                            thresholds,
                            parameters,
                          }}
                        />
                      </span>
                    </Tooltip>
                  );
                }

                return (
                  <Statistic
                    {...{ type: ref, value, suffix, thresholds, parameters }}
                  />
                );
              },
            };
          })
        : [];

    const soilColumnOrder = [
      "moisture_content",
      "smd",
      "gains",
      "irri_trigger",
      "predicted_irri",
      "rainfall",
      "ambient_temp",
      "humidity",
    ];
    const orderedUserTableColumns =
      fieldType === "soil"
        ? soilColumnOrder.reduce((arr, ref) => {
            const col = userTableColumns.find(({ field }) => field === ref);
            return col ? [...arr, col] : arr;
          }, [])
        : userTableColumns;

    const defaultColumns = [
      {
        title: "Name",
        field: "name",
        cellStyle: {
          textTransform: "uppercase",
          fontWeight: "bold",
          color: theme.palette.tertiary.main,
        },
        render: ({ name }) => name,
      },
    ];

    const irrimaxColumns = [
      {
        title: "Irrimax",
        field: "irrimax",
        render: (row) =>
          row.irrimax && (
            <Image src={row.irrimax[0].gauge} alt={row.irrimax[0].name} />
          ),
      },
    ];

    return fields && fields.length
      ? [...defaultColumns, ...irrimaxColumns, ...orderedUserTableColumns]
      : defaultColumns;
  }, [fieldType, fields, theme.palette.tertiary.main]);

  return (
    <Table
      columns={columns}
      data={fields}
      options={{
        pageSize: 5,
        pageSizeOptions: [5, 10, 20, 50],
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "800px",
        cellStyle: { fontWeight: "bold" },
      }}
      title={
        <Typography variant="h2" color="primary">
          {title}
        </Typography>
      }
      onRowClick={(event, { id }) => onClick(id)}
    />
  );
};

export default FieldsTable;
