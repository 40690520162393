import { useLayoutEffect, useMemo } from "react";
import {
  useAmbientClimateDispatch,
  fetchAmbientClimates,
} from "../../../../../context/ambient-climate";
import {
  useAgronomicsDispatch,
  fetchAgronomics,
} from "../../../../../context/agronomics";
import {
  fetchMildewRisk,
  useMildewDispatch,
} from "../../../../../context/mildew";
import {
  fetchSoilClimates,
  useSoilClimateDispatch,
} from "../../../../../context/soil-climate";
import {
  fetchSqueezes,
  useSqueezeDispatch,
} from "../../../../../context/squeeze";
import {
  useRecommendationDispatch,
  fetchRecommendations,
} from "../../../../../context/recommendation";
import {
  useParameterDispatch,
  fetchParameterTypes,
  fetchParameters,
} from "../../../../../context/parameters";
import { useAuthState, usePermissions } from "../../../../../context/auth";
import { useAnalyticsState } from "../../../../../context/analytics";

function filtereRequests(filters, requests) {
  return Object.keys(requests)
    .filter((key) => filters.includes(Number(key)))
    .reduce((arr, key) => [...arr, ...requests[key]], []);
}

const FieldData = ({ children }) => {
  const user = useAuthState();
  const { field, range } = useAnalyticsState();
  const ambientClimatesDispatch = useAmbientClimateDispatch();
  const agronomicsDispatch = useAgronomicsDispatch();
  const mildewDispatch = useMildewDispatch();
  const soilClimatesDispatch = useSoilClimateDispatch();
  const squeezeDispatch = useSqueezeDispatch();
  const recommendationDispatch = useRecommendationDispatch();
  const parmetersDispatch = useParameterDispatch();
  const permissions = usePermissions();
  const dateRange = {
    start_date: range.start_date.format("YYYY-MM-DD HH:mm:ss"),
    end_date: range.end_date.format("YYYY-MM-DD HH:mm:ss"),
  };
  const settings = [
    user.client.id,
    field.id,
    dateRange,
    user.token,
    field.timezone,
  ];

  const prerequisiteRequests = useMemo(
    () =>
      filtereRequests(permissions, {
        4: [
          () => fetchParameterTypes(parmetersDispatch, user.token),
          () => fetchParameters(parmetersDispatch, ...settings),
        ],
      }),
    [parmetersDispatch, permissions, settings, user.token]
  );
  const dataRequests = useMemo(
    () =>
      filtereRequests(permissions, {
        1: [() => fetchMildewRisk(mildewDispatch, ...settings)],
        2: [() => fetchAgronomics(agronomicsDispatch, ...settings)],
        3: [() => fetchAmbientClimates(ambientClimatesDispatch, ...settings)],
        4: [
          () => fetchSoilClimates(soilClimatesDispatch, ...settings),
          () => fetchSqueezes(squeezeDispatch, ...settings),
          () => fetchRecommendations(recommendationDispatch, ...settings),
        ],
      }),
    [
      agronomicsDispatch,
      ambientClimatesDispatch,
      mildewDispatch,
      permissions,
      recommendationDispatch,
      settings,
      soilClimatesDispatch,
      squeezeDispatch,
    ]
  );

  useLayoutEffect(() => {
    async function fetchData() {
      try {
        await Promise.all(prerequisiteRequests.map((fn) => fn()));
        await Promise.all(dataRequests.map((fn) => fn()));
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [dataRequests, prerequisiteRequests]);

  return children;
};

export default FieldData;
