import api from "./agritech";

async function ListSoilClimates(token, clientId, fieldId, range) {
  const response = await api.post(
    `${clientId}/climate/soil/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

export { ListSoilClimates };
