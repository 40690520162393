import api from "./agritech";

async function ListMildew(token, clientId, fieldId, range) {
  const response = await api.post(`${clientId}/dp/mildew/${fieldId}`, range, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function ListSprays(token, clientId, fieldId, range) {
  const response = await api.post(
    `${clientId}/dp/sprays/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function SaveSpray(token, clientId, fieldId, values) {
  const response = await api.post(
    `${clientId}/dp/sprays/${fieldId}`,
    { ...values, module_name: "mildew" },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function UpdateSpray(token, clientId, fieldId, id, values) {
  const response = await api.patch(
    `${clientId}/dp/sprays/${fieldId}/${id}`,
    { ...values, spray_id: id, module_name: "mildew" },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function DeleteSpray(token, clientId, fieldId, id) {
  const response = await api.delete(`${clientId}/dp/sprays/${fieldId}/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export { ListMildew, ListSprays, SaveSpray, UpdateSpray, DeleteSpray };
