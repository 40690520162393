import React from "react";
import { makeStyles } from "@material-ui/core";
import moment from "moment";
import SpraysForm from "./SpraysForm";
import AgronomicsForm from "./AgronomicsForm";
import { normaliseAgronomicsKeys, SaveAgronomics } from "../../api/agronomics";
import { SaveSpray } from "../../api/disease-risk";
import {
  triggerToast,
  handleRequestError,
  formatDateRange,
} from "../../helpers";
import { useDispatch } from "react-redux";
import { useAnalyticsState } from "../../context/analytics";
import {
  useAgronomicsDispatch,
  fetchAgronomics,
} from "../../context/agronomics";
import { useMildewDispatch, fetchMildewRisk } from "../../context/mildew";
import { useSqueezeDispatch, fetchSqueezes } from "../../context/squeeze";
import { SaveSqueeze } from "../../api/squeeze";
import SqueezeForm from "./SqueezeForm";
import RecommendationForm from "./RecommendationForm";
import { SaveRecommendation } from "../../api/recommendation";
import {
  useRecommendationDispatch,
  fetchRecommendations,
} from "../../context/recommendation";
import {
  useParameterDispatch,
  fetchParameters,
} from "../../context/parameters";
import { SaveParameters } from "../../api/parameters";
import ParametersForm from "./ParametersForm";
import { useAuthState } from "../../context/auth";

export const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FormSelection = ({ type, handleWaiting, handleValid, closeModal }) => {
  const user = useAuthState();
  const { field, range } = useAnalyticsState();
  const dispatch = useDispatch();

  const mildewDispatch = useMildewDispatch();
  const handleSpraySubmit = async (formValues) => {
    const record = {
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const response = await SaveSpray(
        user.token,
        user.client.id,
        field.id,
        record
      );
      fetchMildewRisk(
        mildewDispatch,
        user.client.id,
        field.id,
        formatDateRange(range),
        user.token,
        field.timezone
      );
      triggerToast(response.message, { variant: "success" });
      closeModal();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Spray Activity: ");
    }
  };

  const agronomicsDispatch = useAgronomicsDispatch();
  const handleAgronomicsSubmit = async (formValues) => {
    const record = normaliseAgronomicsKeys({
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    });
    try {
      const response = await SaveAgronomics(
        user.token,
        user.client.id,
        field.id,
        record
      );
      fetchAgronomics(
        agronomicsDispatch,
        user.client.id,
        field.id,
        formatDateRange(range),
        user.token,
        field.timezone
      );
      dispatch(triggerToast(response.message, { variant: "success" }));
      closeModal();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Agronomics data: ");
    }
  };

  const squeezeDispatch = useSqueezeDispatch();
  const handleSqueezeSubmit = async (formValues) => {
    const record = {
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const response = await SaveSqueeze(
        user.token,
        user.client.id,
        field.id,
        record
      );
      fetchSqueezes(
        squeezeDispatch,
        user.client.id,
        field.id,
        formatDateRange(range),
        user.token,
        field.timezone
      );
      dispatch(triggerToast(response.message, { variant: "success" }));
      closeModal();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Observation record: ");
    }
  };

  const recommendationDispatch = useRecommendationDispatch();
  const handleRecommendationSubmit = async (formValues) => {
    const record = {
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const response = await SaveRecommendation(
        user.token,
        user.client.id,
        field.id,
        record
      );
      fetchRecommendations(
        recommendationDispatch,
        user.client.id,
        field.id,
        formatDateRange(range),
        user.token,
        field.timezone
      );
      dispatch(triggerToast(response.message, { variant: "success" }));
      closeModal();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Recommendation record: ");
    }
  };

  const parameterDispatch = useParameterDispatch();
  const handleParameterSubmit = async (formValues) => {
    const record = {
      ...formValues,
      timestamp: formValues.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    try {
      const response = await SaveParameters(
        user.token,
        user.client.id,
        field.id,
        record
      );
      fetchParameters(
        parameterDispatch,
        user.client.id,
        field.id,
        formatDateRange(range),
        user.token,
        field.timezone
      );
      dispatch(triggerToast(response.message, { variant: "success" }));
      closeModal();
      return response.data;
    } catch (e) {
      handleRequestError(e, "Failed to store Parameter record: ");
    }
  };

  switch (type) {
    case "spray":
      return (
        <SpraysForm
          title="Provide details of the spray activity:"
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleSpraySubmit}
          initialValues={{ timestamp: moment().tz(field.timezone) }}
        />
      );

    case "agronomics":
      return (
        <AgronomicsForm
          title="Provide the Agronomics data:"
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleAgronomicsSubmit}
          initialValues={{ timestamp: moment().tz(field.timezone) }}
        />
      );

    case "squeeze":
      return (
        <SqueezeForm
          title="Provide the Observation details:"
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleSqueezeSubmit}
          initialValues={{ timestamp: moment().tz(field.timezone) }}
        />
      );

    case "recommendations":
      return (
        <RecommendationForm
          title="Outline your recommendation:"
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleRecommendationSubmit}
          initialValues={{ timestamp: moment().tz(field.timezone) }}
        />
      );

    case "parameters":
      return (
        <ParametersForm
          title="Define the parameter settings:"
          formStyles={useStyles}
          isValid={(status) => handleValid(status)}
          isWaiting={(status) => handleWaiting(status)}
          onSubmit={handleParameterSubmit}
          initialValues={{
            group: "moisture",
            timestamp: moment().tz(field.timezone),
          }}
        />
      );

    default:
      return "Something went wrong...";
  }
};

export default FormSelection;
