import { useTheme } from "@material-ui/core";
import React from "react";
import { VictoryArea } from "victory";
import Card from "../..";
import { useAmbientClimateState } from "../../../../../../../context/ambient-climate";
import { useAnalyticsState } from "../../../../../../../context/analytics";
import DefaultChart from "../../../Chart/components/DefaultChart";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";
import { useChartAdvisoryBg } from "./hooks";

const Vpd = ({ title }) => {
  const {
    range: { start_date, end_date },
  } = useAnalyticsState();
  const { status, data: rawData } = useAmbientClimateState();
  const loading = useLoggerLoadingStatus(status);
  const values = ["vpd"];
  const defaultTable = useSensorDataTable(rawData, title, values);
  const { data, hasData } = useChartData(Object.values(rawData), values);
  const { vpd } = useChartMetrics(values);
  const datasets = {
    vpd: {
      ...vpd,
      type: "line",
      axis: "vpd",
    },
  };

  const axises = useAxisesMinMax(
    {
      vpd: {
        id: 0,
        orientation: "left",
        label: "VPD",
      },
    },
    datasets,
    data
  );
  const maxima = axises.vpd?.maxima;

  const {
    palette: { danger, primary, info },
  } = useTheme();

  const boundaries = useChartAdvisoryBg(
    [
      {
        id: "excess",
        color: danger.main,
        label: "Excess",
        y: [maxima[0], "optimum"],
      },
      {
        id: "optimum",
        color: primary.main,
        label: "Optimum",
        y: [15, "low"],
      },
      {
        id: "low",
        color: info.main,
        label: "Low",
        y: [5, 0],
      },
    ],
    start_date,
    end_date
  );

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises}>
            {boundaries?.length
              ? boundaries.map(({ data, color, label }) => (
                  <VictoryArea
                    key={`vpd-boundary-${label}`}
                    data={data}
                    y={({ y }) => y / maxima[0]}
                    y0={({ y0 }) => y0 / maxima[0]}
                    style={{
                      data: {
                        stroke: color,
                        strokeDasharray: [10, 10],
                        fill: color?.replace(")", ", 0.4)"),
                      },
                    }}
                  />
                ))
              : null}
          </DefaultChart>
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default Vpd;
