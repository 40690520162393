import React from "react";
import { makeStyles, Link, Typography } from "@material-ui/core";
import AgriTech from "../../constants/app";

export default () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href={AgriTech.site.url} target="_blank">
          {AgriTech.company.name}
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
};
