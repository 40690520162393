import api from "./agritech";

async function getUser(token, id) {
  const response = await api.get(`/users/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function saveUser(token, values) {
  const response = await api.post(
    `/register`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function updateUser(token, id, values) {
  const response = await api.patch(
    `/users/${id}`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function deleteUser(token, id) {
  const response = await api.delete(`/users/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export { getUser, saveUser, updateUser, deleteUser };
