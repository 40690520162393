export default {
  options: {
    ves: "Very Easy Squeeze",
    es: "Easy Squeeze",
    ems: "Easy-Medium Squeeze",
    ms: "Medium Squeeze",
    mhs: "Medium-Hard Squeeze",
    hs: "Hard Squeeze",
    ti: "Tuber Initiation",
    hk: "Hooking",
    "≤10": "≤10mm",
    "≤20": "≤20mm",
    "≤30": "≤30mm",
    "≤40": "≤40mm",
    "≤50": "≤50mm",
    "≤60": "≤60mm",
    "≤70": "≤70mm",
    "≤80": "≤80mm",
    bb: "Bud Break",
    fl: "Flowering",
    gfs: "Green Fruit stage",
    crp: "Cropping",
    blb: "Bulbing",
    sb: "Set Back",
  },
};
