import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useAnalyticsState } from "../../../../../context/analytics";
import CardWarning from "../../../../cards/CardWarning";
import FieldData from "../FieldData";
import Loader from "../../../../elements/Loader";
import Layout from "../../../../layout/Layout";
import AnalyticsSettings from "../../../../sidebar/AnalyticsSettings";
import Header from "../Header";
import AnalyticsFooter from "../Footer";
import Fab from "../../../../elements/Fab";
import Copyright from "../../../../elements/Copyright";
import CardList from "../Card/components/CardList";
import {
  useLayoutDispatch,
  useLayoutState,
} from "../../../../../context/layout";

const AnalyticsLayout = ({ fieldId, loading }) => {
  const [status, setStatus] = loading;
  const { field } = useAnalyticsState();
  const { footer: footerDisplay } = useLayoutState();
  const dispatch = useLayoutDispatch();
  const useStyles = makeStyles((theme) => ({
    root: {
      paddingBottom: 78,
    },
    cta: {
      display: "flex",
    },
  }));
  const { root, cta } = useStyles();
  const toggleFooter = () => dispatch({ type: "toggleFooter" });

  return (
    <Layout
      loading={status === "pending"}
      header={
        <div className={cta}>
          <IconButton onClick={() => toggleFooter()}>
            <ScheduleIcon />
          </IconButton>
          <Fab />
        </div>
      }
      sidebar={
        field && (
          <AnalyticsSettings
            key="analytics-settings"
            fieldId={field.id}
            loadingState={(state) => setStatus(state)}
          />
        )
      }
      footer={footerDisplay ? <AnalyticsFooter /> : null}
    >
      <Loader
        active={status === "pending"}
        showChildren={false}
        message="Loading the field..."
      >
        {field ? (
          <div className={root}>
            <Header />
            <FieldData>
              <CardList />
            </FieldData>
            <Copyright />
          </div>
        ) : (
          <CardWarning
            message={`Field ${fieldId} not found`}
            color="disabled"
          />
        )}
      </Loader>
    </Layout>
  );
};

export default AnalyticsLayout;
