import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  useClientsState,
  useClientsDispatch,
  ClientsProvider,
  fetchClients,
} from "../../../context/clients";
import { useAuthState } from "../../../context/auth";
import Layout from "../../layout/Layout";
import {
  makeStyles,
  Grid,
  Typography,
  Chip,
  Tabs,
  Tab,
} from "@material-ui/core";
import { listModules } from "../../../api/modules";
import { handleRequestError } from "../../../helpers";
import Loader from "../../elements/Loader";
import UsersTable from "../../tables/admin/UsersTable";
import FieldsTable from "../../tables/admin/FieldsTable";
import ClientsTable from "../../tables/admin/ClientsTable";
import LoggersTable from "../../tables/admin/LoggersTable";
import CardWarning from "../../cards/CardWarning";
import { listDeletedFields } from "../../../api/fields";
import { listLoggers } from "../../../api/loggers";

export const useStyles = makeStyles((theme) => ({
  spacer: {
    marginTop: theme.spacing(3),
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    fontWeight: "bold",
  },
  buttonDelete: {
    marginRight: theme.spacing(2),
    "&:hover": {
      color: theme.palette.error.main,
    },
  },
  subTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
}));

const Admin = () => {
  return (
    <ClientsProvider>
      <AdminContent />
    </ClientsProvider>
  );
};

const formatFields = ({ fields, client }) =>
  fields?.map((field) => {
    delete field.client_id;
    return {
      ...field,
      import_summary: field.import_summary && JSON.parse(field.import_summary),
      client: { id: client.id, name: client.name },
    };
  });

const AdminContent = () => {
  const classes = useStyles();
  const user = useAuthState();
  const [tab, setTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [deletedFields, setDeletedFields] = useState([]);
  const [activeClient, setActiveClient] = useState(null);
  const [activeField, setActiveField] = useState(null);
  const clients = useClientsState();
  const clientsDispatch = useClientsDispatch();
  const [loggers, setLoggers] = useState([]);
  const users = useMemo(() => {
    return activeClient
      ? activeClient.users.map((item) => ({
          ...item,
          client: activeClient.name,
        }))
      : clients.reduce(
          (arr, item) => [
            ...arr,
            ...item.users.map((user) => ({
              ...user,
              client: item.name,
            })),
          ],
          []
        );
  }, [activeClient, clients]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const fields = useMemo(() => {
    if (tab) {
      return activeClient && deletedFields?.length
        ? formatFields({
            fields: deletedFields,
            client: { name: activeClient.name, id: activeClient.id },
          })
        : [];
    }

    return activeClient
      ? formatFields({
          fields: activeClient.fields,
          client: { name: activeClient.name, id: activeClient.id },
        })
      : clients.reduce((arr, client) => {
          const fields = formatFields({
            fields: client.fields,
            client: { name: client.name, id: client.id },
          });
          return [...arr, ...fields];
        }, []);
  }, [activeClient, clients, deletedFields, tab]);

  const filteredloggers = useMemo(
    () => (activeField ? activeField.loggers : loggers),
    [activeField, loggers]
  );

  async function fetchPermissions(token) {
    setIsLoading(true);
    try {
      const { data: modules } = await listModules(token);
      setPermissions(modules);
      setIsLoading(false);
      return modules;
    } catch (e) {
      setIsLoading(false);
      handleRequestError(e, "Failed fetching modules: ");
    }
  }

  const loadLoggers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await listLoggers(user.token);
      setLoggers(response.data);
      setIsLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching logger: ");
      setIsLoading(false);
    }
  }, [user.token]);

  const loadDeletedFields = useCallback(async () => {
    if (activeClient.id) {
      try {
        const response = await listDeletedFields(user.token, activeClient.id);
        setDeletedFields(response.data);
        return response.data;
      } catch (e) {
        handleRequestError(e, "Failed fetching deleted fields: ");
      }
    }
  }, [activeClient, user.token]);

  async function handleRefresh() {
    setIsLoading(true);
    try {
      const clientData = await fetchClients(clientsDispatch, user.token);
      loadDeletedFields();
      setIsLoading(false);
      return clientData;
    } catch (e) {
      handleRequestError(e, "Failed fetching logger: ");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchPermissions(user.token);
  }, [user.token]);

  useEffect(() => {
    if (activeClient?.id) {
      loadDeletedFields(user.token, activeClient.id);
    }
  }, [activeClient, loadDeletedFields, user.token]);

  useEffect(() => {
    fetchClients(clientsDispatch, user.token);
    loadLoggers(user.token);
  }, [loadLoggers, clientsDispatch, user.token]);

  const defaultProps = {
    classes,
    onRefresh: handleRefresh,
    user,
  };

  return (
    <Layout loading={isLoading}>
      <Loader
        active={isLoading}
        showChildren={false}
        message="Loading client data..."
      >
        {(activeClient || activeField) && (
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid item>
              <Typography variant="h6" color="textSecondary">
                FILTERS:
              </Typography>
            </Grid>
            {activeClient && (
              <Grid item>
                <Chip
                  color="primary"
                  label={activeClient.name}
                  onDelete={() => setActiveClient(null)}
                />
              </Grid>
            )}
            {activeField && (
              <Grid item>
                <Chip
                  color="primary"
                  label={activeField.name}
                  onDelete={() => setActiveField(null)}
                />
              </Grid>
            )}
          </Grid>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.spacer}>
            <ClientsTable
              data={clients}
              permissions={permissions}
              activeClient={activeClient}
              onRowClick={(event, rowData) =>
                activeClient && activeClient.id === rowData.id
                  ? setActiveClient(null)
                  : setActiveClient(rowData)
              }
              {...defaultProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Tabs
              indicatorColor="primary"
              value={tab}
              onChange={handleTabChange}
            >
              <Tab label="Live" />
              <Tab label="Archived" />
            </Tabs>
            <div hidden={tab !== 0}>
              <FieldsTable
                data={fields}
                status={["create", "edit", "delete"]}
                activeField={activeField}
                onRowClick={(event, rowData) =>
                  activeField && activeField.id === rowData.id
                    ? setActiveField(null)
                    : setActiveField(rowData)
                }
                {...defaultProps}
              />
              <LoggersTable
                data={filteredloggers}
                user={user}
                classes={classes}
                onRefresh={loadLoggers}
              />
              <UsersTable data={users} {...defaultProps} />
            </div>
            <div hidden={tab !== 1}>
              {activeClient ? (
                <FieldsTable
                  data={fields}
                  status={["restore"]}
                  {...defaultProps}
                />
              ) : (
                <CardWarning message="Please select a client." />
              )}
            </div>
          </Grid>
        </Grid>
      </Loader>
    </Layout>
  );
};

export default Admin;
