import React, { createContext, useReducer, useContext } from "react";
import { handleRequestError, setLocalTimestamps } from "../helpers";
import { ListMildew, ListSprays } from "../api/disease-risk";

const MildewStateContext = createContext();
const MildewDispatchContext = createContext();

function mildewReducer(state, action) {
  switch (action.type) {
    case "setMildew": {
      return { ...state, data: { ...action.payload } };
    }
    case "setSprayActivies": {
      return { ...state, sprays: [...action.payload] };
    }
    case "setStatus": {
      return { ...state, status: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MildewProvider({ children }) {
  const [state, dispatch] = useReducer(mildewReducer, {
    status: "idle",
    data: [],
    sprays: [],
  });
  return (
    <MildewStateContext.Provider value={state}>
      <MildewDispatchContext.Provider value={dispatch}>
        {children}
      </MildewDispatchContext.Provider>
    </MildewStateContext.Provider>
  );
}

function useMildewState() {
  const context = useContext(MildewStateContext);
  if (context === undefined) {
    throw new Error("useMildewState must be used within a MildewProvider");
  }
  return context;
}

function useMildewDispatch() {
  const context = useContext(MildewDispatchContext);
  if (context === undefined) {
    throw new Error("useMildewDispatch must be used within a MildewProvider");
  }
  return context;
}

function useSpraysById(id) {
  const { sprays } = useMildewState();
  if (!id) return null;
  const records = sprays.filter((item) => item.id === id);
  const record = records.length && {
    ...records[0],
    chemical_name: records[0].chemical_used,
  };
  delete record.chemical_used;
  return record;
}

async function fetchMildewRisk(
  dispatch,
  clientId,
  fieldId,
  range,
  token,
  timezone
) {
  dispatch({ type: "setStatus", payload: "pending" });
  try {
    const response = await ListMildew(token, clientId, fieldId, range);
    const data = response.data
      ? setLocalTimestamps(response.data, timezone).reverse()
      : [];
    dispatch({ type: "setMildew", payload: data });
    dispatch({ type: "setStatus", payload: "resolved" });
    fetchSprayActivities(dispatch, clientId, fieldId, range, token, timezone);
  } catch (e) {
    dispatch({ type: "setStatus", payload: "rejected" });
    handleRequestError(e, "Failed fetching mildew risk: ");
  }
}

async function fetchSprayActivities(
  dispatch,
  clientId,
  fieldId,
  range,
  token,
  timezone
) {
  try {
    const response = await ListSprays(token, clientId, fieldId, range);
    const sprays = setLocalTimestamps(response.data, timezone);
    dispatch({ type: "setSprayActivies", payload: sprays });
  } catch (e) {
    handleRequestError(e, "Failed fetching spray activities: ");
  }
}

export {
  MildewProvider,
  useMildewState,
  useMildewDispatch,
  useSpraysById,
  fetchMildewRisk,
  fetchSprayActivities,
};
