import React from "react";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  useClientsDispatch,
  useClientsState,
  fetchClients,
} from "../../context/clients";
import { useEffect } from "react";
import { useAuthDispatch } from "../../context/auth";
import { useAnalyticsDispatch } from "../../context/analytics";
import { useFieldsFiltersDispatch } from "../../context/field-filters";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const SelectClient = ({ user }) => {
  const history = useHistory();
  const classes = useStyles();
  const clients = useClientsState();
  const clientsDispatch = useClientsDispatch();
  const authDispatch = useAuthDispatch();
  const analyticsDispatch = useAnalyticsDispatch();
  const filtersDispatch = useFieldsFiltersDispatch();

  const handeleChange = (event) => {
    const client = clients.filter((client) => client.id === event.target.value);
    authDispatch({ type: "setUser", payload: { client: client[0] } });
    analyticsDispatch({ type: "setActiveField", payload: null });
    filtersDispatch({ type: "resetFilters" });
    history.push("/");
  };

  useEffect(() => {
    fetchClients(clientsDispatch, user.token);
  }, [clientsDispatch, user.token]);

  return clients.length ? (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Select client</InputLabel>
      <Select value={user.client.id} onChange={handeleChange}>
        {clients.map((client) => (
          <MenuItem key={"client-" + client.id} value={client.id}>
            {client.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : null;
};

export default SelectClient;
