import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Picker from "./Picker";

const DatePickerToFrom = ({
  timestampFrom,
  onChangeFrom,
  timestampTo,
  onChangeTo,
  color,
  disabled,
}) => {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Picker
        label="FROM:"
        timestamp={timestampFrom}
        onChange={onChangeFrom}
        color={color}
        disabled={disabled}
      />
      <Picker
        label="TO:"
        timestamp={timestampTo}
        onChange={onChangeTo}
        color={color}
        disabled={disabled}
        minDate={timestampFrom}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerToFrom;
