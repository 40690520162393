import React, { createContext, useReducer, useContext } from "react";

const SettingsStateContext = createContext();
const SettingsDispatchContext = createContext();

function settingsReducer(state, action) {
  switch (action.type) {
    case "fieldsVisibility": {
      return {
        ...state,
        fieldsSettings: { ...state.fieldsSettings, visibility: action.payload },
      };
    }
    case "fieldsFilterOptions": {
      return {
        ...state,
        fieldsSettings: {
          ...state.fieldsSettings,
          filters: action.payload,
        },
      };
    }
    case "chartsVisibility": {
      return {
        ...state,
        chartsSettings: { ...state.chartsSettings, visibility: action.payload },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const defaultFilters = () => ({ farm: [], crop_type: [] });

function SettingsProvider({ children }) {
  const [state, dispatch] = useReducer(settingsReducer, {
    fieldsSettings: {
      visibility: [],
      filters: defaultFilters(),
    },
    chartsSettings: {
      visibility: [],
    },
  });
  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
}

function useSettingsState() {
  const context = useContext(SettingsStateContext);
  if (context === undefined) {
    throw new Error("useSettingsState must be used within a SettingsProvider");
  }
  return context;
}

function useSettingsDispatch() {
  const context = useContext(SettingsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSettingsDispatch must be used within a SettingsProvider"
    );
  }
  return context;
}

const filterAndOrderSettings = (clientId, settings, type) =>
  Object.values(settings)
    .filter((item) => item.client_id === clientId && item.type === type)
    .sort((a, b) => (a.order > b.order ? 1 : -1));

function setVisibility(dispatch, clientId, settings) {
  dispatch({
    type: "fieldsVisibility",
    payload: filterAndOrderSettings(clientId, settings, "field"),
  });
  dispatch({
    type: "chartsVisibility",
    payload: filterAndOrderSettings(clientId, settings, "chart"),
  });
}

function createOptions(array) {
  return [...new Set(array)].sort((a, b) =>
    a.toUpperCase() < b.toUpperCase()
      ? -1
      : a.toUpperCase() > b.toUpperCase()
      ? 1
      : 0
  );
}

function setFilterOptions(dispatch, fields) {
  if (fields?.length) {
    const settings = fields.reduce(
      (obj, item) => {
        if (item.farm) obj.farm = [...obj.farm, item.farm];
        if (item.crop_type) obj.crop_type = [...obj.crop_type, item.crop_type];
        return obj;
      },
      { farm: [], crop_type: [] }
    );

    const farm = createOptions(settings.farm);
    const crop_type = createOptions(settings.crop_type);

    dispatch({ type: "fieldsFilterOptions", payload: { farm, crop_type } });
  }
}

export {
  SettingsProvider,
  useSettingsState,
  useSettingsDispatch,
  setVisibility,
  setFilterOptions,
};
