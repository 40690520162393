import React from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryLabel,
  VictoryLine,
} from "victory";
import Chart from "../..";

const DefaultChart = ({ data, datasets, axises, children, parent }) => {
  function IndexedY(datum, axis) {
    const value = Math.round((datum.y / axises[axis].max) * 10) / 10;
    return !isNaN(value) ? value : 0;
  }
  function yAxisIndexedMin(min, max) {
    const value = Math.round((min / max) * 100) / 100;
    return !isNaN(value) ? value : 0;
  }
  function tickFormat(t, max) {
    const value = Math.round(t * max * 10) / 10;
    return !isNaN(value) ? value : 0;
  }

  return (
    <Chart data={data} datasets={datasets} axises={axises} parent={parent}>
      {children}
      {Object.values(axises).map(
        ({ id, orientation, min, max, ...axisProps }) => {
          const tickValues = () => {
            const indexedMin = yAxisIndexedMin(min, max);
            const diff = 1 - indexedMin;
            const step = (v) => v * (diff / 3) + indexedMin;
            return [indexedMin, step(1), step(2), 1];
          };
          return (
            <VictoryAxis
              key={id}
              dependentAxis={true}
              orientation={orientation}
              tickValues={tickValues()}
              domain={{ y: [yAxisIndexedMin(min, max), 1] }}
              axisLabelComponent={
                <VictoryLabel
                  style={{
                    fill: "rgba(0,0,0,0.07)",
                    fontSize: 30,
                    fontWeight: "bold",
                    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif"`,
                    padding: 0,
                  }}
                />
              }
              tickFormat={(t) => tickFormat(t, max)}
              {...axisProps}
            />
          );
        }
      )}
      {Object.values(datasets).map(({ axis, color, type }, i) => {
        switch (type) {
          case "bar":
            return (
              <VictoryBar
                name={Object.keys(datasets)[i]}
                key={axis}
                style={{
                  data: { fill: color },
                  labels: { fill: color },
                }}
                // barWidth={2}
                // barRatio={0.2}
                data={data[i]}
                y={(datum) => IndexedY(datum, axis)}
              />
            );
          case "area":
            return (
              <VictoryArea
                name={Object.keys(datasets)[i]}
                key={axis}
                interpolation="basis"
                style={{
                  data: { stroke: color, fill: color?.replace(")", ", 0.4)") },
                  labels: { fill: color },
                }}
                data={data[i]}
                y={(datum) => IndexedY(datum, axis)}
              />
            );

          default:
            return (
              <VictoryLine
                name={Object.keys(datasets)[i]}
                key={axis}
                interpolation="basis"
                style={{
                  data: { stroke: color },
                  labels: { fill: color },
                }}
                data={data[i]}
                y={(datum) => IndexedY(datum, axis)}
              />
            );
        }
      })}
    </Chart>
  );
};

export default DefaultChart;
