import React from "react";
import { render } from "react-dom";
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { store } from "./redux/store";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from "./serviceWorker";
import theme from "./constants/theme";
import App from "./components/App";
import { AuthProvider } from "./context/auth";

// Sentry.init({
//   dsn:
//     "https://f3e7148e40c24f009b32d14a5e7083ce@o420683.ingest.sentry.io/5820298",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
