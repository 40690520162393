import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Typography } from "@material-ui/core";
import { TextInput, CheckboxInput, SelectInput } from "../FormInputs";

const validate = (values) => {
  const errors = {};
  const requiredInput = ["name", "email", "client_id"];

  if (!values.id) {
    requiredInput.push("password");
    requiredInput.push("password_confirmation");
  }

  requiredInput.forEach((input) => {
    if (!values[input]) {
      errors[input] = "Required";
    }
  });

  if (values.password && !values.password_confirmation) {
    errors.password_confirmation = "Please confirm the password";
  }
  if (!values.password && values.password_confirmation) {
    errors.password = "Please confirm the password";
  }

  if (values.password && values.password.length < 6) {
    errors.password = "Minimum of 6 characters";
  }
  if (values.password_confirmation && values.password_confirmation.length < 6) {
    errors.password_confirmation = "Minimum of 6 characters";
  }

  return errors;
};

const UserForm = ({
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
  initialValues,
  clients,
}) => {
  useEffect(() => {
    isValid(!pristine && valid && !submitting);
    isWaiting(submitting);
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field component={CheckboxInput} name="admin" id="admin" label="Admin" />
      <Field
        component={TextInput}
        name="name"
        id="name"
        autoFocus={!initialValues}
        label="Name"
      />
      <Field
        component={TextInput}
        name="email"
        id="email"
        type="email"
        label="Email"
      />
      <Field
        component={SelectInput}
        name="client_id"
        id="client_id"
        label="Client"
        disabled={initialValues ? true : false}
        options={clients}
      />
      <Field
        component={TextInput}
        name="password"
        id="password"
        type="password"
        label={initialValues ? `Update Password` : `Password`}
      />
      <Field
        component={TextInput}
        name="password_confirmation"
        id="password_confirmation"
        type="password"
        label={
          initialValues ? `Confirm Updated Password` : `Password Confirmation`
        }
      />

      <Typography variant="body1" gutterBottom color="error">
        <b>{error}</b>
      </Typography>
    </form>
  );
};

export default reduxForm({ form: "UserForm", validate })(UserForm);
