import api from "./agritech";

function normaliseAgronomicsKeys(formValues, addPrefix = false) {
  return Object.keys(formValues).reduce((obj, key) => {
    if (addPrefix) {
      const exclusions = ["id", "field_id", "module_id", "timestamp"];
      obj[exclusions.includes(key) ? key : `ag_${key}`] = formValues[key];
    } else {
      obj[key.includes("ag_") ? key.replace("ag_", "") : key] = formValues[key];
    }
    return obj;
  }, {});
}

async function ListAgronomics(token, clientId, fieldId, range) {
  const response = await api.post(
    `${clientId}/agronomics/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function SaveAgronomics(token, clientId, fieldId, values) {
  const response = await api.post(`${clientId}/agronomics/${fieldId}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function UpdateAgronomics(token, clientId, fieldId, id, values) {
  const response = await api.patch(
    `${clientId}/agronomics/${fieldId}/${id}`,
    values,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function DeleteAgronomics(token, clientId, fieldId, id) {
  const response = await api.delete(`${clientId}/agronomics/${fieldId}/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export {
  ListAgronomics,
  SaveAgronomics,
  UpdateAgronomics,
  DeleteAgronomics,
  normaliseAgronomicsKeys,
};
