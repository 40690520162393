import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "../..";
import {
  handleRequestError,
  triggerToast,
  formatDateRange,
} from "../../../../../../../helpers";
import UpdateAgronomicsRecord from "../../../../../../actions/UpdateAgronomicsRecord";
import {
  useAgronomicsDispatch,
  fetchAgronomics,
  useAgronomicsState,
} from "../../../../../../../context/agronomics";
import Modal from "../../../../../../elements/Modal";
import DeleteRecord from "../../../../../../actions/DeleteRecord";
import { DeleteAgronomics } from "../../../../../../../api/agronomics";
import { useAuthState } from "../../../../../../../context/auth";
import AnalyticsTable from "../../../../../../tables/AnalyticsTable";
import { useAnalyticsState } from "../../../../../../../context/analytics";
import { useFormatTableData, useLoggerLoadingStatus } from "../../hooks";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import DefaultChart from "../../../Chart/components/DefaultChart";

const AgronomicsOverview = ({ title }) => {
  const { field, range } = useAnalyticsState();
  const { status, data: rawData } = useAgronomicsState();
  const loading = useLoggerLoadingStatus(status);
  const [modalOpen, setModalOpen] = useState(false);
  const [record, setRecord] = useState(null);
  const [action, setAction] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const values = [
    "ag_ph_in",
    "ag_ph_out",
    "ag_ph_bag",
    "ag_ec_in",
    "ag_ec_out",
    "ag_ec_sum",
    "ag_ec_bag",
    "ag_run_off",
  ];
  const tableData = useFormatTableData(rawData, values);
  const { data, hasData } = useChartData(Object.values(rawData), [
    "ag_ph_in",
    "ag_ph_out",
    "ag_ec_in",
    "ag_ec_out",
    "ag_ec_sum",
    "ag_run_off",
  ]);
  const {
    ag_ph_in,
    ag_ph_out,
    ag_ec_in,
    ag_ec_out,
    ag_ec_sum,
    ag_run_off,
  } = useChartMetrics(values);
  const datasets = {
    ag_run_off: {
      ...ag_run_off,
      type: "area",
      axis: "runoff",
    },
    ag_ph_in: {
      ...ag_ph_in,
      type: "line",
      axis: "ph",
    },
    ag_ph_out: {
      ...ag_ph_out,
      type: "line",
      axis: "ph",
    },
    ag_ec_in: {
      ...ag_ec_in,
      type: "line",
      axis: "ph",
    },
    ag_ec_out: {
      ...ag_ec_out,
      type: "line",
      axis: "ph",
    },
    ag_ec_sum: {
      ...ag_ec_sum,
      type: "line",
      axis: "ph",
    },
  };
  const axises = useAxisesMinMax(
    {
      ph: {
        id: 0,
        label: "PH/EC",
        orientation: "left",
      },
      runoff: {
        label: "% Run-Off",
        id: 1,
        orientation: "right",
      },
    },
    datasets,
    data
  );

  // console.log(axises);

  const dispatch = useDispatch();
  const user = useAuthState();

  const agronomicsEdit = (event, row) => {
    openModal();
    setRecord(row.id);
    setAction("update");
  };

  const agronomicsDelete = (event, row) => {
    openModal();
    setRecord(row.id);
    setAction("delete");
  };

  const agronomicsDispatch = useAgronomicsDispatch();
  const handleAgronomicsDelete = async () => {
    setWaiting(true);
    try {
      const response = await DeleteAgronomics(
        user.token,
        user.client.id,
        field.id,
        record
      );
      fetchAgronomics(
        agronomicsDispatch,
        user.client.id,
        field.id,
        formatDateRange(range),
        user.token,
        field.timezone
      );
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      closeModal();
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, "Failed to delete Agronomics record: ");
    }
  };

  return (
    <>
      <Card
        chart={
          hasData ? (
            <DefaultChart data={data} datasets={datasets} axises={axises} />
          ) : null
        }
        loading={loading}
        title={title}
        isLogger={true}
        tables={[
          {
            title: "Recorded Data",
            display: false,
            table: (
              <AnalyticsTable
                config={{ ...tableData, title: "Agronomics Sensor Data" }}
                onEdit={{
                  tooltip: "Edit Agronomics Record",
                  handleClick: agronomicsEdit,
                }}
                onDelete={{
                  tooltip: "Delete Agronomics Record",
                  handleClick: agronomicsDelete,
                }}
              />
            ),
          },
        ]}
      />
      {record && (
        <Modal open={modalOpen}>
          {action === "update" && (
            <UpdateAgronomicsRecord
              field={field}
              user={user}
              range={range}
              id={record}
              closeModal={closeModal}
            />
          )}
          {action === "delete" && (
            <DeleteRecord
              title="Delete Agronomics Record"
              id={record}
              waiting={waiting}
              onDelete={handleAgronomicsDelete}
              closeModal={closeModal}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default AgronomicsOverview;
