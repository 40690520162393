import React from "react";
import {
  makeStyles,
  Menu,
  MenuItem,
  Fade,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Avatar,
  Divider,
  Hidden,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { firstLetters } from "../../helpers";
import SelectClient from "./SelectClient";
import { ClientsProvider } from "../../context/clients";
import { useAuthState, logout, useAuthDispatch } from "../../context/auth";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  user: {
    width: "100%",
    display: "flex",
    position: "relative",
    boxSizing: "border-box",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
    minHeight: 46,
    border: "none",
    borderLeftWidth: 1,
    borderLeftColor: theme.palette.divider,
    borderLeftStyle: "solid",
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    backgroundColor: "inherit",
    cursor: "pointer",
    "&:focus, &:active": {
      outline: "none",
    },
  },
  userText: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: theme.spacing(1),
  },
  userTextPrimary: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  userTextSecondary: {
    fontSize: "0.75rem",
  },
  listItemAvatar: {
    [theme.breakpoints.down("xs")]: {
      minWidth: "auto",
    },
  },
  userAvatar: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
      width: 35,
      height: 35,
      fontSize: "1rem",
    },
  },
}));

const UserMenu = () => {
  const history = useHistory();
  const classes = useStyles();
  const user = useAuthState();
  const dispatch = useAuthDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    history.push("/");
    logout(dispatch);
  };

  return (
    <>
      <button
        className={classes.user}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar alt={user.name} className={classes.userAvatar}>
            {firstLetters(user.name)}
          </Avatar>
        </ListItemAvatar>
        <Hidden xsDown>
          <ListItemText
            primary={user.name}
            secondary={user.client.name}
            className={classes.userText}
            classes={{
              primary: classes.userTextPrimary,
              secondary: classes.userTextSecondary,
            }}
          />
        </Hidden>
        <KeyboardArrowDownIcon
          fontSize="small"
          color={open ? "secondary" : "disabled"}
        />
      </button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {user.isAdmin && [
          <MenuItem key="userMenu-selectClient">
            <ClientsProvider>
              <SelectClient user={user} />
            </ClientsProvider>
          </MenuItem>,
          <Divider key="userMenu-selectClient-divider" />,
        ]}
        <MenuItem key="userMenu-logout" onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
