import React, { useState, useEffect, Fragment } from "react";
import { submit } from "redux-form";
import {
  makeStyles,
  withStyles,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  CardContent,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import ActionSelection from "../actions/ActionSelection";
import FormSelection from "../forms/FormSelection";
import StepperButtons from "./StepperButtons";
import formConfig from "../../constants/forms";

const CustomConnector = withStyles((theme) => ({
  line: {
    borderColor: theme.palette.divider,
    borderTopWidth: 2,
  },
  active: {
    "& $line": {
      borderColor: theme.palette.primary.main,
    },
  },
}))(StepConnector);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  header: {
    backgroundColor: theme.palette.secondary.main,
  },
  body: {
    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      maxHeight: "80vh",
    },
  },
}));

const ActionStepper = ({ action, close }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [activeForm, setActiveForm] = useState("");
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const steps = ["Choose an action", "Insert your data"];

  useEffect(() => {
    if (action) {
      setActiveStep(1);
      setActiveForm(action);
    }
  }, [action]);

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        className={classes.header}
        connector={<CustomConnector />}
      >
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <CardContent className={classes.body}>
        <div>
          {activeStep === 0 && (
            <Fragment>
              <ActionSelection
                config={formConfig}
                stepValid={(status) => setValid(status)}
                selection={setActiveForm}
              />
              <StepperButtons
                failButtonText="Close"
                handleFailButton={() => close()}
                successButtonText="Next"
                disableSuccessButton={!valid}
                handleSuccessButton={() =>
                  setActiveStep((prevActiveStep) => prevActiveStep + 1)
                }
                waiting={waiting}
                waitingText={activeStep && "Saving"}
              />
            </Fragment>
          )}
          {activeStep === 1 && (
            <Fragment>
              <FormSelection
                type={activeForm}
                handleWaiting={setWaiting}
                handleValid={setValid}
                closeModal={close}
              />
              <StepperButtons
                failButtonText="Back"
                handleFailButton={() =>
                  setActiveStep((prevActiveStep) => prevActiveStep - 1)
                }
                successButtonText="Save"
                disableSuccessButton={!valid || waiting}
                handleSuccessButton={() =>
                  dispatch(submit(formConfig[activeForm].submission))
                }
                waiting={waiting}
                waitingText="Saving"
              />
            </Fragment>
          )}
        </div>
      </CardContent>
    </div>
  );
};

export default ActionStepper;
