import api from "./agritech";

async function listMetrics(token) {
  const response = await api.get(`/metrics`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function searchMetrics(params, token) {
  const response = await api.post(
    `/metrics`,
    { ...params },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export { listMetrics, searchMetrics };
