import React, { createContext, useReducer, useContext } from "react";
import { ListSqueezes } from "../api/squeeze";
import { setLocalTimestamps, handleRequestError } from "../helpers";

const SqueezeStateContext = createContext();
const SqueezeDispatchContext = createContext();

function squeezeReducer(state, action) {
  switch (action.type) {
    case "setSqueezes": {
      return [...action.payload];
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SqueezeProvider({ children }) {
  const [state, dispatch] = useReducer(squeezeReducer, []);
  return (
    <SqueezeStateContext.Provider value={state}>
      <SqueezeDispatchContext.Provider value={dispatch}>
        {children}
      </SqueezeDispatchContext.Provider>
    </SqueezeStateContext.Provider>
  );
}

function useSqueezeState() {
  const context = useContext(SqueezeStateContext);
  if (context === undefined) {
    throw new Error("useSqueezeState must be used within a SqueezeProvider");
  }
  return context;
}

async function fetchSqueezes(
  dispatch,
  clientId,
  fieldId,
  range,
  token,
  timezone
) {
  try {
    const response = await ListSqueezes(token, clientId, fieldId, range);
    const data = setLocalTimestamps(response.data, timezone);
    dispatch({ type: "setSqueezes", payload: data });
    return data;
  } catch (e) {
    handleRequestError(e, "Failed fetching squeeze records: ");
  }
}

function useSqueezeById(id) {
  const data = useSqueezeState();
  const record = id && data.filter((item) => item.id === id);
  return record && record.length && record[0];
}

function useSqueezeDispatch() {
  const context = useContext(SqueezeDispatchContext);
  if (context === undefined) {
    throw new Error("useSqueezeDispatch must be used within a SqueezeProvider");
  }
  return context;
}

export {
  SqueezeProvider,
  useSqueezeState,
  useSqueezeDispatch,
  fetchSqueezes,
  useSqueezeById,
};
