import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Analytics from "./pages/Analytics";
import ReportsList from "./pages/ReportsList";
import Report from "./pages/Report";
import Admin from "./pages/admin/Admin";
import Manage from "./pages/admin/Manage";
import NotFound from "./pages/404";
import { SettingsProvider } from "../context/settings";
import { AnalyticsProvider } from "../context/analytics";
import { useAuthState } from "../context/auth";
import { FieldsFiltersProvider } from "../context/field-filters";
import { FieldsProvider } from "../context/fields";
import { SidebarProvider } from "../context/sidebar";
import { LayoutProvider } from "../context/layout";

const AuthenticatedApp = () => {
  return (
    <LayoutProvider>
      <SidebarProvider>
        <FieldsFiltersProvider>
          <SettingsProvider>
            <AnalyticsProvider>
              <FieldsProvider>
                <div className="App">
                  <Router>
                    <Switch>
                      <Route exact path="/" render={Dashboard} />
                      <Route exact path="/reports" render={ReportsList} />
                      <Route exact path="/report/:reportId" render={Report} />
                      <Route exact path="/field/:fieldId" render={Analytics} />
                      <Route exact path="/admin" render={Admin} />
                      <Route exact path="/admin/:model" render={Manage} />
                      <Route path="/admin/:model/:id" render={Manage} />
                      <Route path="*">
                        <NotFound message="Page not found." />
                      </Route>
                    </Switch>
                  </Router>
                </div>
              </FieldsProvider>
            </AnalyticsProvider>
          </SettingsProvider>
        </FieldsFiltersProvider>
      </SidebarProvider>
    </LayoutProvider>
  );
};

const App = () => {
  const user = useAuthState();
  return user.id ? <AuthenticatedApp /> : <Login />;
};

export default App;
