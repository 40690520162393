import React from "react";
import { makeStyles, Button, CircularProgress, Zoom } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  submitIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default ({
  text,
  variant,
  color,
  handleClick,
  disabled,
  waiting,
  waitingText,
  buttonStyles,
}) => {
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      variant={variant}
      color={color}
      onClick={handleClick}
      className={buttonStyles ? buttonStyles : classes.button}
    >
      {waiting && (
        <Zoom in={waiting}>
          <CircularProgress
            color={color}
            size={20}
            className={classes.submitIcon}
          />
        </Zoom>
      )}
      {waiting ? waitingText : text}
    </Button>
  );
};
