import React from "react";
import Card from "../..";
import { useSoilClimateState } from "../../../../../../../context/soil-climate";
import DefaultChart from "../../../Chart/components/DefaultChart";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";

const RunOff = ({ title }) => {
  const { status, data: rawData } = useSoilClimateState();
  const values = ["run_off_in", "run_off_out", "run_off"];
  const loading = useLoggerLoadingStatus(status);
  const defaultTable = useSensorDataTable(rawData, title, values);
  const { data, hasData } = useChartData(Object.values(rawData), values);
  const { run_off_in, run_off_out, run_off } = useChartMetrics(values);
  const datasets = {
    run_off: {
      ...run_off,
      type: "area",
      axis: "percentage",
    },
    run_off_in: {
      ...run_off_in,
      type: "bar",
      axis: "ml",
    },
    run_off_out: {
      ...run_off_out,
      type: "bar",
      axis: "ml",
    },
  };
  const axises = useAxisesMinMax(
    {
      ml: {
        id: 0,
        label: "ml",
        orientation: "left",
      },
      percentage: {
        label: "%",
        id: 1,
        orientation: "right",
      },
    },
    datasets,
    data
  );
  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default RunOff;
