import React from "react";
import {
  makeStyles,
  CircularProgress,
  Typography,
  fade,
} from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  root: { position: "relative", height: "inherit" },
  loader: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: fade(theme.palette.common.white, 0.7),
    top: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loaderText: { marginTop: theme.spacing(2) },
}));

export default (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {((props.active && props.showChildren) || !props.active) &&
        props.children}
      {props.active && (
        <div className={classes.loader}>
          <CircularProgress color="primary" size={60} />
          <Typography
            variant="h4"
            color="primary"
            className={classes.loaderText}
          >
            {props.message}
          </Typography>
        </div>
      )}
    </div>
  );
};
