import React, { useState } from "react";
import ActionTable from "../../tables/ActionTable";
import { makeStyles } from "@material-ui/core";
import DeleteRecord from "../../actions/DeleteRecord";
import { handleRequestError, triggerToast } from "../../../helpers";
import Modal from "../../elements/Modal";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(3),
  },
}));

const AdminTable = ({
  title,
  type,
  config,
  onCreate,
  onView,
  onEdit,
  onDelete,
  onRefresh,
  onRestore,
  deleteLabel = "name",
}) => {
  const classes = useStyles();
  const [record, setRecord] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const dispatch = useDispatch();

  const deleteRecord = (event, row) => {
    openModal();
    setRecord(row);
  };

  const handleDelete = async () => {
    setWaiting(true);
    try {
      const response = await onDelete(record);
      onRefresh();
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      closeModal();
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to delete ${type}: `);
    }
  };

  const handleRestore = async (event, row) => {
    setWaiting(true);
    setRecord(row);
    try {
      const response = await onRestore(row);
      onRefresh();
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      closeModal();
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to restore ${type}: `);
    }
  };

  const handleEdit = (event, row) => {
    onEdit(row.id);
  };

  const handleView = (event, row) => {
    onView(row.id);
  };

  return (
    <>
      <ActionTable
        config={{
          ...config,
          options: {
            ...config.options,
            pageSizeOptions: [5, 10, 20, 50],
            actionsColumnIndex: -1,
          },
          title,
        }}
        onCreate={
          onCreate && {
            tooltip: `Add ${type}`,
            handleClick: onCreate,
          }
        }
        onView={
          onView && {
            tooltip: `View ${type}`,
            handleClick: handleView,
          }
        }
        onEdit={
          onEdit && {
            tooltip: `Edit ${type}`,
            handleClick: handleEdit,
          }
        }
        onDelete={
          onDelete && {
            tooltip: `Delete ${type}`,
            handleClick: deleteRecord,
          }
        }
        onRestore={
          onRestore && {
            tooltip: `Restore ${type}`,
            handleClick: handleRestore,
          }
        }
        style={classes}
      />
      {record && (
        <Modal open={modalOpen}>
          <DeleteRecord
            title={`Delete ${type} "${record[deleteLabel]}"`}
            id={record.id}
            waiting={waiting}
            onDelete={handleDelete}
            closeModal={closeModal}
          />
        </Modal>
      )}
    </>
  );
};

export default AdminTable;
