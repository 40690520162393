import React, { createContext, useReducer, useContext } from "react";
import { listClients } from "../api/clients";
import { handleRequestError } from "../helpers";

const ClientsStateContext = createContext();
const ClientsDispatchContext = createContext();

function clientsReducer(state, action) {
  switch (action.type) {
    case "setClients": {
      return [...action.payload];
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ClientsProvider({ children }) {
  const [state, dispatch] = useReducer(clientsReducer, []);
  return (
    <ClientsStateContext.Provider value={state}>
      <ClientsDispatchContext.Provider value={dispatch}>
        {children}
      </ClientsDispatchContext.Provider>
    </ClientsStateContext.Provider>
  );
}

function useClientsState() {
  const context = useContext(ClientsStateContext);
  if (context === undefined) {
    throw new Error("useClientsState must be used within a ClientsProvider");
  }
  return context;
}

function useClientsDispatch() {
  const context = useContext(ClientsDispatchContext);
  if (context === undefined) {
    throw new Error("useClientsDispatch must be used within a ClientsProvider");
  }
  return context;
}

async function fetchClients(dispatch, token) {
  try {
    const response = await listClients(token);

    dispatch({ type: "setClients", payload: response.data });
  } catch (e) {
    handleRequestError(e, "Failed fetching clients: ");
  }
}

export { ClientsProvider, useClientsState, useClientsDispatch, fetchClients };
