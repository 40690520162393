import axios from "axios";

export const craft = axios.create({
  baseURL: "https://www.agri-tech.co.uk/",
});

export const local = axios.create({
  baseURL: "https://dashboard.agri-tech.co.uk/",
});

export default axios.create({
  baseURL: "https://api.agri-tech.co.uk/v2/",
  // baseURL: "https://api.staging.agri-tech.co.uk/v2/",
  // baseURL: "http://localhost:8080/v2/",
  headers: { "Content-Type": "application/json" },
});
