import React from "react";
import Card from "../..";
import { useSoilClimateState } from "../../../../../../../context/soil-climate";
import DefaultChart from "../../../Chart/components/DefaultChart";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";

const Ec = ({ title }) => {
  const { status, data: rawData } = useSoilClimateState();
  const loading = useLoggerLoadingStatus(status);
  const values = ["ec_in", "ec_out", "ec_bag"];
  const defaultTable = useSensorDataTable(rawData, title, values);
  const { data, hasData } = useChartData(Object.values(rawData), values);
  const { ec_in, ec_out } = useChartMetrics(["ec_in", "ec_out"]);
  const datasets = {
    ec_in: {
      ...ec_in,
      type: "line",
      axis: "ec",
    },
    ec_out: {
      ...ec_out,
      type: "line",
      axis: "ec",
    },
  };
  const axises = useAxisesMinMax(
    {
      ec: {
        id: 0,
        orientation: "left",
        label: "EC",
      },
    },
    datasets,
    data
  );

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default Ec;
