import React, { Fragment, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Typography } from "@material-ui/core";
import { DateTimeInput, TextInput, SelectInput } from "./FormInputs";
import { useParameterState } from "../../context/parameters";

const validate = (values) => {
  const errors = {};
  const requiredFields = ["timestamp", "description", "type", "value"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });

  return errors;
};

const UpdateParametersForm = ({
  title,
  timezone,
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
}) => {
  const { types } = useParameterState();
  const options = types.reduce(
    (obj, item) => ({ ...obj, [item.id]: item.name }),
    {}
  );

  useEffect(() => {
    isValid(!pristine && valid && !submitting);
    isWaiting(submitting);
  });

  return (
    <Fragment>
      {title && (
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
      )}
      <form noValidate onSubmit={handleSubmit}>
        <Field
          component={DateTimeInput}
          name="timestamp"
          id="parameter-form-timestamp"
          label="Timestamp"
          timezone={timezone}
        />
        <Field
          component={TextInput}
          name="description"
          id="parameter-description"
          label="Description"
        />
        <Field
          name="type"
          id="parameter-form-type"
          type="text"
          component={SelectInput}
          label="Type"
          options={options}
        />
        <Field
          name="value"
          id="parameter-form-value"
          component={TextInput}
          type="number"
          label="Value"
        />
        <Typography variant="body1" gutterBottom color="error">
          <b>{error}</b>
        </Typography>
      </form>
    </Fragment>
  );
};

export default reduxForm({
  form: "UpdateParameters", // a unique identifier for this form
  validate,
})(UpdateParametersForm);
