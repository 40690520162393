import React from "react";
import { CssBaseline, Paper, Grid, makeStyles, Box } from "@material-ui/core";
import FormLogin from "../forms/FormLogin";
import Copyright from "../elements/Copyright";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import loginImg1 from "../../assets/images/login-01.jpg";
import loginImg2 from "../../assets/images/login-02.jpg";
import loginImg3 from "../../assets/images/login-03.jpg";
import loginImg4 from "../../assets/images/login-04.jpg";
import loginImg5 from "../../assets/images/login-05.jpg";

const images = [loginImg1, loginImg2, loginImg3, loginImg4, loginImg5];
const randomImage = Math.floor(Math.random() * 5);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(" + images[randomImage] + ")",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: theme.spacing(1),
    width: "120px",
  },
  welcome: {
    marginTop: theme.spacing(4),
  },
}));

const Login = () => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Logo className={classes.logo} />
          <FormLogin />
          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;
