import api from "./agritech";

async function listLoggers(token) {
  const response = await api.get(`/loggers`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function getLogger(token, id) {
  const response = await api.get(`/loggers/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function saveLogger(token, values) {
  const response = await api.post(
    `/loggers`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function updateLogger(token, id, values) {
  const response = await api.patch(
    `/loggers/${id}`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function deleteLogger(token, id) {
  const response = await api.delete(`/loggers/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function connectionTest(token, id) {
  const response = await api.get(`/loggers/api-test/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export {
  listLoggers,
  getLogger,
  saveLogger,
  updateLogger,
  deleteLogger,
  connectionTest,
};
