import React from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MomentUtils from "@date-io/moment";

export const TextInput = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      fullWidth
      margin="normal"
      {...input}
      {...custom}
    />
  );
};

export const CheckboxInput = ({ input, label, ...custom }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={input.value ? true : false}
        onChange={input.onChange}
        name={input.name}
        color="primary"
        {...input}
        {...custom}
      />
    }
    label={label}
  />
);

export const SelectInput = ({
  label,
  input,
  id,
  options,
  disabled,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <FormControl fullWidth margin="normal" disabled={disabled}>
    <InputLabel id={`select-${id}-label`}>{label}</InputLabel>
    <Select
      labelId={`select-${id}-label`}
      id={`select-${id}`}
      value={input.value}
      onChange={(value) => input.onChange(value)}
    >
      {Object.keys(options)
        .map((item) => ({
          value: item,
          label: options[item],
        }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
        .map(({ label, value }) => (
          <MenuItem key={`select-${id}-option-${value}`} value={value}>
            {label}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
);

export const DateTimeInput = ({
  label,
  input,
  meta: { touched, invalid, error },
  timezone,
  ...custom
}) => {
  return (
    <FormControl fullWidth margin="normal">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          label={label}
          error={touched && invalid}
          helperText={touched && error}
          disableFuture={true}
          format="DD/MM/YY HH:mm"
          onChange={(val) =>
            timezone ? input.onChange(val.tz(timezone)) : input.onChange(val)
          }
          value={input.value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...custom}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};
