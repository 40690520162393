import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { useAuthState } from "../../../context/auth";
import { getClient, saveClient, updateClient } from "../../../api/clients";
import Card from "../../cards/Card";
import { handleRequestError, triggerToast } from "../../../helpers";
import Loader from "../../elements/Loader";
import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Admin";
import ClientForm from "../../forms/admin/ClientForm";
import { listModules } from "../../../api/modules";

const ManageClient = ({ id, setIsLoading }) => {
  const classes = useStyles();
  const user = useAuthState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [client, setClient] = useState(null);
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  const loadClient = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getClient(user.token, id);
      setClient(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching client: ");
      setLoading(false);
    }
  }, [user.token, id]);

  const fetchPermissions = useCallback(async () => {
    setLoading(true);
    try {
      const { data: modules } = await listModules(user.token);
      setPermissions(modules);
      setLoading(false);
      return modules;
    } catch (e) {
      setLoading(false);
      handleRequestError(e, "Failed fetching modules: ");
    }
  }, [user.token]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  // Load client initially
  useEffect(() => {
    id && loadClient(id);
  }, [loadClient, id]);

  const handleSubmit = async (formValues) => {
    setWaiting(true);
    const { id, name, directory } = formValues;
    const modules = Object.keys(formValues).reduce((arr, key) => {
      if (key.includes("access_") && formValues[key]) {
        arr.push(parseInt(key.split("access_").pop()));
      }
      return arr;
    }, []);

    try {
      const values = { id, name, directory, modules };
      const response = id
        ? await updateClient(user.token, id, values)
        : await saveClient(user.token, values);
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      history.push("/admin");
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to ${id ? "update" : "save"} client: `);
    }
  };

  const formInputs = useMemo(() => {
    const access =
      permissions &&
      client &&
      permissions.reduce((obj, item) => {
        obj[`access_${item.id}`] = client.module_access
          .map((item) => item.module_id)
          .includes(item.id);
        return obj;
      }, {});

    return client && access
      ? {
          id: client.id,
          name: client.name,
          directory: client.directory,
          ...access,
        }
      : null;
  }, [client, permissions]);

  return (
    <Loader active={loading} showChildren={false} message={`Loading Client...`}>
      <Headline headline={`Edit Client`} headlineVariant="h1" divider={true}>
        <FormButton
          text={id ? "Update" : "Save"}
          disabled={!valid || waiting}
          handleClick={() => dispatch(submit("ClientForm"))}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText={id ? "Updating" : "Saving"}
        />
      </Headline>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <ClientForm
                  permissions={permissions}
                  initialValues={formInputs}
                  isValid={(status) => setValid(status)}
                  isWaiting={(status) => setWaiting(status)}
                  onSubmit={handleSubmit}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ManageClient;
