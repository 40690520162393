import api from "./agritech";

async function ListSqueezes(token, clientId, fieldId, range) {
  const response = await api.post(
    `${clientId}/squeezes/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function SaveSqueeze(token, clientId, fieldId, values) {
  const response = await api.post(`${clientId}/squeezes/${fieldId}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function UpdateSqueeze(token, clientId, fieldId, id, values) {
  const response = await api.patch(
    `${clientId}/squeezes/${fieldId}/${id}`,
    values,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function DeleteSqueeze(token, clientId, fieldId, id) {
  const response = await api.delete(`${clientId}/squeezes/${fieldId}/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export { ListSqueezes, SaveSqueeze, UpdateSqueeze, DeleteSqueeze };
