import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Modal as MuiModal,
  Backdrop,
  Card,
  Slide,
} from "@material-ui/core";

const Modal = ({ children, open, closeable }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  useEffect(() => setIsOpen(open), [open]);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      width: 450,
      maxWidth: "95%",
      outline: "none",
    },
  }));
  const classes = useStyles();

  return (
    <MuiModal
      className={classes.modal}
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      disableBackdropClick={closeable ? !closeable : true}
      disableEscapeKeyDown={closeable ? !closeable : true}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Slide direction="up" in={isOpen}>
        <Card className={classes.card} elevation={9}>
          {children}
        </Card>
      </Slide>
    </MuiModal>
  );
};

export default Modal;
