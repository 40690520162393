import React from "react";
import clsx from "clsx";
import { makeStyles, CssBaseline, Container } from "@material-ui/core/";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Notifier from "../elements/Notifier";
import { useSidebarDispatch, useSidebarState } from "../../context/sidebar";

const drawerWidth = 240;

const Layout = ({ children, loading, header, sidebar, footer }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
      paddingTop: `calc(64px + ${theme.spacing(3)}px)`,
      paddingBottom: theme.spacing(4),
    },
    loading: { height: "inherit" },
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
      position: "relative",
      display: "flex",
    },
  }));
  const classes = useStyles();

  const { open, settings } = useSidebarState();
  const dispatch = useSidebarDispatch();
  const onToggleSidebar = () => {
    dispatch({ type: "setOpen", payload: !open });
    settings && dispatch({ type: "setSettingsOpen", payload: !settings });
  };

  return (
    <div className={classes.root}>
      <Notifier />
      <CssBaseline />
      <Header onToggleSidebar={onToggleSidebar} drawerWidth={drawerWidth}>
        {header}
      </Header>
      <Sidebar onToggleSidebar={onToggleSidebar} drawerWidth={drawerWidth}>
        {sidebar}
      </Sidebar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          maxWidth={false}
          className={clsx(classes.container, loading && classes.loading)}
        >
          {children}
        </Container>
      </main>
      {footer ? <Footer drawerWidth={drawerWidth}>{footer}</Footer> : null}
    </div>
  );
};

export default Layout;
