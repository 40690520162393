import React, { createContext, useReducer, useContext } from "react";
import { handleRequestError, setLocalTimestamps, percentage } from "../helpers";
import { ListAmbientClimates } from "../api/ambient-climate";

const AmbientClimateStateContext = createContext();
const AmbientClimateDispatchContext = createContext();

function ambientClimateReducer(state, action) {
  switch (action.type) {
    case "setAmbientClimate": {
      return { ...state, data: { ...action.payload } };
    }
    case "setStatus": {
      return { ...state, status: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AmbientClimateProvider({ children }) {
  const [state, dispatch] = useReducer(ambientClimateReducer, {
    status: "idle",
    data: [],
  });
  return (
    <AmbientClimateStateContext.Provider value={state}>
      <AmbientClimateDispatchContext.Provider value={dispatch}>
        {children}
      </AmbientClimateDispatchContext.Provider>
    </AmbientClimateStateContext.Provider>
  );
}

function useAmbientClimateState() {
  const context = useContext(AmbientClimateStateContext);
  if (context === undefined) {
    throw new Error(
      "useAmbientClimateState must be used within a AmbientClimateProvider"
    );
  }
  return context;
}

function useAmbientClimateDispatch() {
  const context = useContext(AmbientClimateDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAmbientClimateDispatch must be used within a AmbientClimateProvider"
    );
  }
  return context;
}

async function fetchAmbientClimates(
  dispatch,
  clientId,
  fieldId,
  range,
  token,
  timezone
) {
  dispatch({ type: "setStatus", payload: "pending" });
  try {
    const response = await ListAmbientClimates(token, clientId, fieldId, range);
    const data = response.data
      ? setLocalTimestamps(response.data, timezone)
          .reverse()
          .map(({ humidity, temperature, ...rest }) => ({
            ...rest,
            ambient_temp: temperature,
            humidity: percentage(humidity, 1, 0),
          }))
      : [];
    dispatch({ type: "setAmbientClimate", payload: data });
    dispatch({ type: "setStatus", payload: "resolved" });
    return data;
  } catch (e) {
    dispatch({ type: "setStatus", payload: "rejected" });
    handleRequestError(e, "Failed fetching ambient climate: ");
  }
}

export {
  AmbientClimateProvider,
  useAmbientClimateState,
  useAmbientClimateDispatch,
  fetchAmbientClimates,
};
