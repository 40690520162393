import React from "react";
import AdminTable from "./AdminTable";
import { deleteLogger } from "../../../api/loggers";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

const LoggersTable = ({ data, user, onRefresh }) => {
  const history = useHistory();
  const config = {
    columns: [
      {
        title: "Logger ID",
        field: "logger_id",
        render: ({ logger_id }) => <strong>{logger_id}</strong>,
      },
      {
        title: "Manufacturer",
        field: "manufacturer",
      },
      {
        title: "Timezone",
        field: "timezone",
      },
      {
        title: "Alignment",
        field: "alignment",
      },
    ],
    data,
    options: { pageSizeOptions: [5, 10, 20, 50] },
  };

  const onCreate = () => history.push("/admin/logger");
  const onEdit = (id) => history.push(`/admin/logger/${id}`);
  const onDelete = (record) => deleteLogger(user.token, record.id);

  return (
    <AdminTable
      title={<Typography variant="h2">Loggers</Typography>}
      type="loggers"
      config={config}
      onRefresh={onRefresh}
      onCreate={onCreate}
      onEdit={onEdit}
      onDelete={onDelete}
      deleteLabel="logger_id"
    />
  );
};

export default LoggersTable;
