import api from "./agritech";

async function ListRecommendations(token, clientId, fieldId, range) {
  const response = await api.post(
    `${clientId}/recommendations/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function SaveRecommendation(token, clientId, fieldId, values) {
  const response = await api.post(
    `${clientId}/recommendations/${fieldId}`,
    values,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function UpdateRecommendation(token, clientId, fieldId, id, values) {
  const response = await api.patch(
    `${clientId}/recommendations/${fieldId}/${id}`,
    values,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function DeleteRecommendation(token, clientId, fieldId, id) {
  const response = await api.delete(
    `${clientId}/recommendations/${fieldId}/${id}`,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return response.data;
}

export {
  ListRecommendations,
  SaveRecommendation,
  UpdateRecommendation,
  DeleteRecommendation,
};
