import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { useAuthState } from "../../../context/auth";
import { getUser, saveUser, updateUser } from "../../../api/users";
import Card from "../../cards/Card";
import { handleRequestError, triggerToast } from "../../../helpers";
import Loader from "../../elements/Loader";
import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Admin";
import UserForm from "../../forms/admin/UserForm";
import { listClients } from "../../../api/clients";

const ManageUser = ({ id, setIsLoading }) => {
  const classes = useStyles();
  const user = useAuthState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  const loadUser = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getUser(user.token, id);
      setActiveUser(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching user: ");
      setLoading(false);
    }
  }, [user.token, id]);

  const loadClients = useCallback(async () => {
    setLoading(true);
    try {
      const response = await listClients(user.token);
      setClients(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching clients: ");
      setLoading(false);
    }
  }, [user.token]);

  useEffect(() => {
    loadClients();
    if (id) {
      loadUser(id);
    }
  }, [loadUser, loadClients, id]);

  const clientsOptions = useMemo(
    () =>
      clients.reduce((obj, client) => {
        obj[client.id] = client.name;
        return obj;
      }, {}),
    [clients]
  );

  const handleSubmit = async (formValues) => {
    setWaiting(true);
    try {
      const values = {
        ...formValues,
        client_id: parseInt(formValues.client_id),
        admin: formValues.admin ? 1 : 0,
      };
      const response = id
        ? await updateUser(user.token, id, values)
        : await saveUser(user.token, values);
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      history.push("/admin");
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to ${id ? "update" : "save"} user: `);
    }
  };

  return (
    <Loader active={loading} showChildren={false} message={`Loading User...`}>
      <Headline headline={`Edit User`} headlineVariant="h1" divider={true}>
        <FormButton
          text={id ? "Update" : "Save"}
          disabled={!valid || waiting}
          handleClick={() => dispatch(submit("UserForm"))}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText={id ? "Updating" : "Saving"}
        />
      </Headline>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                {clients && (
                  <UserForm
                    clients={clientsOptions}
                    initialValues={activeUser}
                    isValid={(status) => setValid(status)}
                    isWaiting={(status) => setWaiting(status)}
                    onSubmit={handleSubmit}
                  />
                )}
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ManageUser;
