import { useTheme } from "@material-ui/core";
import React from "react";
import { VictoryChart, VictoryTooltip, VictoryVoronoiContainer } from "victory";
import { useIsSmallDevice } from "../../../../../../../hooks";
import chartTheme from "../../theme";

const ChartWrapper = ({
  axises,
  dimensions,
  aspectRatio,
  children,
  ...props
}) => {
  const theme = useTheme();
  const defaultWidth = 750;
  const defaultHeight = useIsSmallDevice() ? 250 : 75;
  const ratio = defaultHeight / defaultWidth;
  const parentWidth = dimensions?.width;
  const width = parentWidth || defaultWidth;
  const height =
    Math.round(parentWidth * (aspectRatio || ratio)) || defaultHeight;

  return (
    <VictoryChart
      // animate={{ duration: 1000 }}
      padding={{
        top: 0,
        bottom: 30,
        left: 40,
        right: Object.keys(axises).length > 1 ? 40 : 0,
      }}
      scale={{ x: "time" }}
      // standalone={false}
      domainPadding={{ x: [0, 5], y: [0, 5] }}
      theme={chartTheme(theme)}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension="x"
          labels={({ datum }) => datum.y}
          labelComponent={
            <VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: "white" }} />
          }
        />
      }
      width={width}
      height={height}
      {...props}
    >
      {children}
    </VictoryChart>
  );
};

export default ChartWrapper;
