import React, { useEffect, useState, useCallback } from "react";
import { Hidden, useTheme } from "@material-ui/core/";
import Layout from "../layout/Layout";
import Loader from "../elements/Loader";
import FieldFilters from "../header/FieldFilters";
import DashboardSettings from "../sidebar/DashboardSettings";
import {
  useFieldsDispatch,
  fetchFields,
  useFieldsState,
} from "../../context/fields";
import {
  MetricsProvider,
  useMetricsDispatch,
  fetchMetrics,
} from "../../context/metrics";
import FieldsList from "../field/FieldsList";
import { useSettingsDispatch, setVisibility } from "../../context/settings";
import { useAnalyticsDispatch } from "../../context/analytics";
import {
  useAuthState,
  refresh,
  useAuthDispatch,
  usePermissions,
} from "../../context/auth";
import Copyright from "../elements/Copyright";

const Dashboard = () => {
  return (
    <MetricsProvider>
      <DashboardContent />
    </MetricsProvider>
  );
};

const DashboardContent = () => {
  const user = useAuthState();
  const { fields } = useFieldsState();
  const theme = useTheme();
  const authDispatch = useAuthDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const permissions = usePermissions();
  const fieldsDispatch = useFieldsDispatch();
  const metricsDispatch = useMetricsDispatch();
  const settingsDispatch = useSettingsDispatch();
  const loadFields = useCallback(async () => {
    setIsLoading(true);
    try {
      await fetchFields(
        fieldsDispatch,
        user.client.id,
        user.token,
        user.client.directory
      );
      setVisibility(settingsDispatch, user.client.id, user.settings);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [
    fieldsDispatch,
    user.client.id,
    user.token,
    user.client.directory,
    settingsDispatch,
    user.settings,
  ]);

  // Load fields initially
  useEffect(() => {
    const { token, tokenExpiry } = user;
    refresh(authDispatch, { token, tokenExpiry });
    loadFields();
  }, [authDispatch, user, loadFields]);

  // Load metrics
  useEffect(() => {
    const { token } = user;
    fetchMetrics(metricsDispatch, token);
  }, [user, metricsDispatch]);

  // Reset DateRange
  const analyticsDispatch = useAnalyticsDispatch();
  useEffect(() => {
    analyticsDispatch({ type: "resetDateRange" });
  }, [analyticsDispatch]);

  return (
    <Layout
      loading={isLoading}
      header={
        <Hidden smDown>
          <FieldFilters fields={fields} color={theme.palette.secondary.main} />
        </Hidden>
      }
      sidebar={[
        <DashboardSettings
          key="dashboard-settings"
          user={user}
          loadingState={(state) => setIsLoading(state)}
        />,
      ]}
    >
      <Loader
        active={isLoading}
        showChildren={false}
        message="Loading fields..."
      >
        <Hidden mdUp>
          <FieldFilters />
        </Hidden>
        <FieldsList clientName={user.client.name} permissions={permissions} />
        <Copyright />
      </Loader>
    </Layout>
  );
};

export default Dashboard;
