import React from "react";
import { makeStyles, CardContent, Typography } from "@material-ui/core";
import FormButton from "../forms/FormButton";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const DeleteRecord = ({ title, id, waiting, onDelete, closeModal }) => {
  const classes = useStyles();

  return (
    <CardContent>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Typography gutterBottom>
        Are you sure you want to delete this record (#{id})? This cannot be
        undone once confirmed.
      </Typography>
      <div className={classes.buttonGroup}>
        <FormButton text="Close" handleClick={closeModal} />
        <FormButton
          text="Delete"
          disabled={waiting}
          handleClick={() => onDelete()}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText="Deleting"
        />
      </div>
    </CardContent>
  );
};

export default DeleteRecord;
