import api from "./agritech";

async function listClients(token) {
  const response = await api.get(`/clients`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function getClient(token, id) {
  const response = await api.get(`/clients/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function saveClient(token, values) {
  const response = await api.post(
    `/clients`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function updateClient(token, id, values) {
  const response = await api.patch(
    `/clients/${id}`,
    { ...values },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function deleteClient(token, id) {
  const response = await api.delete(`/clients/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export { listClients, getClient, saveClient, updateClient, deleteClient };
