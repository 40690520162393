import React, { createContext, useReducer, useContext } from "react";
import api from "../api/agritech";
import { handleRequestError, filterIrrimax, fieldWithSmd } from "../helpers";
import { fetchIrrimax } from "../api/irrimax";

const FieldsStateContext = createContext();
const FieldsDispatchContext = createContext();

function fieldsReducer(state, action) {
  switch (action.type) {
    case "setFields": {
      return { ...state, fields: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function FieldsProvider({ children }) {
  const [state, dispatch] = useReducer(fieldsReducer, { fields: [] });
  return (
    <FieldsStateContext.Provider value={state}>
      <FieldsDispatchContext.Provider value={dispatch}>
        {children}
      </FieldsDispatchContext.Provider>
    </FieldsStateContext.Provider>
  );
}

function useFieldsState() {
  const context = useContext(FieldsStateContext);
  if (context === undefined) {
    throw new Error("useFieldsState must be used within a FieldsProvider");
  }
  return context;
}

function useFieldsDispatch() {
  const context = useContext(FieldsDispatchContext);
  if (context === undefined) {
    throw new Error("useFieldsDispatch must be used within a FieldsProvider");
  }
  return context;
}

async function fieldData(clientId, token, current, total, fields) {
  let data = fields?.length ? [...fields] : [];
  let page = current || 1;
  let lastPage = total || 1;
  const params = lastPage && page > 1 ? `?page=${page}` : "";
  const requestFieldData = async (queryParams) =>
    api.get(`${clientId}/fields/latest-data/${queryParams}`, {
      headers: { Authorization: "Bearer " + token },
    });

  try {
    const response = await requestFieldData(params);
    const { paginator } = response?.data?.params;
    page = paginator?.currentPage || page;
    lastPage = paginator?.lastPage || lastPage;
    data = [...data, ...response?.data?.data];

    if (page !== lastPage) {
      const secondaryResponse = await fieldData(
        clientId,
        token,
        page + 1,
        lastPage,
        data
      );
      return secondaryResponse;
    }

    return data;
  } catch (e) {
    return;
  }
}

async function fetchFields(dispatch, clientId, token, folder) {
  try {
    const response = await api.get(clientId + "/fields", {
      headers: { Authorization: "Bearer " + token },
    });
    const fieldsList = Object.values(response.data.data);
    const fieldsData = await fieldData(clientId, token);
    let fields = fieldsList.reduce((arr, item) => {
      const data = fieldsData?.find(({ id }) => item?.id === id);
      const field = { ...item, latest: { ...item.latest, ...data?.latest } };
      return [...arr, field];
    }, []);

    if (fields?.length && folder) {
      try {
        const irrimax = await fetchIrrimax(folder);
        fields = irrimax
          ? fieldsList.map((field) => filterIrrimax(irrimax, field))
          : [...fieldsList];
      } catch (e) {
        handleRequestError(e, "Failed fetching irrimax: ");
      }
    }

    // append calculate statistics
    fields = fields.map((field) => fieldWithSmd(field));

    dispatch({ type: "setFields", payload: fields });
  } catch (e) {
    handleRequestError(e, "Failed fetching fields: ");
  }
}

export { FieldsProvider, useFieldsState, useFieldsDispatch, fetchFields };
