import React, { createContext, useReducer, useContext } from "react";
import { defaultDateRange } from "../helpers";

const ChartStateContext = createContext();
const ChartDispatchContext = createContext();

function chartReducer(state, action) {
  switch (action.type) {
    case "setZoom": {
      return { ...state, zoom: { ...state.zoom, ...action.payload } };
    }
    case "resetZoom": {
      return { ...state, zoom: defaultDateRange() };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ChartProvider({ children }) {
  const [state, dispatch] = useReducer(chartReducer, {
    zoom: defaultDateRange(),
  });
  return (
    <ChartStateContext.Provider value={state}>
      <ChartDispatchContext.Provider value={dispatch}>
        {children}
      </ChartDispatchContext.Provider>
    </ChartStateContext.Provider>
  );
}

function useChartState() {
  const context = useContext(ChartStateContext);
  if (context === undefined) {
    throw new Error("useChartState must be used within a ChartProvider");
  }
  return context;
}

function useChartDispatch() {
  const context = useContext(ChartDispatchContext);
  if (context === undefined) {
    throw new Error("useChartDispatch must be used within a ChartProvider");
  }
  return context;
}

export { ChartProvider, useChartState, useChartDispatch };
