import React, { useMemo } from "react";
import { makeStyles, Typography, Fade, useTheme } from "@material-ui/core";
import CardLayout from "../CardLayout";
import { useChartDropdownActions, useIrrimaxCharts } from "../../hooks";

const useStyles = makeStyles((theme) => ({
  chart: {
    maxWidth: "100%",
  },
}));

export default ({ charts }) => {
  const classes = useStyles();
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };
  const defaultActions = useChartDropdownActions();
  const { irrimaxCharts, irrimaxChartActions } = useIrrimaxCharts(charts);
  const options = useMemo(
    () => [{ ...defaultActions }, { ...irrimaxChartActions }],
    [defaultActions, irrimaxChartActions]
  );

  return (
    <CardLayout title="Irrigation" options={options}>
      {irrimaxCharts.map((chart, i) => {
        return (
          <Fade
            key={"irrimax-" + i}
            in={chart.display}
            timeout={transitionDuration}
            style={{ transitionDelay: `${transitionDuration.exit}ms` }}
            unmountOnExit
          >
            <>
              <Typography variant="h5"> {chart.name} </Typography>
              <img
                src={chart.link}
                alt={chart.name}
                className={classes.chart}
              />
            </>
          </Fade>
        );
      })}
    </CardLayout>
  );
};
