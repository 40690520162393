import React from "react";
import Layout from "../layout/Layout";
import CardWarning from "../cards/CardWarning";

export default ({ message }) => {
  return (
    <Layout>
      <CardWarning message={message} color="disabled" />
    </Layout>
  );
};
