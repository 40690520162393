import React, { createContext, useReducer, useContext, useEffect } from "react";
import moment from "moment";
import { auth, getUser } from "../api/auth";
import { SubmissionError } from "redux-form";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

function settingsReducer(state, action) {
  switch (action.type) {
    case "setUser": {
      return { ...state, ...action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const defaultState = {
  id: null,
  name: null,
  email: null,
  isAdmin: false,
  client: { id: null },
  token: null,
  tokenExpiry: null,
  settings: [],
};

function AuthProvider({ children }) {
  const localNode = "AgriTechUser";
  const local = localStorage.getItem(localNode);
  const initialState =
    (local &&
      JSON.parse(typeof local === "string" ? local : toString(local))) ||
    defaultState;
  const [state, dispatch] = useReducer(settingsReducer, initialState);

  useEffect(() => {
    localStorage.setItem(localNode, JSON.stringify(state));
  }, [state]);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
  return context;
}

function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
  return context;
}

function usePermissions() {
  const { client } = useAuthState();
  return client.module_access
    ? client.module_access.map((module) => module.module_id)
    : [];
}

function useHasAccess(id) {
  const permissions = usePermissions();
  return permissions.includes(id);
}

async function login(dispatch, credentials) {
  try {
    const authenticate = await auth(credentials);
    const {
      data: { token, expires_in },
    } = authenticate;
    // request user details
    try {
      const response = await getUser(token);
      const {
        data: { client, user },
      } = response;

      dispatch({
        type: "setUser",
        payload: {
          id: user.id,
          name: user.name,
          email: user.email,
          isAdmin: user.admin === 1,
          client,
          token,
          tokenExpiry: moment().add(expires_in, "seconds"),
          settings: user.settings,
        },
      });

      return true;
    } catch (e) {
      throw new SubmissionError({
        _error: `Unable to get user details: ${e.response.data.message}`,
      });
    }
  } catch (e) {
    const message =
      e.response === undefined ? e.message : e.response.data.message;
    throw new SubmissionError({
      _error: `Login failed: ${message}`,
    });
  }
}

async function refresh(dispatch, { token, tokenExpiry }) {
  // if auth token is expired
  if (tokenExpiry === null || moment(tokenExpiry).isBefore(moment())) {
    logout(dispatch);
  }

  const expiryLimit = 60 * 60 * 24 * 1; // 1 day;
  // if token expires within 1 day
  if (moment(tokenExpiry).diff(moment(), "s") < expiryLimit) {
    // attempt to refresh token
    try {
      const response = await refresh(token);
      // update the user token & expiry
      dispatch({
        type: "setUser",
        payload: {
          token: response.data.token,
          tokenExpiry: moment().add(response.data.expires_in, "seconds"),
        },
      });
    } catch (e) {
      // if network error
      if (
        e.response === undefined ||
        e.response.data.message === "Token is Expired"
      ) {
        // display login modal
        return logout(dispatch);
      }
    }
  }
}

async function logout(dispatch) {
  dispatch({ type: "setUser", payload: defaultState });
}

export {
  AuthProvider,
  useAuthState,
  useAuthDispatch,
  login,
  refresh,
  logout,
  usePermissions,
  useHasAccess,
};
