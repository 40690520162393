import React, { useEffect, useState, useCallback } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useParams } from "react-router-dom";
import Loader from "../elements/Loader";
import Layout from "../layout/Layout";
import Card from "../cards/Card";
import Headline from "../elements/Headline";
import {
  fetchReportById,
  ReportsProvider,
  useReportsDispatch,
  useReportsState,
} from "../../context/reports";
import CardWarning from "../cards/CardWarning";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: 400,
    color: theme.palette.grey[700],
  },
  gutterTop: {
    marginTop: theme.spacing(2),
  },
}));

const Report = () => {
  return (
    <ReportsProvider>
      <ReportContent />
    </ReportsProvider>
  );
};

const ReportContent = () => {
  const classes = useStyles();
  const dispatch = useReportsDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { reportId } = useParams();
  const { report } = useReportsState();

  const loadReport = useCallback(async () => {
    setIsLoading(true);
    try {
      await fetchReportById(dispatch, reportId);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      dispatch({ type: "setActiveReport", payload: null });
    }
  }, [dispatch, reportId]);

  // Load report initially
  useEffect(() => {
    loadReport(reportId);
  }, [loadReport, reportId]);

  return (
    <Layout loading={isLoading}>
      <Loader
        active={isLoading}
        showChildren={false}
        message="Loading reports..."
      >
        {report ? (
          <>
            <Headline
              headline={report.title}
              headlineVariant="h1"
              divider={true}
            >
              <IconButton component="a" target="_blank" href={report.pdf}>
                <GetAppIcon />
              </IconButton>
            </Headline>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography component="p" className={classes.gutterTop}>
                  {report.report_comments}
                </Typography>
              </Grid>
              {Object.keys(report).length > 0 &&
                report.content.length > 0 &&
                report.content.map((item, index) => (
                  <Grid item xs={12} key={`report-item-${report.id}-${index}`}>
                    <Card
                      headline={item.name}
                      headlineVariant="h6"
                      headlineChildren={[
                        <Typography
                          variant="subtitle2"
                          className={classes.subtitle}
                          key={
                            "report-subheadline-" +
                            report.id +
                            "-" +
                            index +
                            "cropType"
                          }
                        >
                          Crop Type: <Box component="strong">{item.group}</Box>
                        </Typography>,

                        <Typography
                          variant="subtitle2"
                          className={classes.subtitle}
                          key={`report-subheadline-${report.id}-${index}-logger`}
                        >
                          Logger: <Box component="strong">{item.probe}</Box>
                        </Typography>,
                        <Box
                          key={`report-subheadline-${report.id}-${index}-status`}
                          component="strong"
                          color={
                            item.status === "Too Dry"
                              ? "warning.main"
                              : item.status === "Too Wet"
                              ? "info.main"
                              : "primary.main"
                          }
                        >
                          {item.status}
                        </Box>,
                      ]}
                      subHeadlineVariant="subtitle1"
                      divider={true}
                    >
                      <Typography className={classes.gutterTop}>
                        {item.comments}
                      </Typography>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </>
        ) : (
          <CardWarning
            message={`No report found with ID:${reportId}`}
            color="disabled"
          />
        )}
      </Loader>
    </Layout>
  );
};

export default Report;
