import api from "./agritech";

async function listManufacturers(token) {
  const response = await api.get(`/manufacturers`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export { listManufacturers };
