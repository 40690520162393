import { useMemo } from "react";
import moment from "moment";
import { useTheme, hexToRgb } from "@material-ui/core/styles";

export const useParamBoundaries = (field, parameters, range) => {
  return useMemo(() => {
    const { params, types } = parameters;
    const start_date = range.start_date
      .clone()
      .tz(field.timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    const end_date = range.end_date
      .clone()
      .tz(field.timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    const events = [];

    // const test = params.reduce((obj, item) => {
    //   obj[item.type] = [];
    //   return obj;
    // }, {});

    // console.log(test);

    // Create initial data structure
    let chart = params.reduce((obj, item) => {
      obj[item.type] = [];
      return obj;
    }, {});

    // Populate with provided params
    params.forEach((item) => {
      const timestamp = moment(
        item.timestamp.clone().tz(field.timezone).format("YYYY-MM-DD HH:mm:ss")
      );
      if (timestamp.isBefore(start_date)) {
        // set the start of the line
        chart[item.type].unshift({ t: moment(start_date), y: item.value });
      } else {
        // add the adjusted value
        chart[item.type].push({ t: timestamp, y: item.value });

        if (!events.map((item) => item.t._i).includes(timestamp._i)) {
          events.push({ t: timestamp, label: item.description });
        }
      }
    });

    const formattedParams = Object.keys(chart).reduce((obj, item) => {
      const count = chart[item].length;
      const arr = [];
      if (count > 1) {
        let prev = null;
        chart[item].forEach((record, index) => {
          if (index === 0) {
            arr.push(record);
          } else {
            arr.push({ t: record.t, y: prev.y });
            arr.push(record);
            if (index === count - 1) {
              arr.push({ t: moment(end_date), y: record.y });
            }
          }
          prev = record;
        });
      } else {
        const record = chart[item][0];
        arr.push({ t: moment(start_date), y: record.y });
        arr.push({ t: moment(end_date), y: record.y });
      }

      const type = types.filter((type) => type.id === parseInt(item));
      const key = type.length ? type[0].slug : item;

      obj[key] = arr;
      return obj;
    }, {});

    return { events, settings: formattedParams };
  }, [field.timezone, parameters, range.end_date, range.start_date]);
};

function boundaryStyles(color) {
  return {
    data: {
      stroke: color,
      strokeDasharray: [10, 10],
      fill: color?.replace(")", ", 0.4)"),
    },
  };
}

export const useBoundaryStyles = (keys) => {
  const {
    palette: {
      info,
      danger,
      pink,
      purple,
      secondary,
      grey,
      brown,
      primary,
      orange,
    },
  } = useTheme();
  const boundaries = {
    field_capactiy: {
      type: "area",
      // fill: "end",
      style: boundaryStyles(info.main),
    },
    refill_point: {
      type: "area",
      // fill: "start",
      style: boundaryStyles(danger.main),
    },
    scab_trigger: {
      type: "line",
      style: boundaryStyles(pink.main),
    },
    irrigation_trigger: {
      type: "area",
      // fill: "refill_point",
      style: boundaryStyles(purple.main),
    },
    pwp: {
      type: "line",
      style: boundaryStyles(grey[400]),
    },
    saturation: {
      type: "line",
      style: boundaryStyles(brown.main),
    },
    smd_5: {
      type: "line",
      style: boundaryStyles(hexToRgb("#06D59E")),
    },
    smd_10: {
      type: "line",
      style: boundaryStyles(hexToRgb("#167547")),
    },
    smd_15: {
      type: "line",
      style: boundaryStyles(hexToRgb("#1ea47e")),
    },
    smd_20: {
      type: "line",
      style: boundaryStyles(hexToRgb("#4fb56a")),
    },
    smd_25: {
      type: "line",
      style: boundaryStyles(hexToRgb("#82c250")),
    },
    smd_30: {
      type: "line",
      style: boundaryStyles(hexToRgb("#bacc30")),
    },
    smd_35: {
      type: "line",
      style: boundaryStyles(hexToRgb("#f8cf10")),
    },
    smd_40: {
      type: "line",
      style: boundaryStyles(hexToRgb("#fbb800")),
    },
    smd_45: {
      type: "line",
      style: boundaryStyles(hexToRgb("#fda000")),
    },
    smd_50: {
      type: "line",
      style: boundaryStyles(hexToRgb("#fc8700")),
    },
    smd_55: {
      type: "line",
      style: boundaryStyles(hexToRgb("#fa6d00")),
    },
    smd_60: {
      type: "line",
      style: boundaryStyles(hexToRgb("#f55014")),
    },
    smd_65: {
      type: "line",
      style: boundaryStyles(hexToRgb("#ce3922")),
    },
    smd_70: {
      type: "line",
      style: boundaryStyles(hexToRgb("#a52726")),
    },
    smd_75: {
      type: "line",
      style: boundaryStyles(hexToRgb("#7b1a23")),
    },
    smd_80: {
      type: "line",
      style: boundaryStyles(hexToRgb("#52111c")),
    },
    smd_85: {
      type: "line",
      style: boundaryStyles(hexToRgb("#2b090f")),
    },
    full_point: {
      type: "area",
      // fill: "end",
      // fill: "med_squeeeze",
      style: boundaryStyles(info.dark),
    },
    irri_upper: {
      type: "line",
      style: boundaryStyles(info.main),
    },
    med_squeeeze: {
      type: "line",
      style: boundaryStyles(secondary.dark),
      // fill: "irri_lower",
    },
    hard_squeeeze: {
      type: "line",
      style: boundaryStyles(orange.main),
    },
    irri_lower: {
      type: "area",
      // fill: "start",
      style: boundaryStyles(danger.main),
    },
    high_et_days: {
      type: "line",
      style: boundaryStyles(primary.main),
    },
    low_et_days: {
      type: "line",
      style: boundaryStyles(orange.main),
    },
  };
  return keys.reduce((obj, key) => {
    return { ...obj, [key]: boundaries[key] };
  }, {});
};
