import React, { Fragment, useEffect } from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { Typography, Grid, IconButton, Divider } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { DateTimeInput, TextInput, SelectInput } from "./FormInputs";
import { useParameterState } from "../../context/parameters";

const validate = (values) => {
  const errors = {};
  if (!values.description) {
    errors.description = "Required";
  }
  if (!values.params || !values.params.length) {
    errors.params = { _error: "At least one param must be entered" };
  } else {
    const errors = [];
    values.params.forEach((param, index) => {
      const paramErrors = {};
      if (!param || !param.type) {
        paramErrors.type = "Required";
        errors[index] = paramErrors;
      }
      if (!param || !param.value) {
        paramErrors.value = "Required";
        errors[index] = paramErrors;
      }
    });
    if (errors.length) {
      errors.params = errors;
    }
  }
  return errors;
};

const RenderParams = ({ fields, meta: { error, submitFailed } }) => {
  const { types } = useParameterState();
  const options = types.reduce(
    (obj, item) => ({ ...obj, [item.id]: item.name }),
    {}
  );

  return (
    <Fragment>
      <Grid container>
        <Grid item xs>
          <Typography variant="h5">Parameters</Typography>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Remove"
            size="small"
            type="button"
            title="Remove"
            color="primary"
            onClick={() => fields.push({})}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
      {submitFailed && error && <span>{error}</span>}

      {fields.map((param, index) => (
        <Grid key={index} container alignItems="center" spacing={2}>
          <Grid item xs>
            <Field
              name={`${param}.type`}
              id={`${param}.type`}
              type="text"
              component={SelectInput}
              label="Type"
              options={options}
            />
          </Grid>
          <Grid item xs>
            <Field
              name={`${param}.value`}
              id={`${param}.value`}
              component={TextInput}
              type="number"
              label="Value"
            />
          </Grid>
          <Grid item>
            {index ? (
              <IconButton
                aria-label="Remove"
                size="small"
                type="button"
                title="Remove"
                onClick={() => fields.remove(index)}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            ) : (
              <div style={{ width: 30 }} />
            )}
          </Grid>
        </Grid>
      ))}
    </Fragment>
  );
};

const RecordParameters = ({
  title,
  timezone,
  handleSubmit,
  error,
  valid,
  pristine,
  submitting,
  isValid,
  isWaiting,
  formStyles,
}) => {
  const classes = formStyles();

  useEffect(() => {
    isValid(!pristine && valid && !submitting);
    isWaiting(submitting);
  });

  return (
    <Fragment>
      {title && (
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
      )}
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Field
          component={DateTimeInput}
          name="timestamp"
          id="parameter-form-timestamp"
          label="Timestamp"
          timezone={timezone}
        />
        <Field
          component={TextInput}
          name="description"
          id="parameter-description"
          label="Description"
        />

        <Divider className={classes.divider} />

        <FieldArray name="params" component={RenderParams} />

        <Typography variant="body1" gutterBottom color="error">
          <b>{error}</b>
        </Typography>

        <Divider className={classes.divider} />
      </form>
    </Fragment>
  );
};

export default reduxForm({
  form: "RecordParameters", // a unique identifier for this form
  validate,
})(RecordParameters);
