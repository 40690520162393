import React from "react";
import AdminTable from "./AdminTable";
import { deleteUser } from "../../../api/users";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

const UsersTable = ({ data, user, onRefresh }) => {
  const history = useHistory();
  const config = {
    columns: [
      {
        title: "Name",
        field: "name",
        render: ({ id, name }) => <strong>{name}</strong>,
      },
      {
        title: "Email",
        field: "email",
      },
      {
        title: "Client",
        field: "client",
      },
    ],
    data,
    options: { pageSizeOptions: [5, 10, 20, 50] },
  };

  const onCreate = () => history.push("/admin/user");
  const onEdit = (id) => history.push(`/admin/user/${id}`);
  const onDelete = (record) => deleteUser(user.token, record.id);

  return (
    <AdminTable
      title={<Typography variant="h2">Users</Typography>}
      type="users"
      config={config}
      onRefresh={onRefresh}
      onCreate={onCreate}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};

export default UsersTable;
