import React from "react";
import clsx from "clsx";
import { makeStyles, Card, CardContent } from "@material-ui/core";
import Headline from "../elements/Headline";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: "100%",
    paddingBottom: theme.spacing(2),
    borderRadius: 0,
    boxShadow: "inset 0 -5px 0 0 " + theme.palette.primary.main,
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      width: 30,
      height: 60,
      transform: "rotate(45deg)",
      borderLeftColor: theme.palette.grey[300],
      borderLeftWidth: 5,
      borderLeftStyle: "solid",
      background: theme.palette.secondary.main,
      right: -15,
      bottom: -20,
      transition: "all 0.2s ease-in-out",
    },
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "inset 0 -5px 0 0 " +
        theme.palette.primary.main +
        ", " +
        theme.shadows[9],
      "&:after": {
        borderLeftWidth: 0,
        right: -30,
        bottom: -50,
      },
    },
  },
  subHeadline: {
    color: theme.palette.text.secondary,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}));
export default (props) => {
  const classes = useStyles();
  return (
    <Card
      className={clsx(classes.card, props.link && classes.link)}
      onClick={props.link}
    >
      <CardContent>
        {props.headline && (
          <Headline
            headline={props.headline}
            headlineVariant={props.headlineVariant}
            subHeadline={props.subheadline}
            subHeadlineVariant={props.subHeadlineVariant}
            divider={props.divider}
          >
            {props.headlineChildren}
          </Headline>
        )}
        {props.children}
      </CardContent>
    </Card>
  );
};
