import { CircularProgress, Grid } from "@material-ui/core";
// import moment from "moment";
import React from "react";
import { VictoryAxis } from "victory";
import { useAnalyticsState } from "../../../../../context/analytics";
import ChartWrapper from "./components/ChartWrapper";

const Chart = ({
  data,
  datasets,
  axises,
  children,
  parent: { aspectRatio, ...dimensions },
}) => {
  const {
    range: { start_date, end_date },
  } = useAnalyticsState();
  if (!Object.keys(datasets)?.length || !data?.length || !data[0]?.length) {
    return (
      <Grid container justify="center">
        <CircularProgress color="secondary" style={{ margin: "0 auto" }} />
      </Grid>
    );
  }

  return (
    <ChartWrapper
      axises={axises}
      dimensions={dimensions}
      aspectRatio={aspectRatio}
    >
      {children}
      <VictoryAxis
        // tickFormat={(t) => moment(t).format("DD/MM")}
        domain={{ x: [start_date, end_date] }}
      />
    </ChartWrapper>
  );
};

export default Chart;
