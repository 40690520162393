import React from "react";
import Card from "../..";
import { useAgronomicsState } from "../../../../../../../context/agronomics";
import DefaultChart from "../../../Chart/components/DefaultChart";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";

const AgronomicsEc = ({ title }) => {
  const { status, data: rawData } = useAgronomicsState();
  const loading = useLoggerLoadingStatus(status);
  const values = ["ag_ec_in", "ag_ec_out", "ag_ec_sum", "ag_ec_bag"];
  const defaultTable = useSensorDataTable(rawData, title, values);
  const { data, hasData } = useChartData(Object.values(rawData), values);
  const { ag_ec_in, ag_ec_out, ag_ec_sum } = useChartMetrics([
    "ag_ec_in",
    "ag_ec_out",
    "ag_ec_sum",
  ]);
  const datasets = {
    ag_ph_in: {
      ...ag_ec_in,
      type: "line",
      axis: "ec",
    },
    ag_ph_out: {
      ...ag_ec_out,
      type: "line",
      axis: "ec",
    },
    ag_ph_sum: {
      ...ag_ec_sum,
      type: "line",
      axis: "ec",
    },
  };
  const axises = useAxisesMinMax(
    {
      ec: {
        id: 0,
        orientation: "left",
        label: "EC",
      },
    },
    datasets,
    data
  );

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default AgronomicsEc;
