import React, { createContext, useReducer, useContext } from "react";
import moment from "moment-timezone";
import { handleRequestError } from "../helpers";
import { craft } from "../api/agritech";

const ReportsStateContext = createContext();
const ReportsDispatchContext = createContext();

function reportsReducer(state, action) {
  switch (action.type) {
    case "setReports": {
      return { ...state, list: action.payload };
    }
    case "setActiveReport": {
      return { ...state, report: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ReportsProvider({ children }) {
  const [state, dispatch] = useReducer(reportsReducer, {
    list: [],
    report: null,
  });
  return (
    <ReportsStateContext.Provider value={state}>
      <ReportsDispatchContext.Provider value={dispatch}>
        {children}
      </ReportsDispatchContext.Provider>
    </ReportsStateContext.Provider>
  );
}

function useReportsState() {
  const context = useContext(ReportsStateContext);
  if (context === undefined) {
    throw new Error("useReportsState must be used within a ReportsProvider");
  }
  return context;
}

function useReportsDispatch() {
  const context = useContext(ReportsDispatchContext);
  if (context === undefined) {
    throw new Error("useReportsDispatch must be used within a ReportsProvider");
  }
  return context;
}

async function fetchReportById(dispatch, id) {
  try {
    const response = await craft.get(`/report/${id}.json`);
    const {
      data: { data },
    } = response;

    if (data.length) {
      const {
        date: { date, timezone },
      } = data[0];
      const timestamp = moment.tz(
        date.substring(0, date.length - 7),
        "YYYY-MM-DD hh:mm:ss",
        timezone
      );

      dispatch({
        type: "setActiveReport",
        payload: { ...data[0], date: timestamp },
      });
    }
  } catch (e) {
    handleRequestError(e, "Failed fetching reports: ");
  }
}

async function fetchReports(dispatch, clientName) {
  try {
    const response = await craft.get(`/reports/${clientName}.json`);
    const {
      data: { data },
    } = response;
    const reports =
      data.length &&
      data.map((item) => {
        const {
          date: { date, timezone },
        } = item;
        const timestamp = moment.tz(
          date.substring(0, date.length - 7),
          "YYYY-MM-DD hh:mm:ss",
          timezone
        );

        return {
          ...item,
          date: timestamp,
          group: timestamp.startOf("month").unix(),
        };
      });
    dispatch({ type: "setReports", payload: reports });
  } catch (e) {
    handleRequestError(e, "Failed fetching reports: ");
  }
}

export {
  ReportsProvider,
  useReportsState,
  useReportsDispatch,
  fetchReportById,
  fetchReports,
};
