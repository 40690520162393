import { useTheme } from "@material-ui/core";
import React from "react";
import {
  analyticsTableToolbar,
  defaultTableOptions,
} from "../pages/Analytics/util";
import ActionTable from "./ActionTable";

export default ({ config, onEdit, onDelete }) => {
  const theme = useTheme();
  return (
    <ActionTable
      config={{
        ...config,
        components: analyticsTableToolbar(theme),
        options: defaultTableOptions,
      }}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};
