import { VictoryTheme } from "victory";

function theme(muiTheme) {
  let theme = { ...VictoryTheme.material };

  theme.axis.style = {
    ...theme.axis.style,
    axis: {
      ...theme.axis.style.axis,
      strokeWidth: 1,
    },
    grid: {
      ...theme.axis.style.grid,
      stroke: "rgba(0,0,0,0.15)",
    },
  };

  return theme;
}

export default theme;
