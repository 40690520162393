import React, { useMemo } from "react";
import Card from "../..";
import { useAmbientClimateState } from "../../../../../../../context/ambient-climate";
import DefaultChart from "../../../Chart/components/DefaultChart";
import {
  useAxisesMinMax,
  useChartData,
  useChartMetrics,
} from "../../../Chart/hooks";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";

const ChillingHours = ({ title }) => {
  const { status, data: rawData } = useAmbientClimateState();
  const loading = useLoggerLoadingStatus(status);
  const { hourlyData } = useMemo(
    () =>
      Object.values(rawData).reduce(
        (prev, { timestamp, ambient_temp }) => {
          const { hours, last, hourlyData } = prev;
          const diffMins = last ? timestamp.diff(last, "minutes") : 60;
          if (diffMins >= 60) {
            const chilling_hours =
              ambient_temp <= 5 ? Math.round(diffMins / 60) + hours : hours;

            return {
              hours: chilling_hours,
              last: timestamp,
              hourlyData: [...hourlyData, { timestamp, chilling_hours }],
            };
          }
          return prev;
        },
        {
          hours: 0,
          last: null,
          hourlyData: [],
        }
      ),
    [rawData]
  );
  const values = ["chilling_hours"];
  const defaultTable = useSensorDataTable(rawData, title, values);
  const { data, hasData } = useChartData(hourlyData, values);
  const { chilling_hours } = useChartMetrics(values);
  const datasets = {
    chilling_hours: {
      ...chilling_hours,
      type: "area",
      axis: "hours",
    },
  };
  const axises = useAxisesMinMax(
    {
      hours: {
        id: 0,
        orientation: "left",
        label: "Hours",
      },
    },
    datasets,
    data
  );

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[defaultTable]}
    />
  );
};

export default ChillingHours;
