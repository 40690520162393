import api from "./agritech";

async function ListParameterTypes(token) {
  const response = await api.get(`/parameter-types`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function ListParameters(token, clientId, fieldId, range) {
  const response = await api.post(
    `${clientId}/parameters/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function SaveParameters(token, clientId, fieldId, values) {
  const response = await api.post(`${clientId}/parameters/${fieldId}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function UpdateParameter(token, clientId, fieldId, id, values) {
  const response = await api.patch(
    `${clientId}/parameters/${fieldId}/${id}`,
    values,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function DeleteParameter(token, clientId, fieldId, id) {
  const response = await api.delete(`${clientId}/parameters/${fieldId}/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

export {
  ListParameterTypes,
  ListParameters,
  SaveParameters,
  UpdateParameter,
  DeleteParameter,
};
