import React, { createContext, useReducer, useContext } from "react";
import { listMetrics } from "../api/metrics";
import { handleRequestError } from "../helpers";

const MetricStateContext = createContext();
const MetricDispatchContext = createContext();

function metricsReducer(state, action) {
  switch (action.type) {
    case "setMetrics": {
      return action.payload;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MetricsProvider({ children }) {
  const [state, dispatch] = useReducer(metricsReducer, []);
  return (
    <MetricStateContext.Provider value={state}>
      <MetricDispatchContext.Provider value={dispatch}>
        {children}
      </MetricDispatchContext.Provider>
    </MetricStateContext.Provider>
  );
}

function useMetricsState() {
  const context = useContext(MetricStateContext);
  if (context === undefined) {
    throw new Error("useMetricsState must be used within a MetricsProvider");
  }
  return context;
}

async function fetchMetrics(dispatch, token) {
  try {
    const response = await listMetrics(token);
    const data = response.data
      ? response.data.map((item) => ({
          ...item,
          color: JSON.parse(item.color),
          limits: JSON.parse(item.limits),
          thresholds: JSON.parse(item.thresholds),
        }))
      : [];
    dispatch({ type: "setMetrics", payload: data });
    return data;
  } catch (e) {
    handleRequestError(e, "Failed fetching metrics: ");
  }
}

function useMetricsDispatch() {
  const context = useContext(MetricDispatchContext);
  if (context === undefined) {
    throw new Error("useMetricsDispatch must be used within a MetricsProvider");
  }
  return context;
}

export { MetricsProvider, useMetricsState, useMetricsDispatch, fetchMetrics };
