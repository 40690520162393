export function calcMaxima(data) {
  return data.reduce((arr, subset) => {
    const hasData = subset.filter(({ y }) => y !== null).length;
    return hasData ? [...arr, Math.max(...subset.map((d) => d.y))] : arr;
  }, []);
}

export function calcMinima(data) {
  return data.reduce((arr, subset) => {
    const hasData = subset.filter(({ y }) => y !== null).length;
    return hasData ? [...arr, Math.min(...subset.map((d) => d.y))] : arr;
  }, []);
}

export function maximaMinima(data) {
  const maxima = calcMaxima(data);
  const minima = calcMinima(data);
  const max = Math.ceil(Math.max(...maxima));
  const min = Math.floor(Math.min(...minima));
  return { maxima, minima, max, min };
}
