import React, { createContext, useReducer, useContext } from "react";

const FieldsFiltersStateContext = createContext();
const FieldsFiltersDispatchContext = createContext();

function fieldsFiltersReducer(state, action) {
  switch (action.type) {
    case "fieldsFilterSelection": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "resetFilters": {
      return defaultFilters();
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const defaultFilters = () => ({ farm: "", crop_type: "" });

function FieldsFiltersProvider({ children }) {
  const [state, dispatch] = useReducer(fieldsFiltersReducer, defaultFilters());
  return (
    <FieldsFiltersStateContext.Provider value={state}>
      <FieldsFiltersDispatchContext.Provider value={dispatch}>
        {children}
      </FieldsFiltersDispatchContext.Provider>
    </FieldsFiltersStateContext.Provider>
  );
}

function useFieldsFiltersState() {
  const context = useContext(FieldsFiltersStateContext);
  if (context === undefined) {
    throw new Error(
      "useFieldsFiltersState must be used within a FieldsFiltersProvider"
    );
  }
  return context;
}

function useFieldsFiltersDispatch() {
  const context = useContext(FieldsFiltersDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useFieldsFiltersDispatch must be used within a FieldsFiltersProvider"
    );
  }
  return context;
}

export {
  fieldsFiltersReducer,
  FieldsFiltersProvider,
  useFieldsFiltersState,
  useFieldsFiltersDispatch,
};
