import React, { createContext, useReducer, useContext } from "react";
import { handleRequestError, setLocalTimestamps } from "../helpers";
import { ListAgronomics, normaliseAgronomicsKeys } from "../api/agronomics";

const AgronomicsStateContext = createContext();
const AgronomicsDispatchContext = createContext();

function agronomicsReducer(state, action) {
  switch (action.type) {
    case "setAgronomics": {
      return { ...state, data: { ...action.payload } };
    }
    case "setStatus": {
      return { ...state, status: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AgronomicsProvider({ children }) {
  const [state, dispatch] = useReducer(agronomicsReducer, {
    status: "idle",
    data: [],
  });
  return (
    <AgronomicsStateContext.Provider value={state}>
      <AgronomicsDispatchContext.Provider value={dispatch}>
        {children}
      </AgronomicsDispatchContext.Provider>
    </AgronomicsStateContext.Provider>
  );
}

function useAgronomicsState() {
  const context = useContext(AgronomicsStateContext);
  if (context === undefined) {
    throw new Error(
      "useAgronomicsState must be used within a AgronomicsProvider"
    );
  }
  return context;
}

function useAgronomicsDispatch() {
  const context = useContext(AgronomicsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAgronomicsDispatch must be used within a AgronomicsProvider"
    );
  }
  return context;
}

function useAgronomicsById(id) {
  const { data } = useAgronomicsState();
  const index = data.id.indexOf(id);
  if (!id) return null;
  return Object.keys(data).reduce((obj, key) => {
    obj[key] = data[key][index];
    return obj;
  }, {});
}

async function fetchAgronomics(
  dispatch,
  clientId,
  fieldId,
  range,
  token,
  timezone
) {
  dispatch({ type: "setStatus", payload: "pending" });
  try {
    const response = await ListAgronomics(token, clientId, fieldId, range);
    const agronomics = setLocalTimestamps(
      response.data.map((record) => normaliseAgronomicsKeys(record, true)),
      timezone
    );
    dispatch({ type: "setAgronomics", payload: agronomics });
    dispatch({ type: "setStatus", payload: "resolved" });
    return agronomics;
  } catch (e) {
    dispatch({ type: "setStatus", payload: "rejected" });
    handleRequestError(e, "Failed fetching agronomics: ");
  }
}

export {
  AgronomicsProvider,
  useAgronomicsState,
  useAgronomicsDispatch,
  useAgronomicsById,
  fetchAgronomics,
};
