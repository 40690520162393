import React from "react";
import { makeStyles } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";

const Picker = ({ label, timestamp, onChange, color, minDate, disabled }) => {
  const useStyles = makeStyles((theme) => ({
    picker: {
      cursor: "pointer",
      "& input": {
        cursor: "pointer",
      },
    },
    input: {
      maxWidth: 110,
      color: color ? color : theme.palette.text.secondary,
      fontWeight: 700,
      fontSize: "0.9rem",
    },
  }));
  const classes = useStyles();
  return (
    <DateTimePicker
      disabled={disabled}
      className={classes.picker}
      label={label}
      value={timestamp}
      onChange={onChange}
      disableFuture={true}
      format="DD/MM/YY HH:mm"
      InputProps={{
        className: classes.input,
        disableUnderline: true,
      }}
      minDate={minDate && minDate}
    />
  );
};

export default Picker;
