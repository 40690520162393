import React, { useState, useMemo } from "react";
import {
  makeStyles,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useAuthState, usePermissions } from "../../context/auth";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ActionSelection = ({ config, stepValid, selection }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const user = useAuthState();
  const moduleAccess = usePermissions();

  const forms = useMemo(
    () =>
      Object.keys(config).reduce((obj, key) => {
        const { permissions } = config[key];
        if (moduleAccess.includes(permissions.module)) {
          if (permissions.admin) {
            if (user.isAdmin === permissions.admin) {
              obj[key] = config[key];
            }
          } else {
            obj[key] = config[key];
          }
        }
        return obj;
      }, {}),
    [config, moduleAccess, user.isAdmin]
  );

  const handleChange = (e) => {
    selection(e.target.value);
    setSelected(e.target.value);
    stepValid(e.target.value !== "");
  };

  if (!Object.keys(forms).length) {
    return (
      <Typography>
        You currently do not have access to any actions. To discuss the options,
        please contact our team.
      </Typography>
    );
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="select-record-data-label">
        Choose an action to perform:
      </InputLabel>
      <Select
        labelId="select-record-data-label"
        id="select-record-data"
        value={selected}
        onChange={handleChange}
      >
        {Object.keys(forms).map((item) => (
          <MenuItem key={"select-record-data-option" + item} value={item}>
            {config[item].label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ActionSelection;
