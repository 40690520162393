import React, { useState, useMemo } from "react";
import moment from "moment";
import {
  useMildewDispatch,
  useSpraysById,
  fetchMildewRisk,
  useMildewState,
} from "../../../../../../../context/mildew";
import { useAuthState } from "../../../../../../../context/auth";
import {
  DeleteSpray,
  UpdateSpray,
} from "../../../../../../../api/disease-risk";
import { formatDateRange } from "../../../../../../../helpers";
import EditDeleteTable from "../../../../../../tables/EditDeleteTable";
import SpraysForm from "../../../../../../forms/SpraysForm";
import Card from "../..";
import { useAnalyticsState } from "../../../../../../../context/analytics";
import { useLoggerLoadingStatus, useSensorDataTable } from "../../hooks";
import { useAxisesMinMax, useChartData } from "../../../Chart/hooks";
import DefaultChart from "../../../Chart/components/DefaultChart";
import { useTheme } from "@material-ui/core";

const MildewRisk = ({ title }) => {
  const {
    palette: { primary, secondary, danger },
  } = useTheme();
  const user = useAuthState();
  const { field, range } = useAnalyticsState();
  const { status, data: rawData, sprays: sprayData } = useMildewState();
  const loading = useLoggerLoadingStatus(status);
  const values = ["mildew"];
  const defaultTable = useSensorDataTable(
    Object.values(rawData).map(({ value: mildew, ...record }) => ({
      mildew,
      ...record,
    })),
    title,
    values
  );

  const { data: mildew, hasData } = useChartData(Object.values(rawData), [
    "value",
  ]);

  const data = useMemo(() => {
    const subsets = mildew[0]?.reduce(
      ({ excess, warning, optimum }, record) => {
        if (record.y >= 125) {
          return { excess: [...excess, record], warning, optimum };
        }
        if (record.y < 115) {
          return { excess, warning, optimum: [...optimum, record] };
        }
        return { excess, warning: [...warning, record], optimum };
      },
      { excess: [], warning: [], optimum: [] }
    );
    return [subsets.excess, subsets.warning, subsets.optimum];
  }, [mildew]);

  const datasets = {
    excess: {
      color: danger.main,
      type: "line",
      axis: "hours",
    },
    warning: {
      color: secondary.main,
      type: "line",
      axis: "hours",
    },
    optimum: {
      color: primary.main,
      type: "line",
      axis: "hours",
    },
  };

  const axises = useAxisesMinMax(
    {
      hours: {
        id: 0,
        label: "Hours",
        orientation: "left",
      },
    },
    datasets,
    data
  );

  const dispatch = useMildewDispatch();
  const [sprayId, setSprayId] = useState(null);
  const record = useSpraysById(sprayId);
  const formValues = record && {
    ...record,
    timestamp: moment.tz(record.timestamp, field.timezone),
  };

  const sprays = useMemo(
    () =>
      sprayData?.length && {
        columns: [
          { title: "Timestamp", field: "timestamp_label" },
          { title: "Chemical Used", field: "chemical_used" },
          { title: "Fungicide", field: "fungicide" },
          { title: "Active Ingredient", field: "active_ingredient" },
        ],
        data: sprayData.map((item) => ({
          ...item,
          timestamp_label: moment(item.timestamp).format("DD/MM/YY HH:mm"),
        })),
      },
    [sprayData]
  );

  const handleUpdate = (values) => {
    const record = {
      ...values,
      timestamp: values.timestamp.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    return UpdateSpray(user.token, user.client.id, field.id, sprayId, record);
  };

  const handleDelete = () =>
    DeleteSpray(user.token, user.client.id, field.id, sprayId);

  const handleRefresh = () =>
    fetchMildewRisk(
      dispatch,
      user.client.id,
      field.id,
      formatDateRange(range),
      user.token,
      field.timezone
    );

  return (
    <Card
      chart={
        hasData ? (
          <DefaultChart data={data} datasets={datasets} axises={axises} />
        ) : null
      }
      loading={loading}
      title={title}
      isLogger={true}
      tables={[
        defaultTable,
        {
          title: "Spray Activities",
          display: false,
          table: (
            <EditDeleteTable
              title="Spray Records"
              type="Spray Record"
              data={sprays}
              record={formValues}
              setRecord={setSprayId}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
              onRefresh={handleRefresh}
            >
              <SpraysForm
                title="Amend the Spray Activity:"
                timezone={field.timezone}
              />
            </EditDeleteTable>
          ),
        },
      ]}
    />
  );
};

export default MildewRisk;
